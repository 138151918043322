export const emailLinkFormInitial = {
  acknowledgement: false,
  text: [],
  document: [],
  questionnaireValues: [],
  yourAnswer: {},
};

export const emailLinkFormTextFields = () => [
  {
    id: 1,
    name: "text",
    label: "Content",
  },
];

export const emailLinkFormDocumentFields = () => [
  [
    {
      id: 1,
      name: "contentText",
      label: "Content Text",
      type: "text",
      accessor: "contentText",
      xs: 8,
    },
    {
      id: 2,
      name: "contentUpload",
      label: "Content Upload",
      type: "file",
      xs: 4,
    },
    {
      id: 3,
      name: "remarks",
      label: "Remarks",
      accessor: "remarks",
      type: "text",

      xs: 12,
    },
  ],
];
