export const sidebarData = [
  {
    id: 1,
    name: "Company Analysis/Initial Risk Assessment",
    value: "DDRequirementsAnalysis",
    sidebarIndex: 1,
    buttons: [
      {
        id: 1,
        name: "Size of organisation",
        value: "sizeOfOrganization",
        path: "/dd-requiremetns-analysis/sizeOfOrganization",
      },
      {
        id: 2,
        name: "Type/Nature of organisation",
        value: "typeOfOrganization",
        path: "/dd-requiremetns-analysis/typeOfOrganization",
      },
      {
        id: 3,
        name: "Explanation/Context of Operation",
        value: "contactsOfOperation",
        path: "/dd-requiremetns-analysis/contactsOfOperation",
      },
      {
        id: 4,
        name: "Probability of adverse impacts on HR - DWC",
        value: "probabilityImpacts",
        path: "/dd-requiremetns-analysis/probabilityImpacts",
      },
      {
        id: 5,
        name: "Severity of adverse impacts on HR - DWC",
        value: "severityImpacts",
        path: "/dd-requiremetns-analysis/severityImpacts",
      },
      {
        id: 6,
        name: "Documents Submitted for the year",
        value: "informationDDRequirements",
        path: "/dd-requiremetns-analysis/informationDDRequirements",
      },
    ],
  },
  {
    id: 2,
    name: "Company Compliance/ Due Diligence",
    value: "dueDiligenceDescription",
    sidebarIndex: 2,

    buttons: [
      {
        id: 1,
        name: "HR - DWC Policy",
        value: "DWCPolicy",
        buttons: [
          {
            id: 1,
            name: "Description of Policies and related Processes",
            value: "descriptionOfPlanProcesses",
            path: "/due-dilligence-description/descriptionOfPlanProcesses",
          },
          {
            id: 2,
            name: "Implementation/Communication Efforts",
            value: "implementationCommunication",
            path: "/due-dilligence-description/implementationCommunication",
          },
        ],
      },
      {
        id: 2,
        name: "HR - DWC Adverse Impact Assessment Description",
        value: "impactAssessmentDescription",
        buttons: [
          {
            id: 1,
            name: "Operations",
            value: "operations",
            path: "/due-dilligence-description/operations",
          },
          {
            id: 2,
            name: "Supply Chain",
            value: "supplyChain",
            path: "/due-dilligence-description/supplyChain",
          },
          {
            id: 3,
            name: "Business Relationships",
            value: "businessRelationShips",
            path: "/due-dilligence-description/businessRelationShips",
          },
        ],
      },
      {
        id: 3,
        name: "Addressing Priority Adverse Impacts (based on Assessment)",
        value: "addressingPriority",
        path: "/due-dilligence-description/addressingPriority",
      },
      {
        id: 4,
        name: "Communications (related to Priority Impacts)",
        value: "communications",
        buttons: [
          {
            id: 1,
            name: "To Stakeholders",
            value: "toStakeholders",
            path: "/due-dilligence-description/toStakeholders",
          },
          {
            id: 2,
            name: "To Rightholders",
            value: "toRightHolders",
            path: "/due-dilligence-description/toRightHolders",
          },
        ],
      },
      {
        id: 5,
        name: "Required cooperation relating to remediation or compensation",
        value: "remediationCompensation",
        path: "/due-dilligence-description/remediationCompensation",
      },
      {
        id: 6,
        name: "Documents Submitted for the year",
        value: "informationDueDilligence",
        path: "/due-dilligence-description/informationDueDilligence",
      },
    ],
  },

  {
    id: 3,
    name: "Transparency Act Report Creator",
    value: "transparencyActReportCreator",
    sidebarIndex: 3,

    buttons: [
      {
        id: 1,
        name: "Organisation Structure",
        value: "OrganizationStructure",
        path: "/transparency-act-report-creator/OrganizationStructure",
      },
      {
        id: 2,
        name: "Areas of Operation",
        value: "areasOfOperation",
        path: "/transparency-act-report-creator/areasOfOperation",
      },
      {
        id: 3,
        name: "Guidelines/policies to address Adverse Impacts on HR - DWC",
        value: "guidelinesPolicies",
        path: "/transparency-act-report-creator/guidelinesPolicies",
      },
      {
        id: 4,
        name: "Any Adverse Impacts Identified",
        value: "adverseImpacts",
        path: "/transparency-act-report-creator/adverseImpacts",
      },
      {
        id: 5,
        name: "Report Generation",
        value: "reportGeneration",
        path: "/transparency-act-report-creator/reportGeneration",
      },
    ],
  },
  {
    id: 4,
    name: "Information Request Creator",
    value: "emailInformationRequestCreator",
    sidebarIndex: 4,

    buttons: [
      {
        id: 1,
        name: "Email Master",
        value: "emailMaster",
        path: "/email-information-request-creator/emailMaster/list",
      },
      {
        id: 2,
        name: "Data Collection Form Design",
        value: "dataCollectionContent",
        path: "/email-information-request-creator/dataCollectionContent/list",
      },
      {
        id: 3,
        name: "Email Creator",
        value: "emailCreation",
        path: "/email-information-request-creator/emailCreation/list",
      },
      {
        id: 4,
        name: "Email Responses",
        value: "emailResponses",
        path: "/email-information-request-creator/emailResponses/list",
      },
    ],
  },
];
