import React from "react";
import { useParams } from "react-router-dom";
import DDRequirementsInformation from "../../pages/dataEntry/ddRequirementsAnalysis/DDRequirementsInformation";
import AddressingPriority from "../../pages/dataEntry/DueDelligience/AddressingPriority";
import DueDiligenceInformation from "../../pages/dataEntry/DueDelligience/DueDiligenceInformation";
import AdverseImpacts from "../../pages/dataEntry/TransparencyAct/AdverseImpact";
import DATAForm from "../common/EntryForm";
import { lookup } from "../common/lookup";
import { Report } from "../Report";
export default function DDRAnalysis() {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { screen } = useParams();
  let header = lookup[screen]?.name;
  let subHeader = lookup[screen]?.subName;
  let optionalLabel = lookup[screen]?.optional;

  return (
    <>
      {screen === "addressingPriority" ? (
        <AddressingPriority />
      ) : screen === "adverseImpacts" ? (
        <AdverseImpacts />
      ) : screen === "reportGeneration" ? (
        <Report />
      ) : screen === "informationDDRequirements" ? (
        <DDRequirementsInformation />
      ) : screen === "informationDueDilligence" ? (
        <DueDiligenceInformation />
      ) : (
        <DATAForm
          header={header}
          subHeader={subHeader}
          supportingDataOptional={optionalLabel ? optionalLabel : "Optional"}
        />
      )}
    </>
  );
}
