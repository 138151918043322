import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { updateStatusStyle } from "../../styles/dashboard";
import { useNavigate } from "react-router-dom";
import { updateUserStatus } from "../../api/api";
import { Grid } from "@mui/material";
import { CustomButton, CustomRadioButton, CustomTextField } from ".";
import DatePickerField from "./DatePickerField";
import { formGroup, updateStatusInitial } from "../../constant/updateStatus";
import { updateStatusValidation } from "../../constant";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 712,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const UpdateStatus = (props) => {
  const useStyles = updateStatusStyle();
  const { originalId, pathName } = props;
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const seedData = useSelector((state) => state.toastMessageStateReducer.seeds);

  const onSubmit = (data) => {
    updateUserStatus(originalId.id, data).then((response) => {
      setOpen(false);
      props.setAnchorEl(false);
      props.setStatus(!props.status);
      navigate(pathName);
    });
  };
  //to handle formik data
  const formik = useFormik({
    initialValues: updateStatusInitial,
    validationSchema: updateStatusValidation,
    onSubmit,
  });

  const { handleChange, handleBlur, errors, touched, values, setFieldValue } =
    formik;

  return (
    <div className="editTableStyle">
      <span onClick={handleOpen}>Update Status</span>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={useStyles.title}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {`Update Status: ${props.title}`}
            </Typography>
            <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
          </div>

          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={3}
              p={4}
              className={useStyles.form}
            >
              <Grid item xs={12}>
                <CustomRadioButton
                  name={formGroup.statusId.name}
                  label={formGroup.statusId.label}
                  inputValues={seedData?.customerStatus || []}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.statusId || originalId.status?.id}
                  error={touched.statusId && errors.statusId}
                  helperText={touched.statusId && errors.statusId}
                  style={{ marginLeft: "10px" }}
                />
              </Grid>
              <Grid item xs={12} className={useStyles.effectiveDate}>
                <DatePickerField
                  label={formGroup.effectiveDate.label}
                  name={formGroup.effectiveDate.name}
                  onChange={(val) => {
                    setFieldValue(formGroup.effectiveDate.name, val);
                  }}
                  value={values.effectiveDate}
                  helperText={touched.effectiveDate && errors.effectiveDate}
                  maxDate={new Date()}
                  dateFieldStyle={{ width: "50%" }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  name={formGroup.reason.name}
                  label={formGroup.reason.label}
                  variant={formGroup.reason.variant}
                  type={formGroup.reason.type}
                  onChange={handleChange}
                  value={values.reason}
                  error={touched.reason && errors.reason}
                  onBlur={handleBlur}
                  logInField
                  fullWidth={true}
                />
              </Grid>
            </Grid>

            <div className="buttonModal">
              <Typography
                id="modal-modal-description"
                style={{ cursor: "pointer" }}
                className={useStyles.cancelButton}
                onClick={handleClose}
              >
                CANCEL
              </Typography>

              <CustomButton
                variant="contained"
                label="UPDATE"
                className={useStyles.updateButton}
                type="submit"
              />
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default UpdateStatus;
