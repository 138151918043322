import { Button, Divider, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { formStyles } from "../../../styles";
import {
  CustomTextField,
  CustomUploadImage,
  DropdownField,
} from "../../reusable";
import CustomReactTable from "../../reusable/CustomReactTable";
import { useParams } from "react-router-dom";
import { createSupportingData } from "../../../api/api";
// import Cookies from "universal-cookie";

export const SupportingData = ({
  tableColumn,
  tableRawdata,
  supportingDataInitial,
  supportingDataValidation,
  supportingDataFields,
  setRenderTable,
  formDataId,
  supportingDataOptional,
}) => {
  const { divider, subTitle, addBtn, addBtnContainer, tableContainer } =
    formStyles();

  let { screen } = useParams();

  const onSubmit = (values, { resetForm }) => {
    let workData = {
      information: values.uploadFile,
      data: JSON.stringify({ ...values }),
    };

    let form = new FormData();
    Object.keys(workData)
      .filter((fill) => workData[fill] !== null && workData[fill] !== "")
      .map((item) => {
        //to remove spaces in value
        return form.append(
          item,
          typeof workData[item] === "string"
            ? workData[item]
                .toString()
                .replace(/\s{2,}/g, " ")
                .trim()
            : workData[item]
        );
      });

    formDataId &&
      createSupportingData(formDataId, form)
        .then((response) => setRenderTable((prevData) => !prevData))
        .catch((response) => {
          console.log(response);
        });

    resetForm();
  };

  const formik = useFormik({
    initialValues: supportingDataInitial,
    validationSchema: supportingDataValidation,
    onSubmit,
  });

  const {
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    touched,
    values,
    resetForm,
  } = formik;

  useEffect(() => {
    resetForm();
  }, [resetForm, screen]);
  return (
    <div>
      <Typography className={subTitle}>
        {`Upload Supporting Data (${supportingDataOptional || "Optional"})`}
      </Typography>
      <form>
        <Grid container rowSpacing={3} columnSpacing={3} p={4}>
          <Grid item xs={4}>
            <DropdownField
              label={supportingDataFields.fileType.label}
              name={supportingDataFields.fileType.name}
              value={values.fileType}
              onChange={handleChange}
              error={touched.fileType && errors.fileType}
              onBlur={handleBlur}
              inputValues={supportingDataFields.fileType.inputValues}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextField
              name={supportingDataFields.fileContent.name}
              label={supportingDataFields.fileContent.label}
              type={supportingDataFields.fileContent.type}
              onChange={handleChange}
              value={values.fileContent}
              error={touched.fileContent && errors.fileContent}
              onBlur={handleBlur}
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomUploadImage
              name={supportingDataFields.uploadFile.name}
              label={supportingDataFields.uploadFile.label}
              onChange={setFieldValue}
              value={values.uploadFile}
              error={touched.uploadFile && errors.uploadFile}
              uploadFile={true}
            />
          </Grid>
        </Grid>
        <div className={addBtnContainer}>
          <Button
            variant="contained"
            className={addBtn}
            onClick={formik.handleSubmit}
          >
            ADD
          </Button>
        </div>
        <div className={tableContainer}>
          <CustomReactTable
            columnData={tableColumn}
            rawData={tableRawdata}
            disablePagination
            disableColumnHiding={true}
            disableRowSelection={true}
            disableSort={true}
            style={{
              th: {
                color: "#0000008A",
                font: "normal normal bold 14px/19px Roboto",
                display: "flex !important",
                alignItems: "center",
                height: "64px !important",
              },
              body: {
                position: "relative",
                zIndex: "0",
                backgroundColor: "white",
                color: "#40403A",
                font: "normal normal bold 14px/19px Roboto",
              },
            }}
          />
        </div>
        <Divider className={divider} />
      </form>
    </div>
  );
};
