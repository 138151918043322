import React from "react";
import { makeStyles } from "@mui/styles";
import { Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import {
  CustomRadioButton,
  CustomTextField,
  CustomToggleButton,
  CustomUploadImage,
} from "../../reusable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import HelperText from "../../reusable/HelperText";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  accordionContainer: {
    width: "320px",
    height: "calc(100vh - 64px)",
    position: "fixed",
    overflowY: "scroll",
    backgroundColor: "white",
    borderRight: "2px solid #7394c6",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent !important",
      width: "0px !important",
    },
  },
  formContainer: {
    width: "calc(98vw - 330px)",
    position: "absolute",
    left: "330px",
    padding: "0 20px 20px 20px",
    boxSizing: "border-box",
    // height: "85vh",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    margin: "20px 0",
  },
  title: {
    fontWeight: "bold !important",
  },
  arrowBtn: {
    color: "black !important",
    marginRight: "10px !important",
  },
  formBox: {
    padding: "20px 40px",
    boxSizing: "border-box",
    border: "1px solid #2A5BA8",
    borderRadius: "4px",
  },
  helperTextContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  headerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  heading: {
    fontWeight: "600 !important",
    fontFamily: " Rubik !important",
    marginBottom: "16px  !important",
  },
  subHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px !important ",
  },
  italicText: {
    fontStyle: "italic ",
  },
  divider: {
    backgroundColor: "#40403A ",
    height: "2px",
    // width: "94%",
    margin: "20px 0 !important",
  },
  certificationPara: {
    margin: "14px 0 !important",
  },
  agreeBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "20px 0",
  },
  disAgreeBtn: {
    backgroundColor: "#D8553AB3 !important",
  },
  subFormBox: {
    padding: "20px 20px",
    boxSizing: "border-box",
    border: "1px solid #2A5BA8",
    borderRadius: "4px",
    marginTop: 20,
  },
  questionText: {
    width: "100% !important",
    color: "#5C6BC0",
  },
}));

const ViewForm = () => {
  const {
    root,
    accordionContainer,
    formContainer,
    titleContainer,
    title,
    arrowBtn,
    formBox,
    helperTextContainer,
    headerContainer,
    heading,
    subHeaderContainer,
    italicText,
    divider,
    certificationPara,
    agreeBtnContainer,
    disAgreeBtn,
    subFormBox,
    // questionText,
  } = useStyles();

  const navigate = useNavigate();
  const globalState = useSelector((state) => state);
  const { viewForm } = globalState?.toastMessageStateReducer;

  console.log(viewForm);

  return (
    <div className={root}>
      <div className={accordionContainer}>
        <CustomToggleButton />
      </div>
      <div className={formContainer}>
        <div className={titleContainer}>
          <IconButton className={arrowBtn} onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" className={title}>
            View Form
          </Typography>
        </div>
        <div className={formBox}>
          <div className={helperTextContainer}>
            <HelperText
              position="left"
              text={viewForm?.formSetupData?.helperText}
            />
          </div>
          <div className={headerContainer}>
            <Typography variant="h4" className={heading}>
              {viewForm.heading}
            </Typography>

            <Typography variant="h4"> {viewForm.subHeading}</Typography>
          </div>
          <div className={subHeaderContainer}>
            <Typography variant="h6" className={italicText}>
              {viewForm.topicName}
            </Typography>
            <Typography variant="h6" className={italicText}>
              {viewForm.financialYearInput?.map(
                (item) => item.id === viewForm.financialYearId && item.name
              )}
            </Typography>
          </div>
          <div className={subHeaderContainer}>
            <Typography
              variant="h6"
              className={italicText}
              style={{ visibility: "hidden" }}
            >
              Expiry Date : {moment(viewForm.expireDate).format("L")}
            </Typography>
            <Typography variant="h6" className={italicText}>
              Requested Date : {moment().format("L")}
            </Typography>
          </div>
          <Divider className={divider} />
          {viewForm.formSetupData.certification === "Yes" && (
            <>
              <Typography variant="h4" sx={{ fontSize: "28px" }}>
                Acknowledgement/Certification
              </Typography>
              <Typography className={certificationPara}>
                {viewForm.formSetupData.certificationText}
              </Typography>
              <div className={agreeBtnContainer}>
                <Button
                  variant="oulined"
                  style={{ color: "#D8553AB3", border: "1px solid #D8553AB3" }}
                >
                  DisAgree
                </Button>
                <Button variant="outlined" color="info">
                  Agree
                </Button>
              </div>
              <Divider className={divider} />
            </>
          )}
          <Typography variant="h4" sx={{ fontSize: "28px" }}>
            Data Collection
          </Typography>
          {viewForm.formSetupData.dataCollection === "Yes" && (
            <div className={subFormBox}>
              {/* <Typography variant="" className={heading}>
                ID/Serial No : 1
              </Typography> */}
              <Typography className={certificationPara}>
                {viewForm.formSetupData.dataCollectionText}
              </Typography>
              <CustomTextField
                label="Content Text"
                fullwidth={true}
                isViewMode={true}
                style={{ width: "100%" }}
              />

              {viewForm.formSetupData.multiRecords === "Yes" && (
                <div className={agreeBtnContainer}>
                  <Button variant="contained" color="info">
                    ADD
                  </Button>
                </div>
              )}
            </div>
          )}
          <Divider className={divider} />

          {viewForm.formSetupData.documentUpload === "Yes" && (
            <div className={subFormBox}>
              <Typography variant="" className={heading}>
                {/* ID/Serial No : 2 */}
                {viewForm.formSetupData.documentUploadText}
              </Typography>
              <Grid container columnSpacing={4} rowSpacing={3} pt={2}>
                <Grid item xs={4}>
                  <CustomTextField
                    label="Document Type"
                    fullwidth={true}
                    isViewMode={true}
                    style={{ width: "100%" }}
                  />
                </Grid>
                {viewForm.formSetupData.contentText === "Yes" && (
                  <Grid item xs={8}>
                    <CustomTextField
                      label="File Name"
                      fullwidth={true}
                      isViewMode={true}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                )}
                <Grid item xs={4}>
                  <CustomUploadImage
                    label={"Upload Document"}
                    uploadFile
                    isViewMode={true}
                  />
                </Grid>
                {viewForm.formSetupData.remarks === "Yes" && (
                  <Grid item xs={12}>
                    <CustomTextField
                      label="Remarks, if any"
                      fullwidth={true}
                      isViewMode={true}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                )}
              </Grid>
              {viewForm.formSetupData.multiRecordsUpload === "Yes" && (
                <div className={agreeBtnContainer}>
                  <Button variant="contained" color="info">
                    ADD
                  </Button>
                </div>
              )}
            </div>
          )}
          <Divider className={divider} />

          {viewForm.formSetupData?.questionnaireValues?.length > 0 && (
            <div className={subFormBox}>
              <Typography variant="h4" sx={{ fontSize: "28px" }}>
                Questionnaire
              </Typography>
              <Grid container pt={1}>
                {viewForm.formSetupData?.questionnaireValues?.map(
                  (item, index) => (
                    <Grid item xs={12} key={index} pt={1}>
                      <CustomRadioButton
                        label={`${index + 1}. ${item.Question} `}
                        inputValues={item.Answers || []}
                        value={item?.Answers[0]?.id || ""}
                        name={item.Question}
                        vertical
                        labelStyle={{}}
                        fullwidth
                      />
                      <CustomTextField
                        label={"Enter your answer here"}
                        style={{ width: "100%", marginBottom: "24px" }}
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewForm;
