import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  cancelMailField,
  cancelMailInitial,
  emailCancellingValidation,
} from "../../constant";
import { useFormik } from "formik";
import { Box, Grid } from "@mui/material";
import { CustomTextField } from ".";
import { makeStyles } from "@mui/styles";
import { getEmailCreationById, updateEmailCreation } from "../../api/api";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root ": {
    minWidth: 600,
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2, backgroundColor: "#2A5BA8", color: "white" }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: "10px !important",
    fontWeight: "bold !important",
  },
  bottomBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "89%",
    margin: "auto",
  },
  sendBtn: {
    backgroundColor: "#2A5BA8 !important",
  },
  cancelBtn: {
    backgroundColor: "#00000061 !important",
    color: "white !important",
    border: "none !important",
  },
}));

export function EmailCancelModal({ open, handleClose, getId }) {
  const { title, bottomBtnContainer, cancelBtn, sendBtn } = useStyles();

  const onSubmit = async (values) => {
    let getPrevData = await getEmailCreationById(getId.row.original.id);

    let workData = {
      ...getPrevData.data,
      cancelingReason: values.reason,
    };

    updateEmailCreation(getId.row.original.id, workData)
      .then((res) => console.log(res))
      .catch((res) => console.log(res));

    handleClose();
  };

  const formik = useFormik({
    initialValues: cancelMailInitial,
    validationSchema: emailCancellingValidation,
    onSubmit,
  });

  const { handleChange, handleBlur, errors, touched, values } = formik;

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Email Cancelling
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form>
            <Grid container rowSpacing={3} columnSpacing={3} p={4}>
              <Grid item xs={12}>
                <Typography className={title}>Reason For Cancelling</Typography>
                <CustomTextField
                  label={cancelMailField.reason.label}
                  name={cancelMailField.reason.name}
                  type={cancelMailField.reason.type}
                  value={values.reason}
                  onChange={handleChange}
                  error={touched.reason && errors.reason}
                  onBlur={handleBlur}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Box className={bottomBtnContainer}>
            <Button
              variant="outlined"
              className={cancelBtn}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              type="submit"
              className={sendBtn}
              onClick={formik.handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
