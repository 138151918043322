import {
  dataCollectionInitialValues,
  emailCreationFormInitial,
} from "../constant";

export const initialStateReducer = {
  toastMessageState: {
    severity: "",
    message: "",
    messageStatus: false,
  },
  leadershipFlag: false,
  emailViewData: emailCreationFormInitial,
  viewForm: dataCollectionInitialValues,
  isLoading: false,
  seeds: {},
};

export const toastMessageStateReducer = (
  state = initialStateReducer,
  action
) => {
  const { type, payload } = action;
  const copyState = JSON.parse(JSON.stringify(state));

  switch (type) {
    case "TOASTMESSAGEACTION": {
      return { ...copyState, toastMessageState: payload };
    }
    case "EMAILVIEW": {
      return { ...copyState, emailViewData: payload };
    }
    case "VIEWFORM": {
      return { ...copyState, viewForm: payload };
    }
    case "LEADERSHIP": {
      return { ...copyState, leadershipFlag: payload };
    }
    case "LOADER": {
      return { ...copyState, isLoading: payload };
    }
    case "ALLSEEDS": {
      return {
        ...copyState,
        seeds: {
          ...state.seeds,
          [payload.key]: payload.value,
        },
      };
    }
    default:
      return state;
  }
};
