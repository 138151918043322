import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, IconButton, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useNavigate } from "react-router-dom";
import {
  customerDownloadDetails,
  invoiceDownload,
  invoiceSetupDownload,
  userDownloadDetails,
} from "../../api/api";
import { downloadTableData } from "../../utils/downloadFile";
import CustomSearchField from "./CustomSearchField";
import CustomerFilter from "./filter/CustomerFilter";
import InvoiceSetupFilter from "./filter/InvoiceSetupFilter";

const useStyles = makeStyles((theme) => ({
  container: {
    height: 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },

  contianerAlign: {
    width: "90%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    // color: "white",
  },
  iconsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  topbarIcons: {
    color: "white",
    backgroundColor: "#E46D35",
    padding: 6,
    // borderRadius: 4,
  },
  downloadBarIcons: {
    color: "white",
    backgroundColor: "#264DB5",
    padding: 7,
    borderRadius: 4,
  },
  newButton: {
    color: "white",
    background: "#59B961 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 4px #00000033",
    borderRadius: "4px",
    fontSize: "14px",
    padding: "8px 16px",
  },
}));

export function SuperAdminTopbar(props) {
  const {
    container,
    title,
    contianerAlign,
    newButton,
    iconsContainer,
    downloadBarIcons,
  } = useStyles();
  const [downloadData, setDownloadData] = useState();

  const navigate = useNavigate();

  const {
    label,
    searchField,
    filter,
    download,
    userTypeId,
    newForm,
    userId,
    pathName,
    customerId,
  } = props;

  useEffect(() => {
    if (label === "CUSTOMERS") {
      customerDownloadDetails(userId).then(
        (response) => {
          setDownloadData(response.data);
        },
        (response) => {
          console.log(response, "error");
        }
      );
    }
    if (label === "INVOICES SETUP") {
      invoiceSetupDownload().then(
        (response) => {
          setDownloadData(response.data);
        },
        (response) => {
          console.log(response, "error");
        }
      );
    }
    if (label === "My USERS") {
      userDownloadDetails(userTypeId).then(
        (response) => {
          setDownloadData(response.data);
        },
        (response) => {
          console.log(response, "error");
        }
      );
    } else if (customerId) {
      invoiceDownload(customerId).then((response) => {
        setDownloadData(response.data);
      });
    }
  }, [customerId, label, userId, userTypeId]);

  const downLoadRecord = () => {
    downloadTableData(downloadData, label);
  };

  return (
    <div className={container}>
      <div className={contianerAlign}>
        <Typography variant="h6" className={title}>
          {label}
        </Typography>
        <div className={iconsContainer}>
          {searchField && <CustomSearchField />}
          {filter && (
            <Button>
              {pathName.split("/")[1] === "customersForm" ? (
                <CustomerFilter />
              ) : (
                <InvoiceSetupFilter />
              )}
            </Button>
          )}
          {newForm && (
            <Button onClick={() => navigate(pathName)}>
              <span className={newButton}>NEW</span>
            </Button>
          )}
          {download && (
            <IconButton onClick={downLoadRecord}>
              <DownloadIcon className={downloadBarIcons} />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
}
