import { createStore } from "redux";
// import logger from "redux-logger";
// import { legacy_createStore as createStore } from "redux";
import rootReducer from "./root-reducer";

const store = createStore(
  rootReducer,
  window.REDUX_DEVTOOLS_EXTENSION && window.REDUX_DEVTOOLS_EXTENSION()
);

export default store;
