import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export function WidgetTextField(props) {
  const { className, schema, value, onChange, readonly, uiSchema } = props;

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      type={showPassword ? "text" : props.type || schema.type}
      variant="outlined"
      className={className}
      label={schema.label}
      disabled={readonly}
      value={value || ""}
      onChange={(event) => onChange(event.target.value || undefined)}
      style={{ marginLeft: 0 }}
      InputProps={{
        onKeyPress: (e) =>
          uiSchema.key === "number" &&
          !/[0-9+-]/.test(e.key) &&
          e.preventDefault(),
        endAdornment: uiSchema.showEyeIcon && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
