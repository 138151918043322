import { makeStyles } from "@mui/styles";

export const emailListStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  accordionContainer: {
    width: "320px",
    height: "calc(100vh - 64px)",
    position: "fixed",
    overflowY: "scroll",
    backgroundColor: "white",
    borderRight: "2px solid #7394c6",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent !important",
      width: "0px !important",
    },
  },
  tableContainer: {
    width: "calc(98vw - 330px)",
    position: "absolute",
    left: "330px",
    padding: "0 20px 20px 20px",
    boxSizing: "border-box",
  },
  iconsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontWeight: "bold !important",
  },
  topbarContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0",
  },
  downloadBarIcons: {
    color: "white",
    backgroundColor: "#264DB5",
    padding: 7,
    borderRadius: 4,
  },

  newButton: {
    color: "white",
    background: "#59B961 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 4px #00000033",
    borderRadius: "4px",
    fontSize: "14px",
    padding: "8px 16px",
  },
  filterBtn: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#E2E2E2",
    // padding: "8px 16px",
    fontSize: 14,
    // marginTop: 8,
    borderRadius: 4,
    color: "black",
  },
  bulkUploadContainer: {
    margin: "30px 0",
  },
}));
