import { TextField } from "@mui/material";
import React from "react";

export function WidgetTimeField(props) {
  const { schema, value, onChange, options, readonly } = props;

  return (
    <TextField
      type="time"
      variant={options.variant || "outlined"}
      InputLabelProps={{ shrink: true }}
      className={options.classNames}
      label={schema.label}
      disabled={readonly}
      value={value || ""}
      onChange={(event) => onChange(event.target.value)}
      style={{ marginLeft: 0 }}
    />
  );
}
