import axios from "axios";
import Cookies from "universal-cookie";
import { isLoading } from "../redux/action";
import store from "../redux/store";

const cookies = new Cookies();

export const appApi = axios.create({
  // baseURL: "http://localhost:4000",

  baseURL: "https://api.esgms.io",
  responseType: "json",
  headers: {
    Accept: "application/json",
    "content-type": "application/json",
  },
});

appApi.interceptors.request.use(
  (config) => {
    config.headers["authorization"] = `Bearer ${cookies.get("token")}`;

    store.dispatch(isLoading(true));

    return config;
  },
  (error) => {
    console.error(error);
    return Promise.reject(error);
  }
);

appApi.interceptors.response.use(
  (response) => {
    store.dispatch(isLoading(false));

    return response;
  },
  (error) => {
    store.dispatch(isLoading(false));

    return Promise.reject(error);
  }
);
