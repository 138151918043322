import { makeStyles } from "@mui/styles";

export const emailLinkFormStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  accordionContainer: {
    width: "320px",
    height: "calc(100vh - 64px)",
    position: "fixed",
    overflowY: "scroll",
    backgroundColor: "white",
    borderRight: "2px solid #7394c6",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent !important",
      width: "0px !important",
    },
  },
  formContainer: {
    // width: "calc(98vw - 330px)",
    width: "100%",
    height: "calc(100vh - 64px)",
    top: "64px",
    position: "fixed",
    overflowY: "scroll",
    padding: "0 20px 20px 20px",
    boxSizing: "border-box",
    // height: "85vh",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    margin: "20px 0",
  },
  title: {
    fontWeight: "bold !important",
  },
  arrowBtn: {
    color: "black !important",
    marginRight: "10px !important",
  },
  formBox: {
    padding: "20px 40px",
    boxSizing: "border-box",
    // border: "1px solid #2A5BA8",
    borderRadius: "4px",
  },
  helperTextContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  headerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  heading: {
    fontWeight: "600 !important",
    fontFamily: " Rubik !important",
    margin: "16px 0 !important",
  },
  subHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px !important ",
  },
  italicText: {
    fontStyle: "italic ",
  },
  divider: {
    backgroundColor: "#40403A ",
    height: "2px",
    // width: "94%",
    margin: "20px 0 !important",
  },
  certificationPara: {
    margin: "14px 0 !important",
  },
  agreeBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "20px 0",
  },
  disAgreeBtn: {
    backgroundColor: "#D8553AB3 !important",
  },
  subFormBox: {
    padding: "20px 20px",
    boxSizing: "border-box",
    border: "1px solid #2A5BA8",
    borderRadius: "4px",
    marginTop: 20,
  },
  questionText: {
    width: "100% !important",
    color: "#5C6BC0",
  },
  saveBtnContainer: {
    width: "95.5%",
    margin: "32px auto",
    display: "flex",
    justifyContent: "center",
  },
  cancelBtn: {
    padding: "6px 30px !important",
    backgroundColor: "#C0C1CE !important",
  },
}));
