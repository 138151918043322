import { lazy } from "react";
import { ROUTE_PATHS } from "./routePath";

const login = lazy(() => import("../components/pages/loginPage/Login"));

const forgetPassword = lazy(() =>
  import("../components/pages/loginPage/ForgotPassword")
);

const resetPassword = lazy(() =>
  import("../components/pages/loginPage/ResetPassword")
);

const userProfile = lazy(() =>
  import("../components/pages/profile/userProfile/UserProfile")
);

const userChangePassword = lazy(() =>
  import("../components/pages/profile/userProfile/UserChangePassword")
);

const userEditProfile = lazy(() =>
  import("../components/pages/profile/userProfile/UserEditProfile")
);

const welcomePage = lazy(() =>
  import("../components/pages/profile/userProfile/WelcomePage")
);

const homePage = lazy(() => import("../components/user/common/HomePage"));

const emailView = lazy(() =>
  import("../components/user/EmailInformation/EmailView")
);

const emailLinkForm = lazy(() => import("../components/user/EmailLinkFom"));

const logHistory = lazy(() =>
  import("../components/user/EmailInformation/LogHistory")
);

const viewForm = lazy(() =>
  import("../components/user/EmailInformation/ViewForm")
);

const viewResponse = lazy(() =>
  import("../components/user/EmailInformation/ViewResponses")
);

////  admin
const superAdminDashboard = lazy(() =>
  import("../components/pages/superAdmin/Dashboard")
);

const invoiceDashboard = lazy(() =>
  import("../components/pages/superAdmin/InvoiceDashboard")
);

const customersForm = lazy(() =>
  import("../components/pages/superAdmin/customers/Form")
);

const customersList = lazy(() =>
  import("../components/pages/superAdmin/customers/List")
);

const invoiceSetupList = lazy(() =>
  import("../components/pages/superAdmin/invoiceSetup/List")
);

const invoiceSetupForm = lazy(() =>
  import("../components/pages/superAdmin/invoiceSetup/Form")
);

//company admin
const dashboard = lazy(() =>
  import("../components/pages/companyUsersAdmin/Dashboard")
);

const invoices = lazy(() =>
  import("../components/pages/companyUsersAdmin/Invoices")
);

const companyUsers = lazy(() =>
  import("../components/pages/companyUsersAdmin/companyUsers/CompanyUsers")
);

const parameter = lazy(() =>
  import("../components/pages/companyUsersAdmin/Parameter")
);

// companyadmin profile
const myProfile = lazy(() =>
  import("../components/pages/profile/companyAdminProfile/MyProfile")
);

const editProfile = lazy(() =>
  import("../components/pages/profile/companyAdminProfile/EditProfile")
);

const changePassword = lazy(() =>
  import("../components/pages/profile/companyAdminProfile/ChangePassword")
);

const companyProfile = lazy(() =>
  import("../components/pages/companyProfile/Profile")
);

const companyEditProfile = lazy(() =>
  import("../components/pages/companyProfile/EditProfile")
);

const myUsersList = lazy(() =>
  import("../components/pages/myUsers/MyUserList")
);

const myUsersForm = lazy(() =>
  import("../components/pages/myUsers/MyUserForm")
);

const viewReport = lazy(() => import("../components/user/ViewReport"));

export const routes = [
  //user my profile
  {
    path: ROUTE_PATHS.USER_PROFILE,
    component: userProfile,
    exact: true,
  },

  //user change password
  {
    path: ROUTE_PATHS.USER_CHAHGE_PASSWORD,
    component: userChangePassword,
    exact: true,
  },

  //user Edit profile
  {
    path: ROUTE_PATHS.USER_EDIT_PROFILE,
    component: userEditProfile,
    exact: true,
  },

  // welcome Page
  {
    path: ROUTE_PATHS.WELCOME_PAGE,
    component: welcomePage,
    exact: true,
  },
  // home Page
  {
    path: ROUTE_PATHS.HOMEPAGE,
    component: homePage,
    exact: true,
  },
  // email view
  {
    path: ROUTE_PATHS.EMAIL_VIEW,
    component: emailView,
    exact: true,
  },
  {
    path: ROUTE_PATHS.EMAIL_LINK_FORM,
    component: emailLinkForm,
    exact: true,
  },
  // view responses
  {
    path: ROUTE_PATHS.VIEW_RESPONSES,
    component: viewResponse,
    exact: true,
  },

  // loghisotry
  {
    path: ROUTE_PATHS.LOG_HISTORY,
    component: logHistory,
    exact: true,
  },
  // viewForm
  {
    path: ROUTE_PATHS.VIEW_FORM,
    component: viewForm,
    exact: true,
  },

  //super admin dashboard
  {
    path: ROUTE_PATHS.SUPER_ADMIN_DASHBOARD,
    component: superAdminDashboard,
    exact: true,
  },

  //invoice dashboard
  {
    path: ROUTE_PATHS.INVOICE_DASHBOARD,
    component: invoiceDashboard,
    exact: true,
  },
  //customer form
  {
    path: ROUTE_PATHS.CUSTOMERS_FORM,
    component: customersForm,
    exact: true,
  },
  {
    path: ROUTE_PATHS.CUSTOMERS_LIST,
    component: customersList,
    exact: true,
  },
  //invoice setup
  {
    path: ROUTE_PATHS.INVOICES_SETUP_LIST,
    component: invoiceSetupList,
    exact: true,
  },
  {
    path: ROUTE_PATHS.INVOICES_SETUP_FORM,
    component: invoiceSetupForm,
    exact: true,
  },

  ///////  company admin
  //dashboard
  {
    path: ROUTE_PATHS.DASHBOARD,
    component: dashboard,
    exact: true,
  },
  //invoices
  {
    path: ROUTE_PATHS.INVOICES,
    component: invoices,
    exact: true,
  },
  //companyusers
  {
    path: ROUTE_PATHS.COMPANYUSERS,
    component: companyUsers,
    exact: true,
  },

  {
    path: ROUTE_PATHS.PARAMETER,
    component: parameter,
    exact: true,
  },
  //adminProfile
  {
    path: ROUTE_PATHS.PROFILE,
    component: myProfile,
    exact: true,
  },
  {
    path: ROUTE_PATHS.EDIT_PROFILE,
    component: editProfile,
    exact: true,
  },
  {
    path: ROUTE_PATHS.CHAHGE_PASSWORD,
    component: changePassword,
    exact: true,
  },

  //company profile

  {
    path: ROUTE_PATHS.COMPANY_EDIT_PROFILE,
    component: companyEditProfile,
    exact: true,
  },
  {
    path: ROUTE_PATHS.COMPANY_PROFILE,
    component: companyProfile,
    exact: true,
  },

  // my users
  //my users list
  {
    path: ROUTE_PATHS.MY_USERS_LIST,
    component: myUsersList,
    exact: true,
  },

  //my user form

  {
    path: ROUTE_PATHS.MY_USERS_FORM,
    component: myUsersForm,
    exact: true,
  },
  // view report
  {
    path: ROUTE_PATHS.VIEW_REPORT_GENERATION,
    component: viewReport,
    exact: true,
  },
];

export const loginRoutes = [
  //login
  {
    path: ROUTE_PATHS.LOGIN,
    component: login,
    exact: true,
  },

  //forget Password
  {
    path: ROUTE_PATHS.FORGETPASSWORD,
    component: forgetPassword,
    exact: true,
  },

  //reset Password
  {
    path: ROUTE_PATHS.RESETPASSWORD,
    component: resetPassword,
    exact: true,
  },

  //update password
  // {
  //   path: ROUTE_PATHS.UPDATEPASSWORD,
  //   component: updatePassword,
  //   exact: true,
  // },
];
