import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { FormHelperText } from "@mui/material";

export function CustomDateTimePicker(props) {
  //   const [value, setValue] = React.useState(new Date());

  const {
    label,
    onChange,
    value,
    name,
    isViewMode,
    // openTo,
    // views,
    maxDate,
    minDate,
    className,
    helperText,
    error,
    // style,
    // dateFieldStyle,
  } = props;

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          renderInput={(props) => (
            <TextField {...props} error={error} fullWidth />
          )}
          name={name}
          label={label}
          value={value}
          onChange={onChange}
          className={className}
          minDate={minDate}
          maxDate={maxDate}
          disabled={isViewMode}
        />
      </LocalizationProvider>
      <FormHelperText style={{ color: "#d32f2f", marginLeft: "16px" }}>
        {helperText}
      </FormHelperText>
    </>
  );
}
