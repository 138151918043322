/* eslint-disable array-callback-return */
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { useNavigate, createSearchParams } from "react-router-dom";
import { filterStyle } from "../../../styles/dashboard";
import { CustomButton, CustomTextField, DropdownField } from "..";
import {
  dataCollectionFilter,
  dataCollectionFilterInitial,
  dataCollectionFilterType,
  filterTypeSeeds,
} from "../../../constant/emailFilterContent";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const DataCollectionFilter = ({ listPath }) => {
  const useStyles = filterStyle();

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = async (data) => {
    let workData = { ...data };
    Object.keys(workData).map((item) => {
      if (!workData[item]) {
        delete workData[item];
      }
    });

    let filter = {
      topicName: {
        [workData.topicNameType]:
          workData.topicNameType === "in" || workData.topicNameType === "notIn"
            ? [...workData.topicName.split(",")]
            : workData.topicName,
      },
      heading: {
        [workData?.headingType || "eq"]:
          workData.headingType === "in" || workData.headingType === "notIn"
            ? [...workData.heading.split(",")]
            : workData.heading,
      },
      //   senderEmail: {
      //     [workData?.senderEmailType || "eq"]:
      //       workData.senderEmailType === "in" ||
      //       workData.senderEmailType === "notIn"
      //         ? [...workData.senderEmail.split(",")]
      //         : workData.senderEmail,
      //   },
      status: {
        [workData?.statusType || "eq"]:
          workData.statusType === "in" || workData.statusType === "notIn"
            ? [...workData.status.split(",")]
            : workData.status,
      },
    };

    Object.keys(filter).map((item) => {
      if (
        filter[item].eq === undefined &&
        filter[item].ne === undefined &&
        filter[item].in === undefined &&
        filter[item].notIn === undefined
      ) {
        delete filter[item];
      }
    });

    navigate({
      pathName: listPath,
      search: `?${createSearchParams({
        filter: JSON.stringify(filter),
      })}`,
    });
    setOpen(false);
    resetForm();
  };

  //to handle formik data
  const formik = useFormik({
    initialValues: dataCollectionFilterInitial,
    onSubmit,
  });

  const handleClearFilter = () => {
    navigate({
      pathName: listPath,
    });
    setOpen(true);
    resetForm();
  };

  const { values, handleChange, resetForm, handleBlur } = formik;

  return (
    <div>
      <span onClick={handleOpen} className={useStyles.superAdminTopbarIcons}>
        <FilterListIcon style={{ marginRight: 10 }} />
        Filter
      </span>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={useStyles.title}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Filter
            </Typography>
            <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className={useStyles.invoiceTitle}>
              <Typography
                id="modal-modal-description"
                sx={{ p: 3, width: "150px" }}
              >
                Topic Name
              </Typography>
              <DropdownField
                label={dataCollectionFilterType.topicNameType.label}
                inputValues={filterTypeSeeds || []}
                name={dataCollectionFilterType.topicNameType.name}
                onChange={handleChange}
                className={useStyles.titleContentStyle}
                value={values.topicNameType}
              />
              <CustomTextField
                label={dataCollectionFilter.topicName.label}
                name={dataCollectionFilter.topicName.name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.topicName}
                variant="outlined"
                logInField
                style={{ width: "250px" }}
              />
            </div>
            <div className={useStyles.invoiceTitle}>
              <Typography
                id="modal-modal-description"
                sx={{ p: 3, width: "150px" }}
              >
                Heading
              </Typography>
              <DropdownField
                label={dataCollectionFilterType.headingType.label}
                inputValues={filterTypeSeeds || []}
                name={dataCollectionFilterType.headingType.name}
                onChange={handleChange}
                className={useStyles.titleContentStyle}
                value={values.headingType}
              />
              <CustomTextField
                label={dataCollectionFilter.heading.label}
                name={dataCollectionFilter.heading.name}
                onChange={handleChange}
                value={values.heading}
                variant="outlined"
                onBlur={handleBlur}
                logInField
                style={{ width: "250px" }}
              />
            </div>
            <div className={useStyles.invoiceTitle}>
              <Typography
                id="modal-modal-description"
                sx={{ p: 3, width: "150px" }}
              >
                Status
              </Typography>
              <DropdownField
                label={dataCollectionFilterType.statusType.label}
                inputValues={filterTypeSeeds || []}
                name={dataCollectionFilterType.statusType.name}
                onChange={handleChange}
                value={values.statusType}
                className={useStyles.titleContentStyle}
              />
              <CustomTextField
                label={dataCollectionFilter.status.label}
                name={dataCollectionFilter.status.name}
                onChange={handleChange}
                value={values.status}
                variant="outlined"
                onBlur={handleBlur}
                logInField
                style={{ width: "250px" }}
              />
            </div>

            {/* <div className={useStyles.invoiceTitle}>
              <Typography
                id="modal-modal-description"
                sx={{ p: 3, width: "120px" }}
              >
                Status
              </Typography>
              <DropdownField
                label={dataCollectionFilterType.statusType.label}
                inputValues={filterTypeSeeds || []}
                name={dataCollectionFilterType.statusType.name}
                onChange={handleChange}
                className={useStyles.titleContentStyle}
                value={values.statusType}
                style={{ paddingLeft: 40 }}
              />
              <CustomTextField
                label={dataCollectionFilter.status.label}
                name={dataCollectionFilter.status.name}
                onChange={handleChange}
                value={values.status}
                variant="outlined"
                onBlur={handleBlur}
                logInField
                style={{ width: "250px" }}
              />
             
            </div> */}
            <div className="buttonModal">
              <Typography
                id="modal-modal-description"
                sx={{ p: 3 }}
                onClick={handleClose}
                style={{ cursor: "pointer" }}
                className={useStyles.cancelButton}
              >
                CANCEL
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ p: 3 }}
                onClick={handleClearFilter}
                style={{ cursor: "pointer" }}
                className={useStyles.cancelButton}
              >
                CLEAR FILTER
              </Typography>
              <CustomButton
                variant="contained"
                label="Apply"
                className={useStyles.saveButton}
                type="submit"
              />
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default DataCollectionFilter;
