export const toastMessageAction = (data) => {
  return { type: "TOASTMESSAGEACTION", payload: data };
};

export const leadershipIndicatorAction = (flag) => {
  return { type: "LEADERSHIP", payload: flag };
};

export const emailViewData = (data) => {
  return { type: "EMAILVIEW", payload: data };
};

export const viewFormData = (data) => {
  return { type: "VIEWFORM", payload: data };
};

export const isLoading = (data) => {
  return { type: "LOADER", payload: data };
};

export const allSeeds = (key, data) => {
  return { type: "ALLSEEDS", payload: { value: data, key: key } };
};
