import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate, useOutletContext, useLocation } from "react-router-dom";
import { ROUTE_PATHS } from "../../../../routes/routePath";
import { sidebarData } from "../../../../constant/sidebar";
import {
  createAccess,
  getFormSeed,
  getUsersDetailsById,
} from "../../../../api/api";

const useStyles = makeStyles({
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: 30,
    paddingTop: 30,
  },
  btn: {
    margin: "0 6px",
  },
  saveBtn: {
    background: "#3AD865 !important",
    color: "white",
    height: "40px",
    width: "100px",
    padding: 10,
  },
  cancelBtn: {
    background: "#0000001A !important",
    color: "white",
    height: "40px",
    width: "100px",
    padding: 10,
  },
});

export default function FormAccess() {
  const { btnContainer, saveBtn, cancelBtn } = useStyles();
  const { handleBack } = useOutletContext();
  const navigate = useNavigate();
  const search = useLocation().search;
  const searchParam = new URLSearchParams(search);
  const userId = JSON.parse(searchParam?.get("userId"));
  const isViewMode = JSON.parse(searchParam?.get("isViewMode"));

  const [formSeed, setFormSeed] = useState([]);
  const [access, setAccess] = useState([]);

  useEffect(() => {
    getFormSeed().then((res) => setFormSeed(res.data));
  }, []);

  const allBoxHandleChange = (e) => {
    formSeed.forEach((form) => {
      setAccess((prevData) => {
        prevData.filter(
          (item, index) => item.formId === form.id && prevData.splice(index, 1)
        );
        return [
          ...prevData,
          {
            formId: form?.id,
            userId,
            allowed: e.target.checked,
          },
        ];
      });
    });
  };

  const secHandleChange = (e, value) => {
    let allform = formSeed.filter((item) => item.section === value);
    allform.forEach((form) => {
      setAccess((prevData) => {
        prevData.filter(
          (item, index) => item.formId === form.id && prevData.splice(index, 1)
        );
        return [
          ...prevData,
          {
            formId: form?.id,
            userId,
            allowed: e.target.checked,
          },
        ];
      });
    });
  };

  const tableHandleChange = (e, value) => {
    let getFormId = formSeed.find((item) => item.name === value);
    access.filter(
      (item, index) => item.formId === getFormId.id && access.splice(index, 1)
    );

    setAccess((prevData) => [
      ...prevData,
      {
        formId: getFormId?.id,
        userId,
        allowed: e.target.checked,
      },
    ]);
  };

  const getCheckedValues = (value) => {
    let getForm = formSeed.find((item) => item.name === value);
    return access?.some(
      (item) => item?.formId === getForm?.id && item?.allowed === true
    );
  };

  const secCheckedValues = (value) => {
    let allform = formSeed.filter((item) => item?.section === value);

    return allform.every((secForm) =>
      access.find(
        (item) => secForm?.id === item?.formId && item?.allowed === true
      )
    );
  };

  const children = (buttons, principleName) => {
    return buttons?.map(({ name, id, value }, index) => (
      <Box sx={{ display: "flex", flexDirection: "column", ml: 10 }}>
        <FormControlLabel
          key={index}
          label={name}
          control={
            <Checkbox
              name={value}
              disabled={isViewMode}
              onChange={(e) => tableHandleChange(e, value)}
              checked={getCheckedValues(value)}
              sx={{ "&.Mui-checked": { color: "#E46D35" } }}
            />
          }
        />
      </Box>
    ));
  };

  const handleSubmit = () => {
    // let filterAllow = access.filter((item) => item.allowed === true);
    createAccess(userId, access)
      .then((res) => navigate(ROUTE_PATHS.COMPANYUSERS))
      .catch((res) => console.log(res));
  };

  useEffect(() => {
    if (userId) {
      getUsersDetailsById(userId).then((res) => {
        res.data.formAccess?.length && setAccess(res.data.formAccess);
      });
    }
  }, [userId]);

  return (
    <div>
      <FormControlLabel
        style={{ width: "100%" }}
        control={
          <Checkbox
            onChange={allBoxHandleChange}
            // checked={allBox}
            disabled={isViewMode}
            sx={{
              "& .MuiSvgIcon-root": { fontSize: 28 },
              "&.Mui-checked": { color: "#E46D35" },
            }}
          />
        }
        label="ALL"
      />
      {sidebarData?.map((sec, index) => (
        <>
          <FormControlLabel
            label={sec.name}
            key={index}
            style={{ marginTop: 15 }}
            control={
              <Checkbox
                name={sec.value}
                disabled={isViewMode}
                onChange={(e) => secHandleChange(e, sec.value)}
                checked={secCheckedValues(sec.value)}
                sx={{ "&.Mui-checked": { color: "#E46D35" } }}
              />
            }
          />
          {sec?.buttons?.map((item, index) => {
            return (
              <>
                <Box sx={{ display: "flex", flexDirection: "column", ml: 5 }}>
                  <FormControlLabel
                    label={item.name}
                    key={index}
                    control={
                      <Checkbox
                        name={item.value}
                        disabled={isViewMode}
                        onChange={(e) => tableHandleChange(e, item.value)}
                        checked={getCheckedValues(item.value)}
                        sx={{ "&.Mui-checked": { color: "#E46D35" } }}
                      />
                    }
                  />
                </Box>
                {sec.name === "Due Dilligence Description" &&
                  children(item.buttons, item.value)}
              </>
            );
          })}
        </>
      ))}
      <div className={btnContainer}>
        <Button
          variant="contained"
          className={cancelBtn}
          onClick={() => {
            handleBack();
            navigate("/companyUsersForm/userForm");
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          className={saveBtn}
          onClick={handleSubmit}
        >
          {userId ? "Update" : "Save"}
        </Button>
      </div>
    </div>
  );
}
