/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import { filterStyle } from "../../../styles/dashboard";
import { useFormik } from "formik";
import { useNavigate, createSearchParams } from "react-router-dom";
import { ROUTE_PATHS } from "../../../routes/routePath";
import {
  filterTypeSeeds,
  invoiceFilter,
  invoiceFilterInitial,
} from "../../../constant";
import { getPaymentStatus } from "../../../api/api";
import { CustomButton, CustomTextField, DropdownField } from "..";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 712,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const InvoiceFilter = () => {
  const useStyles = filterStyle();
  const [seeds, getSeeds] = useState();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = async (data) => {
    let workData = { ...data };
    Object.keys(workData).map((item) => {
      if (!workData[item]) {
        delete workData[item];
      }
    });

    let filter = {
      invoiceNo: {
        [workData.invoiceNoType]:
          workData.invoiceNoType === "in" || workData.invoiceNoType === "notIn"
            ? [...workData.invoiceNo.split(",")]
            : workData.invoiceNo,
      },
      paymentStatusId: {
        [workData?.paymentStatusType || "eq"]:
          workData.paymentStatusType === "in" ||
          workData.paymentStatusType === "notIn"
            ? [...workData.paymentStatusId.split(",")]
            : workData.paymentStatusId,
      },
    };

    Object.keys(filter).map((item) => {
      if (
        filter[item].eq === undefined &&
        filter[item].ne === undefined &&
        filter[item].in === undefined &&
        filter[item].notIn === undefined
      ) {
        delete filter[item];
      }
    });

    navigate({
      pathname: ROUTE_PATHS.INVOICES,
      search: `?${createSearchParams({
        filter: JSON.stringify(filter),
      })}`,
    });
    setOpen(false);
    resetForm();
  };
  //to handle formik data
  const formik = useFormik({
    initialValues: invoiceFilterInitial,
    onSubmit,
  });

  useEffect(() => {
    getPaymentStatus().then((response) => {
      getSeeds(response.data);
    });
  }, []);
  const { values, handleChange, resetForm, handleBlur } = formik;

  return (
    <div>
      <FilterListIcon className={useStyles.topbarIcons} onClick={handleOpen} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={useStyles.title}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Filter
            </Typography>
            <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className={useStyles.invoiceTitle}>
              <Typography
                id="modal-modal-description"
                sx={{ p: 3 }}
                style={{ width: 130 }}
              >
                Invoice No
              </Typography>
              <DropdownField
                label={invoiceFilter.invoiceNoType.label}
                inputValues={filterTypeSeeds || []}
                name={invoiceFilter.invoiceNoType.name}
                onChange={handleChange}
                className={useStyles.titleContentStyle}
                value={values.invoiceNoType}
              />
              <CustomTextField
                label={invoiceFilter.invoiceNo.label}
                name={invoiceFilter.invoiceNo.name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.invoiceNo}
                variant="outlined"
                logInField
                style={{ width: "220px" }}
              />
            </div>
            <div className={useStyles.invoiceTitle}>
              <Typography id="modal-modal-description" sx={{ p: 3 }}>
                Payment Status
              </Typography>
              <DropdownField
                label={invoiceFilter.paymentStatusType.label}
                inputValues={filterTypeSeeds || []}
                name={invoiceFilter.paymentStatusType.name}
                onChange={handleChange}
                className={useStyles.titleContentStyle}
                value={values.paymentStatusType}
              />
              <DropdownField
                label={invoiceFilter.paymentStatus.label}
                inputValues={seeds || []}
                name={invoiceFilter.paymentStatus.name}
                onChange={handleChange}
                value={values.paymentStatus}
                className={useStyles.titleContentStyle}
                variant="outlined"
              />
            </div>
            <div className="buttonModal">
              <Typography
                id="modal-modal-description"
                sx={{ p: 3 }}
                onClick={handleClose}
                style={{ cursor: "pointer" }}
                className={useStyles.cancelButton}
              >
                CANCEL
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ p: 3 }}
                onClick={() => resetForm()}
                style={{ cursor: "pointer" }}
                className={useStyles.cancelButton}
              >
                CLEAR FILTER
              </Typography>
              <CustomButton
                variant="contained"
                label="Apply"
                className={useStyles.saveButton}
                type="submit"
              />
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default InvoiceFilter;
