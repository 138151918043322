import React from "react";
import { makeStyles } from "@mui/styles";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { FormHelperText } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  textArea: {
    width: "96.5%",
    resize: "none",
    padding: "16px",
    borderRadius: 5,
    fontSize: "1.1rem",
    letterSpacing: "inherit",
    borderColor: "rgba(197, 195, 195,1)",
    fontFamily: "Roboto,Helvetica,Arial",
    color: "#424242",

    "&::placeholder": {
      // padding: "16.5px 12px",
      fontWeight: 400,
      fontSize: "1rem",
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
}));

export function CustomTextarea(props) {
  const { textArea } = useStyles();
  const { name, onChange, label, onBlur, error, value, style, helpertext } =
    props;

  return (
    <>
      <TextareaAutosize
        aria-label="minimum height"
        autoComplete="off"
        placeholder={label}
        minRows={6}
        className={textArea}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        value={value}
        style={style}
      />
      <FormHelperText style={{ marginLeft: "16px", color: "#d33730" }}>
        {helpertext}
      </FormHelperText>
    </>
  );
}
