import * as yup from "yup";
// import {
//   MOBILE_NUMBER_PATTERN,
//   SPECIAL_CHARACTER_PATTERN,
// } from "../utils/regexPattern";

//login page validation
export const logInPageValidation = () => {
  const messages = {
    emailId: ["Email Id is required", "Enter valid email id"],
    password: ["Password is required"],
  };

  return yup.object().shape({
    emailId: yup
      .string()
      .trim()
      .required(messages.emailId[0])
      .email(messages.emailId[1]),
    password: yup.string().trim().required(messages.password[0]),
  });
};

//forget password validation
export const forgetPasswordValidation = () => {
  const messages = {
    emailId: ["Email Id is required"],
  };
  return yup.object().shape({
    emailId: yup.string().trim().required(messages.emailId[0]),
  });
};

//update Password validation
export const updatePasswordValidation = () => {
  const messages = {
    password: ["Password is required"],
    confirmNewPassword: ["Confirm New Password is required"],
  };

  return yup.object().shape({
    password: yup.string().trim().required(messages.password[0]),
    confirmNewPassword: yup
      .string()
      .trim()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required(messages.confirmNewPassword[0]),
  });
};

//my user validation

export const myUserValidation = () => {
  const messages = {
    name: ["Name is Required"],
    emailId: ["Email is Required", "Enter the valid email"],
    mobileNo: ["Mobile  is Required"],
    password: ["Password is required"],
    confirmPassword: ["Confirm  Password is required"],
  };

  return yup.object().shape({
    name: yup.string().trim().required(messages.name[0]),
    //   .matches(SPECIAL_CHARACTER_PATTERN, "Enter the valid name"),
    emailId: yup
      .string()
      .email(messages.emailId[1])
      .trim()
      .required(messages.emailId[0]),

    mobileNo: yup.string().required(messages.mobileNo[0]),
    //   .matches(MOBILE_NUMBER_PATTERN, "Phone number is not valid"),

    password: yup.string().trim().required(messages.password[0]),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });
};

export const changePasswordToSchema = () => {
  const messages = {
    oldPassword: ["Old Password is required"],
    newPassword: ["New Password is required"],
    confirmNewPassword: ["Confirm New Password is required"],
  };

  return yup.object().shape({
    oldPassword: yup.string().trim().required(messages.oldPassword[0]),
    newPassword: yup.string().trim().required(messages.newPassword[0]),
    confirmNewPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match")
      .required(messages.confirmNewPassword[0]),
  });
};

export const companyAdminEditProfileValidation = () => {
  const messages = {
    name: ["Name is Required"],
    emailId: ["Email is Required", "Enter the valid email"],
    mobileNo: ["MobileNo is Required"],
  };

  return yup.object().shape({
    name: yup.string().trim().required(messages.name[0]),
    //   .matches(SPECIAL_CHARACTER_PATTERN, "Enter the valid name"),
    emailId: yup
      .string()
      .email(messages.emailId[1])
      .trim()
      .required(messages.emailId[0]),

    mobileNo: yup.string().required(messages.mobileNo[0]),
    //   .matches(MOBILE_NUMBER_PATTERN, "Phone number is not valid"),
  });
};

export const updateStatusValidation = () => {
  const messages = {
    statusId: ["Status is required"],
    effectiveDate: ["Effective Date is required"],
    reason: ["Reason field is required"],
  };

  return yup.object().shape({
    statusId: yup.string().trim().required(messages.statusId[0]),
    effectiveDate: yup.string().trim().required(messages.effectiveDate[0]),
    reason: yup.string().trim().required(messages.reason[0]),
  });
};

export const invoiceSetupValidation = () => {
  const messages = {
    invoiceNo: ["Invoice No is required"],
    amount: ["Amount Date is required"],
    invoice: ["Upload Invoice field is required"],
  };

  return yup.object().shape({
    invoiceNo: yup.string().trim().required(messages.invoiceNo[0]),
    amount: yup.string().trim().required(messages.amount[0]),
    invoice: yup.string().trim().required(messages.invoice[0]),
  });
};

export const entryFormValidation = () => {
  const messages = {
    financialYear: [" Year is required"],
    informationText: ["Description is required"],
    saveAs: ["Save As field is required"],
  };

  return yup.object().shape({
    financialYear: yup.string().trim().required(messages.financialYear[0]),
    informationText: yup.string().trim().required(messages.informationText[0]),
    saveAs: yup.string().trim().required(messages.saveAs[0]),
  });
};

export const informationDetailsValidation = () => {
  const messages = {
    fileType: ["Document Type is required"],
    fileContent: ["File Name is required"],
    uploadFile: ["Upload Document field is required"],
  };

  return yup.object().shape({
    fileType: yup.string().trim().required(messages.fileType[0]),
    fileContent: yup.string().trim().required(messages.fileContent[0]),
    uploadFile: yup.string().trim().required(messages.uploadFile[0]),
  });
};

export const addressingPriorityOtherInformationValidation = () => {
  const messages = {
    uploadInformation: ["Information Text is required"],
    fileContent: ["File Name is required"],
    fileType: ["Document Type is required"],
    uploadFile: ["Upload Document field is required"],
  };

  return yup.object().shape({
    uploadInformation: yup
      .string()
      .trim()
      .required(messages.uploadInformation[0]),
    fileContent: yup.string().trim().required(messages.fileContent[0]),
    fileType: yup.string().trim().required(messages.fileType[0]),
    uploadFile: yup.string().trim().required(messages.uploadFile[0]),
  });
};

export const adverseImpactValidation = () => {
  const messages = {
    financialYear: [" Year is required"],
    adverseImpact: ["Adverse Impacts Identified is required"],
    saveAs: ["Save As is required"],
  };

  return yup.object().shape({
    financialYear: yup.string().trim().required(messages.financialYear[0]),
    adverseImpact: yup.string().trim().required(messages.adverseImpact[0]),
    saveAs: yup.string().trim().required(messages.saveAs[0]),
  });
};

export const adverseIdentityValidation = () => {
  const messages = {
    assessmentImpact: ["Assessment Impact Description is required"],
    plan: ["Plan To Cease & Mitigate Risk is required"],
    result: ["Results or Expected Results field is required"],
  };

  return yup.object().shape({
    assessmentImpact: yup
      .string()
      .trim()
      .required(messages.assessmentImpact[0]),
    plan: yup.string().trim().required(messages.plan[0]),
    result: yup.string().trim().required(messages.result[0]),
  });
};

export const saveModalValidation = () => {
  const messages = {
    sendEmail: ["Send Request is required"],
    emailAddresses: ["Email Address is required"],
    subject: ["Subject is required"],
    message: ["Message field is required"],
  };

  return yup.object().shape({
    sendEmail: yup.string().trim().required(messages.sendEmail[0]),

    emailAddresses: yup.string().when(`sendEmail`, {
      is: "true",
      then: yup.string().required(messages.emailAddresses[0]),
    }),
    subject: yup.string().when(`sendEmail`, {
      is: "true",
      then: yup.string().required(messages.subject[0]),
    }),
    message: yup.string().when(`sendEmail`, {
      is: "true",
      then: yup.string().required(messages.message[0]),
    }),
  });
};

export const emailMasterFormValidation = () => {
  const messages = {
    organizationName: ["Organisation Name is required"],
    name: ["Name is required"],
    email: ["Email is required"],
  };

  return yup.object().shape({
    organizationName: yup
      .string()
      .trim()
      .required(messages.organizationName[0]),
    name: yup.string().trim().required(messages.name[0]),
    email: yup
      .string()
      .trim()
      .email("Invalid email")
      .matches(/^[\w-\.]+@([\w-]+\.)+com$/, "Invalid email")
      .required(messages.email[0]),
  });
};

export const emailCreationFormValidation = () => {
  return yup.object().shape({
    emailDate: yup.string().trim().required("Date is required"),
    name: yup.string().trim().required("Name is required"),
    senderName: yup.string().trim().required("Sender Name is required"),
    senderEmail: yup
      .string()
      .trim()
      .email("Invalid email")
      .matches(/^[\w-\.]+@([\w-]+\.)+com$/, "Invalid email")
      .required("Sender Email is required"),
    emails: yup.array().min(1).required("Recipient Email is required"),
    emailSalutation: yup
      .string()
      .trim()
      .required("Email Salutation is required"),
    emailContent: yup.string().trim().required("Email Content is required"),
    emailClosing: yup.string().trim().required("Email Closing is required"),

    scheduleDate: yup.string().trim().required("Schedule Date is required"),
    // scheduleTime: yup.string().trim().required("Schedule Time is required"),

    financialYearId: yup.string().trim().required("FinancialYear is required"),
    dataCollectionFormId: yup
      .string()
      .trim()
      .required("Topic Name  is required"),

    // financialYearId: yup.string().when(`isDataRequried`, {
    //   is: "true",
    //   then: yup.string().required("FinancialYear is required"),
    // }),
    // dataCollectionFormId: yup.string().when(`isDataRequried`, {
    //   is: "true",
    //   then: yup.string().required("Topic Name is required"),
    // }),

    followUpDays: yup.string().trim().required("No of days is required"),
    followUpTimes: yup.string().trim().required("No of times is required"),
  });
};

export const emailCancellingValidation = () => {
  const messages = {
    reason: ["Reason for cancelling is required"],
  };

  return yup.object().shape({
    reason: yup.string().trim().required(messages.reason[0]),
  });
};

export const paramterValidation = () => {
  const messages = {
    fromYear: ["From Year is required"],
    toYear: ["To Year is required"],
    fromMonth: ["From Month is required"],
    toMonth: ["To Month is required"],
  };

  return yup.object().shape({
    fromYear: yup.string().trim().required(messages.fromYear[0]),
    toYear: yup.string().trim().required(messages.toYear[0]),
    fromMonth: yup.string().trim().required(messages.fromMonth[0]),
    toMonth: yup.string().trim().required(messages.toMonth[0]),
  });
};

export const dataCollectionFormValidation = () => {
  const messages = {
    financialYearId: [" Year is required"],
    topicName: ["Topic Name is required"],
    expireDate: ["Expiry Date is required"],
    heading: ["Heading is required"],
    // subHeading: ["Sub - Heading is required"],
    certificationText: ["Acknowledgement/Certification Text is required"],
    dataCollectionText: ["Description is required"],
    helperText: ["Helpertext is required"],
  };

  return yup.object().shape({
    financialYearId: yup.string().trim().required(messages.financialYearId[0]),
    topicName: yup.string().trim().required(messages.topicName[0]),
    expireDate: yup.string().trim().required(messages.expireDate[0]),
    heading: yup.string().trim().required(messages.heading[0]),
    // subHeading: yup.string().trim().required(messages.subHeading[0]),
    formSetupData: yup.object().shape({
      certificationText: yup.string().when(`certification`, {
        is: "Yes",
        then: yup.string().required(messages.certificationText[0]),
      }),
      dataCollectionText: yup.string().when(`dataCollection`, {
        is: "Yes",
        then: yup.string().required(messages.dataCollectionText[0]),
      }),
      // helperText: yup.string().trim().required(messages.helperText[0]),
    }),
  });
};

export const emailLinkFormValidation = (
  textLength,
  documentLength,
  questionLength,
  data
) => {
  return yup.object().shape({
    // text: yup.array().when(`${data?.dataCollection}`, {
    //   is: "Yes",
    //   then: yup.array().min(textLength, "Please Fill all the fields"),
    // }),

    // document: yup.array().min(documentLength, "Please Fill all the fields"),
    text: yup.array().min(textLength, "Please Fill all the fields"),
    document: yup
      .array()
      .min(documentLength, "short")
      .of(
        yup.object().shape({
          contentText: yup
            .string()
            .trim()
            .when(`${data?.contentText}`, {
              is: "Yes",
              then: yup.string().required("Please Fill all the fields"),
            }),

          contentUpload: yup
            .string()
            .trim()
            .when(`${data?.documentUpload}`, {
              is: "Yes",
              then: yup.string().required("Please Fill all the fields"),
            }),
          remarks: yup
            .string()
            .trim()
            .when(`${data?.remarks}`, {
              is: "Yes",
              then: yup.string().required("Please Fill all the fields"),
            }),
        })
      ),
    questionnaireValues: yup.array().min(questionLength, "questionnaires"),
  });
};
