import React from "react";
import { useParams } from "react-router-dom";
import { DataCollectionForm } from "./DataCollectionForm";
import { EmailCreationForm } from "./EmailCreationForm";
import { EmailMasterForm } from "./EmailMasterForm";

export const EmailForm = () => {
  let { screen } = useParams();

  return (
    <>
      {screen === "emailMaster" && <EmailMasterForm />}
      {screen === "emailCreation" && <EmailCreationForm />}
      {screen === "dataCollectionContent" && <DataCollectionForm />}
    </>
  );
};
