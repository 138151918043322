import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import {
  CustomRadioButton,
  CustomTextField,
  CustomToggleButton,
  DropdownField,
} from "../../../reusable";
import { formStyles } from "../../../../styles";
import { SupportingData } from "../SupportingData";
import CustomReactTable from "../../../reusable/CustomReactTable";
import {
  addressingPriorityAuditlogColumn,
  addressingPriorityDataFields,
  addressingPriorityDataInitial,
  addressingPriorityDataOptions,
  addressingPrioritySupportingColumn,
  addressingPrioritySupportingFields,
  addressingPrioritySupportingInitial,
  addressingPriorityUploadInformation,
} from "../../../../constant/dataEntry/addressingPriorityConstant";
import {
  entryFormValidation,
  informationDetailsValidation,
} from "../../../../constant";
import {
  createAuditlog,
  getAdverseImpactSectionSeed,
  getAllParamter,
  getAuditlog,
  getFormById,
  getFormSeed,
  getOtherInformationDetails,
  getSupportingData,
  updateFormById,
} from "../../../../api/api";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import ErrorModalBox from "../../../reusable/ErrorModalBox";
import { ROUTE_PATHS } from "../../../../routes/routePath";
import { toastMessageAction } from "../../../../redux/action";
import { useDispatch } from "react-redux";
import { OtherInformationDetails } from "../OtherInformationDetails";

const AddressingPriority = () => {
  const {
    root,
    accordionContainer,
    formContainer,
    titleContainer,
    title,
    subTitle,
    formBox,
    divider,
    bottomBtnContainer,
    cancelBtn,
    tableContainer,
    notePara,
  } = formStyles();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const custId = cookies.get("custId");

  //modal
  // const [open, setOpen] = React.useState(false);
  const [showAuditLog, setShowAuditLog] = React.useState(false);
  const [errorModal, setErrorModal] = React.useState(false);
  const [renderTable, setRenderTable] = React.useState(false);
  const [formSeed, setFormSeed] = React.useState([]);
  const [adverseImpactSeed, setadverseImpactSeed] = React.useState([]);
  const [financialYearInput, setFinancialYearInput] = React.useState([]);
  const [formDataId, setFormDataId] = React.useState(null);
  const [informationTabledata, setInformationTableData] = React.useState({});
  const [supportingTabledata, setSupportingTabledata] = React.useState([]);
  const [deletedInformation, setDeletedInformation] = React.useState([]);
  const [deletedSupporting, setDeletedSupporting] = React.useState([]);
  const [auditlogTableData, setAuditlogTabledata] = React.useState([]);

  let { screen } = useParams();
  let getForm = formSeed?.find((item) => item.name === screen);

  // const handleClickOpen = (btnValue) => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  //formik
  const onSubmit = (values) => {
    let formValues = {
      sendEmail: false,
      formStatusId: values.saveAs,
    };

    let updateFormValue = {
      informationText: values?.informationText,
      hasAdverseImpact: false,
    };

    formDataId &&
      createAuditlog(formDataId, formValues)
        .then((res) => console.log(res))
        .catch((res) => console.log(res));

    formDataId &&
      getForm &&
      updateFormById(getForm?.id, values.financialYear, updateFormValue)
        .then((res) => console.log(res))
        .catch((res) => console.log(res));

    navigate(ROUTE_PATHS.HOMEPAGE);
    dispatch(
      toastMessageAction({
        severity: "success",
        message: "Saved Successfully !",
        messageStatus: true,
      })
    );
  };

  const formik = useFormik({
    initialValues: addressingPriorityDataInitial,
    validationSchema: entryFormValidation,
    onSubmit,
  });

  const { handleChange, handleBlur, errors, touched, values, resetForm } =
    formik;

  // let workData = {
  //   financialYear: values.financialYear,
  //   informationText: values.informationText,
  //   saveAs: values.saveAs,
  // };

  // error modal
  const errorModalOpen = () => {
    Object.keys(errors).length > 0 && setErrorModal(true);
  };

  useEffect(() => {
    formDataId
      ? getOtherInformationDetails(formDataId)
          .then((res) => setInformationTableData(res.data))
          .catch((res) => console.log(res))
      : setInformationTableData({});

    formDataId
      ? getSupportingData(formDataId)
          .then((response) => setSupportingTabledata(response.data))
          .catch((response) => console.log(response))
      : setSupportingTabledata([]);

    formDataId
      ? getAuditlog(formDataId)
          .then((response) => setAuditlogTabledata(response.data))
          .catch((response) => console.log(response))
      : setAuditlogTabledata([]);
  }, [formDataId, renderTable, screen]);

  useEffect(() => {
    getFormSeed().then((res) => setFormSeed(res.data));
    getAdverseImpactSectionSeed().then((res) => setadverseImpactSeed(res.data));
    setFormDataId(null);
    resetForm();
  }, [resetForm, screen]);

  useEffect(() => {
    getAllParamter().then((res) => {
      let filterInput = res.data.filter((item) => item.customerId === custId);
      let inputValues = filterInput.map((item) => ({
        id: item.id,
        accessor: "id",
        // name: `${moment(item.year.split("-")[0]).format("ll")} - ${moment(
        //   item.year.split("-")[1]
        // ).format("ll")}`,
        name: item.year,
      }));
      setFinancialYearInput(inputValues);
    });
  }, [custId]);

  useEffect(() => {
    getForm &&
      values.financialYear &&
      getFormById(getForm?.id, values.financialYear)
        .then((res) => {
          setFormDataId(res.data.id);
          values.informationText = res.data.informationText;
        })
        .catch((res) => console.log(res));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.financialYear]);

  useEffect(() => {
    setInformationTableData({});
    setSupportingTabledata([]);
    setAuditlogTabledata([]);
  }, [screen]);

  console.log(informationTabledata);

  return (
    <div className={root}>
      <div className={accordionContainer}>
        <CustomToggleButton />
      </div>
      <div className={formContainer}>
        <div className={titleContainer}>
          <Typography variant="h6" className={title}>
            Addressing Priority Adverse Impacts (based on Assessment)
          </Typography>
        </div>
        <Box className={formBox}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container rowSpacing={3} columnSpacing={3} p={4}>
              <Grid item xs={5}>
                <DropdownField
                  label={addressingPriorityDataFields.financialYear.label}
                  name={addressingPriorityDataFields.financialYear.name}
                  value={values.financialYear || ""}
                  onChange={handleChange}
                  error={touched.financialYear && errors.financialYear}
                  onBlur={handleBlur}
                  inputValues={financialYearInput}
                />
              </Grid>
              <Grid item xs={7}>
                <CustomTextField
                  name={addressingPriorityDataFields.informationText.name}
                  label={addressingPriorityDataFields.informationText.label}
                  type={addressingPriorityDataFields.informationText.type}
                  onChange={handleChange}
                  value={values.informationText}
                  error={touched.informationText && errors.informationText}
                  onBlur={handleBlur}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={12} className={notePara}>
                Note : Above fields must be completed prior to Continuing
              </Grid>
              <Grid item xs={12} className={notePara}>
                Note : Type text in Description or See Upload Information
              </Grid>
            </Grid>
            <Divider className={divider} />

            <Typography className={subTitle}>
              Upload Information Details (Optional)
            </Typography>

            {addressingPriorityUploadInformation?.map((item, index) => {
              return (
                <OtherInformationDetails
                  otherInformationDataInitial={item?.initialValue}
                  otherInformationDataValidation={item?.validation}
                  otherInformationDataFields={item?.fields}
                  tableColumn={item?.tableColumn(
                    setInformationTableData,
                    setRenderTable,
                    setDeletedInformation,
                    informationTabledata,
                    financialYearInput
                  )}
                  tableRawdata={informationTabledata}
                  setRenderTable={setRenderTable}
                  formValues={values}
                  formDataId={formDataId}
                  adverseImpactSeed={adverseImpactSeed?.[index]}
                />
              );
            })}

            <SupportingData
              supportingDataFields={addressingPrioritySupportingFields}
              supportingDataInitial={addressingPrioritySupportingInitial}
              supportingDataValidation={informationDetailsValidation}
              tableColumn={addressingPrioritySupportingColumn(
                setSupportingTabledata,
                setRenderTable,
                setDeletedSupporting,
                supportingTabledata
              )}
              tableRawdata={supportingTabledata}
              setRenderTable={setRenderTable}
              formDataId={formDataId}
            />

            <Grid container rowSpacing={3} columnSpacing={3} pl={4}>
              <Grid item xs={5}>
                <CustomRadioButton
                  label={addressingPriorityDataFields.saveAs.label}
                  name={addressingPriorityDataFields.saveAs.name}
                  value={values.saveAs}
                  onChange={handleChange}
                  error={touched.saveAs && errors.saveAs}
                  onBlur={handleBlur}
                  inputValues={addressingPriorityDataOptions}
                  helpertext={touched.saveAs && errors.saveAs}
                />
              </Grid>
            </Grid>

            <Box className={bottomBtnContainer}>
              <Button
                variant="outlined"
                className={cancelBtn}
                onClick={() => navigate(ROUTE_PATHS.HOMEPAGE)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="success"
                type="submit"
                onClick={() => {
                  formik.handleSubmit();
                  errorModalOpen();
                }}
              >
                Submit
              </Button>
            </Box>
          </form>
          <Divider className={divider} />

          <Typography
            className={subTitle}
            onClick={() => setShowAuditLog(!showAuditLog)}
          >
            {showAuditLog ? "Hide Audit Logs" : "Show Audit Logs"}
          </Typography>
          {showAuditLog && (
            <div className={tableContainer}>
              <CustomReactTable
                columnData={addressingPriorityAuditlogColumn}
                rawData={auditlogTableData}
                disablePagination
                disableRowSelection={true}
                disableColumnHiding={true}
                disableSort={true}
                style={{
                  th: {
                    color: "#0000008A",
                    font: "normal normal bold 14px/19px Roboto",
                    display: "flex !important",
                    alignItems: "center",
                    height: "64px !important",
                  },
                  body: {
                    position: "relative",
                    zIndex: "0",
                    backgroundColor: "white",
                    color: "#40403A",
                    font: "normal normal bold 14px/19px Roboto",
                  },
                }}
              />
            </div>
          )}
        </Box>
      </div>
      {/* <DialogBox
        open={open}
        handleClose={handleClose}
        workData={workData}
        formDataId={formDataId}
        deletedInformation={deletedInformation}
        deletedSupporting={deletedSupporting}
        formId={getForm && getForm.id}
      /> */}
      <ErrorModalBox setErrorModal={setErrorModal} errorModal={errorModal} />
    </div>
  );
};

export default AddressingPriority;
