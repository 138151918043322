import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { updateStatusStyle } from "../../styles/dashboard";
import { useNavigate } from "react-router-dom";
import { updateInvoiceDetails } from "../../api/api";
import {
  formGroup,
  updatePaymentStatusContentInitial,
} from "../../constant/updatePaymentStatusContent";
import { Grid } from "@mui/material";
import { CustomButton, CustomRadioButton, CustomTextField } from ".";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 712,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const UpdatePaymentStatus = (props) => {
  const useStyles = updateStatusStyle();
  const { originalId, pathName } = props;

  const navigate = useNavigate();
  const seedData = useSelector((state) => state.toastMessageStateReducer.seeds);
  console.log("seedData: ", seedData);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = async (data) => {
    await updateInvoiceDetails(originalId.id, data).then((response) => {
      setOpen(false);
      props.setAnchorEl(false);
      props.setStatus(!props.status);
      navigate(pathName);
    });
  };
  //to handle formik data
  const formik = useFormik({
    initialValues: updatePaymentStatusContentInitial,
    onSubmit,
  });

  const { handleChange, handleBlur, errors, touched, values } = formik;

  // useEffect(() => {
  //   getInvoiceDetailsById(originalId.id).then((response) => {
  //     console.log("response: ", response);
  //     setValues(response.data);
  //   });
  // }, [originalId, setValues]);

  return (
    <div className="editTableStyle">
      <span onClick={handleOpen}>Update Status</span>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={useStyles.title}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Update payment : Invoice No
            </Typography>
            <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
          </div>

          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={3}
              p={4}
              className={useStyles.form}
            >
              <Grid item xs={12}>
                <CustomRadioButton
                  name={formGroup.paymentStatusId.name}
                  label={formGroup.paymentStatusId.label}
                  inputValues={seedData?.paymentStatus || []}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.paymentStatusId}
                  error={touched.paymentStatusId && errors.paymentStatusId}
                  helperText={touched.paymentStatusId && errors.paymentStatusId}
                  style={{ marginLeft: "10px" }}
                />
              </Grid>

              <Grid item xs={12}>
                <CustomTextField
                  name={formGroup.pymtReferenceNo.name}
                  label={formGroup.pymtReferenceNo.label}
                  variant={formGroup.pymtReferenceNo.variant}
                  type={formGroup.pymtReferenceNo.type}
                  onChange={handleChange}
                  value={values.pymtReferenceNo}
                  error={touched.pymtReferenceNo && errors.pymtReferenceNo}
                  onBlur={handleBlur}
                  fullWidth={true}
                />
              </Grid>
            </Grid>

            <div className="buttonModal">
              <Typography
                id="modal-modal-description"
                style={{ cursor: "pointer" }}
                className={useStyles.cancelButton}
                onClick={handleClose}
              >
                CANCEL
              </Typography>

              <CustomButton
                variant="contained"
                label="UPDATE"
                className={useStyles.updateButton}
                type="submit"
              />
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default UpdatePaymentStatus;
