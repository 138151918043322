export const emailId = {
  name: "emailId",
  label: "Email Id *",
  type: "text",
  variant: "outlined",
};

export const password = {
  name: "password",
  label: "Password *",
  variant: "outlined",
  type: "password",
};

export const loginInitial = {
  emailId: "",
  password: "",
};

export const forgetInitial = {
  emailId: "",
};

export const confirmNewPassword = {
  name: "confirmNewPassword",
  label: "Confirm NewPassword *",
  variant: "outlined",
  type: "password",
};

export const updatePasswordInitial = {
  password: "",
  confirmNewPassword: "",
};
