import React, { useCallback, useEffect } from "react";
import { Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import {
  CustomAutoComplete,
  CustomRadioButton,
  CustomTextarea,
  CustomTextField,
  CustomToggleButton,
  DropdownField,
} from "../../reusable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../routes/routePath";
import {
  dataCollectionFields,
  // dataCollectionInitialValues,
  formSetupDataFields,
  dataCollectionFormList,
  dataCollectionFormValidation,
  dataColllectionHelperText,
} from "../../../constant";
import { useFormik } from "formik";
import DatePickerField from "../../reusable/DatePickerField";
import {
  createDataCollection,
  getAllParamter,
  getDataformById,
  updateDataformCollection,
} from "../../../api/api";
import Cookies from "universal-cookie";
import CustomReactTable from "../../reusable/CustomReactTable";
import { useDispatch, useSelector } from "react-redux";
import { toastMessageAction, viewFormData } from "../../../redux/action";
import ErrorModalBox from "../../reusable/ErrorModalBox";
import HelperText from "../../reusable/HelperText";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  accordionContainer: {
    width: "320px",
    height: "calc(100vh - 64px)",
    position: "fixed",
    overflowY: "scroll",
    backgroundColor: "white",
    borderRight: "2px solid #7394c6",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent !important",
      width: "0px !important",
    },
  },
  formContainer: {
    width: "calc(98vw - 330px)",
    position: "absolute",
    left: "330px",
    padding: "0 20px 20px 20px",
    boxSizing: "border-box",
  },
  helperTextContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  formBox: {
    padding: "20px",
    border: "3px solid #2A5BA8",
    borderRadius: "4px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    margin: "20px 0",
  },
  title: {
    fontWeight: "bold !important",
  },
  arrowBtn: {
    color: "black !important",
    marginRight: "10px !important",
  },
  saveBtnContainer: {
    width: "95.5%",
    margin: "16px auto",
    display: "flex",
    justifyContent: "center",
  },
  saveBtn: {
    padding: "6px 30px !important",
    backgroundColor: "#59B961 !important",
  },
  cancelBtn: {
    padding: "6px 30px !important",
    backgroundColor: "#C0C1CE !important",
  },
  // viewBtn: {
  //   padding: "6px 30px !important",
  //   backgroundColor: "#C0C1CE !important",
  // },
  divider: {
    backgroundColor: "#40403A ",
    height: "2px",
    // width: "94%",
  },
  addBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  notePara: {
    marginTop: "-22px !important",
    font: "normal normal normal 16px/19px Lato !important",
    color: "#707070 !important",
  },
}));

export const DataCollectionForm = () => {
  const {
    root,
    accordionContainer,
    formContainer,
    formBox,
    helperTextContainer,
    titleContainer,
    title,
    arrowBtn,
    cancelBtn,
    saveBtn,
    divider,
    saveBtnContainer,
    addBtnContainer,
    notePara,
  } = useStyles();

  const [financialYearInput, setFinancialYearInput] = React.useState([]);
  const [editIndex, setEditIndex] = React.useState("");
  const [errorModal, setErrorModal] = React.useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const custId = cookies.get("custId");
  const search = useLocation().search;
  const searchParam = new URLSearchParams(search);
  const editId = searchParam?.get("editId");
  const globalState = useSelector((state) => state);
  const viewFormDataValues = globalState?.toastMessageStateReducer.viewForm;

  const onSubmit = (value) => {
    editId
      ? updateDataformCollection(editId, value)
          .then((res) => navigate(ROUTE_PATHS.DATA_COLLECTION_LIST))
          .catch((res) => console.log(res))
      : createDataCollection(value)
          .then((res) => navigate(ROUTE_PATHS.DATA_COLLECTION_LIST))
          .catch((res) => {
            console.log(res);
            let errorVal =
              Object.keys(res.response.data.error).length !== 0
                ? res.response.data.error
                : "error";
            dispatch(
              toastMessageAction({
                severity: "error",
                message: errorVal,
                messageStatus: true,
              })
            );
          });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: viewFormDataValues,
    validationSchema: dataCollectionFormValidation,
    onSubmit,
  });

  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
    setValues,
    setFieldValue,
  } = formik;

  // error modal
  const errorModalOpen = useCallback(() => {
    Object.keys(errors).length > 0 && setErrorModal(true);
  }, [errors]);

  const handleAddBtn = () => {
    if (editIndex !== "") {
      values.formSetupData?.questionnaireValues?.splice(editIndex, 1);
      setEditIndex("");
    }
    if (
      values.formSetupData.question !== "" &&
      values.formSetupData.questionOptions.length !== 0
    )
      setValues({
        ...values,
        formSetupData: {
          ...values.formSetupData,
          question: "",
          questionOptions: "",
          questionnaireValues: [
            ...values.formSetupData.questionnaireValues,
            {
              Question: values.formSetupData.question,
              Answers: values.formSetupData.questionOptions,
            },
          ],
        },
      });
  };

  const checkDuplicateValues = (newValue) => {
    let checkDuplicate = [];

    newValue.map((item, index) =>
      typeof item === "string" // to check empty space manually
        ? checkDuplicate.length === 0
          ? item.trim().length !== 0 &&
            checkDuplicate.push({
              id: `${index + 1}`,
              name: item?.name || item,
            })
          : checkDuplicate.find(
              (dup) => dup?.name === item?.name || dup?.id === item?.name
            )
          ? null
          : item.trim().length !== 0 &&
            checkDuplicate.push({
              id: `${index + 1}`,
              name: item?.name || item,
            })
        : // else
        checkDuplicate.length === 0
        ? checkDuplicate.push({
            id: `${index + 1}`,
            name: item?.name || item,
          })
        : checkDuplicate.find(
            (dup) => dup?.name === item?.name || dup?.id === item?.name
          )
        ? null
        : checkDuplicate.push({
            id: `${index + 1}`,
            name: item?.name || item,
          })
    );

    setValues({
      ...values,
      formSetupData: {
        ...values.formSetupData,
        questionOptions: checkDuplicate,
      },
    });
  };

  useEffect(() => {
    getAllParamter().then((res) => {
      let filterInput = res.data.filter((item) => item.customerId === custId);
      let inputValues = filterInput.map((item) => ({
        id: item.id,
        accessor: "id",
        // name: `${moment(item.year.split("-")[0]).format("ll")} - ${moment(
        //   item.year.split("-")[1]
        // ).format("ll")}`,
        name: item.year,
      }));
      setFinancialYearInput(inputValues);
    });
  }, [custId]);

  useEffect(() => {
    editId &&
      getDataformById(editId)
        .then((res) => setValues(res.data))
        .catch((res) => console.log(res));
  }, [editId, setValues]);

  return (
    <div className={root}>
      <div className={accordionContainer}>
        <CustomToggleButton />
      </div>
      <div className={formContainer}>
        <div className={titleContainer}>
          <IconButton
            className={arrowBtn}
            onClick={() => navigate(ROUTE_PATHS.DATA_COLLECTION_LIST)}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" className={title}>
            New Data Collection Form Design
          </Typography>
        </div>
        <div className={formBox}>
          <Grid container rowSpacing={4} columnSpacing={4} p={3}>
            <Grid
              item
              xs={12}
              className={helperTextContainer}
              style={{ paddingTop: 10, marginBottom: "-30px" }}
            >
              <HelperText
                position="left"
                text={dataColllectionHelperText.form}
              />
            </Grid>
            {dataCollectionFields.map((item, index) => {
              if (item.type === "text") {
                return (
                  <Grid item xs={item.xs} key={index}>
                    <CustomTextField
                      name={item.name}
                      label={item.label}
                      type={item.type}
                      onChange={handleChange}
                      value={values[item.name]}
                      error={touched[item.name] && errors[item.name]}
                      onBlur={handleBlur}
                      fullWidth={true}
                    />
                  </Grid>
                );
              }
              if (item.type === "date") {
                return (
                  <Grid item xs={item.xs} key={index}>
                    <DatePickerField
                      name={item.name}
                      label={item.label}
                      type={item.type}
                      value={values[item.name]}
                      fullWidth={true}
                      minDate={new Date()}
                      onChange={(val) => {
                        setFieldValue(item.name, val);
                      }}
                      error={touched[item.name] && errors[item.name]}
                      helperText={touched[item.name] && errors[item.name]}
                    />
                  </Grid>
                );
              }
              if (item.type === "select") {
                return (
                  <Grid item xs={item.xs} key={index}>
                    <DropdownField
                      name={item.name}
                      label={item.label}
                      value={values[item.name]}
                      fullWidth={true}
                      inputValues={financialYearInput || []}
                      onChange={handleChange}
                      error={touched[item.name] && errors[item.name]}
                      helperText={touched[item.name] && errors[item.name]}
                    />
                  </Grid>
                );
              }
              return "";
            })}
            <Grid item xs={12}>
              <Divider className={divider} />
            </Grid>
            {formSetupDataFields.map((item, index) => {
              if (item.type === "text") {
                return (
                  values.formSetupData[item.accessor] === "Yes" && (
                    <Grid item xs={item.xs} key={index}>
                      <CustomTextField
                        name={item.name}
                        label={item.label}
                        type={item.type}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            formSetupData: {
                              ...values.formSetupData,
                              [item.name]: e.target.value,
                            },
                          });
                        }}
                        value={values.formSetupData[item.name]}
                        error={
                          touched?.formSetupData &&
                          touched?.formSetupData[item.name] &&
                          errors?.formSetupData &&
                          errors?.formSetupData[item.name]
                        }
                        onBlur={handleBlur}
                        fullWidth={true}
                      />
                    </Grid>
                  )
                );
              }
              if (item.type === "textarea") {
                return (
                  values.formSetupData.certification === "Yes" && (
                    <Grid item xs={item.xs} key={index}>
                      <CustomTextarea
                        name={item.name}
                        label={item.label}
                        type={item.type}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            formSetupData: {
                              ...values.formSetupData,
                              [item.name]: e.target.value,
                            },
                          });
                        }}
                        value={values.formSetupData[item.name]}
                        helpertext={
                          touched?.formSetupData &&
                          touched?.formSetupData[item.name] &&
                          errors?.formSetupData &&
                          errors?.formSetupData[item.name]
                        }
                        onBlur={handleBlur}
                        fullWidth={true}
                      />
                    </Grid>
                  )
                );
              }
              if (item.type === "radio") {
                return item.toShowValue ? (
                  values.formSetupData[item.toShowValue] === "Yes" && (
                    <Grid item xs={item.xs} key={index}>
                      <CustomRadioButton
                        name={item.name}
                        label={item.label}
                        type={item.type}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            formSetupData: {
                              ...values.formSetupData,
                              [item.name]: e.target.value,
                            },
                          });
                        }}
                        value={values.formSetupData[item.name]}
                        error={touched[item.name] && errors[item.name]}
                        onBlur={handleBlur}
                        fullWidth={true}
                        inputValues={item.inputValues}
                        vertical
                        helperPoper={item.helperPoper}
                      />
                    </Grid>
                  )
                ) : (
                  <Grid item xs={item.xs} key={index}>
                    <CustomRadioButton
                      name={item.name}
                      label={item.label}
                      type={item.type}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          formSetupData: {
                            ...values.formSetupData,
                            [item.name]: e.target.value,
                          },
                        });
                      }}
                      value={values.formSetupData[item.name]}
                      error={touched[item.name] && errors[item.name]}
                      onBlur={handleBlur}
                      fullWidth={true}
                      inputValues={item.inputValues}
                      vertical
                      helperPoper={item.helperPoper}
                    />
                  </Grid>
                );
              }
              if (item.type === "divider") {
                return (
                  <Grid item xs={12} key={index}>
                    <Divider className={divider} />
                  </Grid>
                );
              }
              return "";
            })}
            <Grid item xs={12}>
              <span style={{ display: "flex", alignItems: "center" }}>
                SECTION NAME : Questionnaire{" "}
                <HelperText
                  position={"right"}
                  text={dataColllectionHelperText.questionnaire}
                />
              </span>
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name={"question"}
                label={"Question"}
                type={"text"}
                onChange={(e) => {
                  setValues({
                    ...values,
                    formSetupData: {
                      ...values.formSetupData,
                      question: e.target.value,
                    },
                  });
                }}
                value={values.formSetupData["question"]}
                error={touched["question"] && errors["question"]}
                onBlur={handleBlur}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomAutoComplete
                name={"questionOptions"}
                label={"Answers"}
                inputValues={[
                  { id: "1", name: "Yes" },
                  { id: "2", name: "No" },
                  { id: "3", name: "Not Applicable" },
                ]}
                onChange={(e, newValue) => {
                  checkDuplicateValues(newValue);
                }}
                value={values.formSetupData["questionOptions"]}
                error={touched["questionOptions"] && errors["questionOptions"]}
                onBlur={handleBlur}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} className={notePara}>
              Note : For additional Questions, complete both boxes above and
              click “Add”
            </Grid>
            <Grid item xs={12} className={addBtnContainer}>
              <Button variant="contained" onClick={handleAddBtn}>
                ADD
              </Button>
            </Grid>
            <Grid item xs={12}>
              <CustomReactTable
                columnData={dataCollectionFormList(
                  values,
                  setValues,
                  setEditIndex
                )}
                rawData={values?.formSetupData?.questionnaireValues || []}
                disableRowSelection
                disableColumnHiding
                disablePagination
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name={"helperText"}
                label={"Helpertext"}
                type={"text"}
                onChange={(e) => {
                  setValues({
                    ...values,
                    formSetupData: {
                      ...values.formSetupData,
                      helperText: e.target.value,
                    },
                  });
                }}
                value={values.formSetupData["helperText"]}
                error={touched["helperText"] && errors["helperText"]}
                onBlur={handleBlur}
                fullWidth={true}
              />
            </Grid>
          </Grid>

          <div className={saveBtnContainer}>
            <Button
              variant="contained"
              className={cancelBtn}
              type="submit"
              onClick={() => navigate(ROUTE_PATHS.DATA_COLLECTION_LIST)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              onClick={() => {
                dispatch(
                  viewFormData({
                    ...values,
                    financialYearInput,
                  })
                );
                navigate(ROUTE_PATHS.VIEW_FORM);
              }}
            >
              View Form
            </Button>
            <Button
              variant="contained"
              className={saveBtn}
              type="submit"
              onClick={() => {
                formik.handleSubmit();
                errorModalOpen();
              }}
            >
              {editId ? "Update" : "Submit"}
            </Button>
          </div>
        </div>
      </div>
      <ErrorModalBox setErrorModal={setErrorModal} errorModal={errorModal} />
    </div>
  );
};
