import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createSearchParams, useNavigate } from "react-router-dom";
import { EmailCancelModal } from ".";

const useStyles = makeStyles((theme) => ({
  popover: {
    "& .MuiPaper-root": {
      minWidth: "150px",
    },
  },
  titles: {
    padding: "14px !important",
    cursor: "pointer !important",
    font: "normal normal normal 16px/19px Rubik !important",
    color: "#000000 !important",
  },
}));

export function EmailPopover(props) {
  const { values, toShow, paths, func } = props;
  const { popover, titles } = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openEmail, setOpenEmail] = React.useState(false);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const navigate = useNavigate();

  const handleClick = (event) => {
    let checkValues = Object.values(toShow)?.some(
      (item) => item === true || item === "true"
    );
    if (checkValues) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //modal
  const openModal = (btnValue) => {
    setOpenEmail(true);
  };
  const closeModal = () => {
    setAnchorEl(null);
    setOpenEmail(false);
  };

  const handleEdit = (path) => {
    navigate({
      pathname: path,
      search: `?${createSearchParams({
        editId: values.row.original.id,
      })}`,
    });
  };

  return (
    <div>
      <Button aria-describedby={id} onClick={handleClick}>
        <MoreHorizTwoToneIcon />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={popover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {toShow["edit"] && (
          <Typography className={titles} onClick={() => handleEdit(paths.edit)}>
            Edit
          </Typography>
        )}
        {toShow["response"] && (
          <Typography
            className={titles}
            onClick={() => handleEdit(paths.viewResponse)}
          >
            View Responses
          </Typography>
        )}
        {toShow["cancelEmail"] && (
          <Typography className={titles} onClick={() => openModal()}>
            Cancel Email
          </Typography>
        )}
        {toShow["logHistory"] && (
          <Typography
            className={titles}
            onClick={() => handleEdit(paths.logHistory)}
          >
            Log History
          </Typography>
        )}
        {toShow["emailMasterDelete"] && (
          <Typography className={titles} onClick={() => func(true)}>
            Delete
          </Typography>
        )}
      </Popover>
      <EmailCancelModal
        open={openEmail}
        handleClose={closeModal}
        getId={values}
      />
    </div>
  );
}
