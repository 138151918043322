import * as React from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { FormHelperText } from "@mui/material";

const DatePickerField = (props) => {
  const {
    label,
    onChange,
    value,
    name,
    isViewMode,
    // openTo,
    views,
    maxDate,
    minDate,
    className,
    helperText,
    error,
    style,
    dateFieldStyle,
  } = props;

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          name={name}
          label={label}
          inputFormat="MM/dd/yyyy"
          value={value}
          onChange={onChange}
          className={className}
          readOnly={isViewMode}
          views={views}
          // openTo={openTo}
          renderInput={(params) => (
            <TextField
              {...params}
              error={error}
              fullWidth
              style={dateFieldStyle}
            />
          )}
          maxDate={maxDate}
          minDate={minDate}
          style={style}
        />
      </LocalizationProvider>
      <FormHelperText style={{ color: "#d32f2f", marginLeft: "16px" }}>
        {helperText}
      </FormHelperText>
    </>
  );
};

export default DatePickerField;
