import {
  getAllDataForms,
  getAllDataFormsSearch,
  getEmailCreation,
  getEmailCreationSearch,
  getEmailMaster,
  getEmailMasterSearch,
  getEmailResponseSearch,
  getTableEmailResponses,
} from "../../../api/api";
import {
  dataCollectionListColumn,
  emailMasterListColumn,
} from "../../../constant";
import { emailCreationListColumn } from "../../../constant/dataEntry/emailCreationList";
import { emailResponsesListColumn } from "../../../constant/dataEntry/emailResponsesList";
import { ROUTE_PATHS } from "../../../routes/routePath";

export const lookup = {
  //DD Requirements Analysis
  sizeOfOrganization: {
    name: "Size of organisation",
    api: "/organization",
    sidebarIndex: 1,
  },
  typeOfOrganization: {
    name: "Type/Nature of organisation",
    sidebarIndex: 1,
  },
  contactsOfOperation: {
    name: "Explanation/Context of Operation",
    sidebarIndex: 1,
  },
  probabilityImpacts: {
    name: "Probability of adverse impacts on HR - DWC",
    sidebarIndex: 1,
  },
  severityImpacts: {
    name: "Severity of adverse impacts on HR - DWC",
    sidebarIndex: 1,
  },
  ///Due Dilligence Description
  descriptionOfPlanProcesses: {
    name: "HR - DWC Policy",
    subName: "Description of Policies and related Processes",
    sidebarIndex: 2,
  },
  implementationCommunication: {
    name: "HR - DWC Policy",
    subName: "Implementation/Communication Efforts",
    sidebarIndex: 2,
  },
  operations: {
    name: "HR - DWC Adverse Impact Assessment Description",
    subName: "Operations",
    optional:
      "Such as Business Area/Third Party Assessment Form and Higher Risk Business Area/Third Party List",
    sidebarIndex: 2,
  },
  supplyChain: {
    name: "HR - DWC Adverse Impact Assessment Description",
    subName: "Supply Chain",
    optional:
      "Such as Business Area/Third Party Assessment Form and Higher Risk Business Area/Third Party List",

    sidebarIndex: 2,
  },
  businessRelationShips: {
    name: "HR - DWC Adverse Impact Assessment Description",
    subName: "Business Relationships",
    optional:
      "Such as Business Area/Third Party Assessment Form and Higher Risk Business Area/Third Party List",

    sidebarIndex: 2,
  },
  addressingPriority: {
    name: "Addressing Priority Adverse Impacts (based on Assessment)",
    sidebarIndex: 2,
  },
  toStakeholders: {
    name: "Communications (related to Priority Impacts)",
    subName: "To Stakeholders",
    sidebarIndex: 2,
  },
  toRightHolders: {
    name: "Communications (related to Priority Impacts)",
    subName: "To Rightholders",
    sidebarIndex: 2,
  },
  remediationCompensation: {
    name: "Required cooperation relating to remediation or compensation",
    sidebarIndex: 2,
  },

  ////Transparency Act Report Creator

  OrganizationStructure: { name: "Organisation Structure", sidebarIndex: 3 },
  areasOfOperation: { name: "Areas of Operation", sidebarIndex: 3 },
  guidelinesPolicies: {
    name: "Guidelines/policies to address Adverse Impacts on HR - DWC",
    sidebarIndex: 3,
  },
  adverseImpacts: {
    name: "Any Adverse Impacts Identified",
    sidebarIndex: 3,
  },
  reportGeneration: {
    name: "Report Generation",
    sidebarIndex: 3,
  },
  ////name: "Email Information Request Creator

  emailMaster: {
    name: "Email Master",
    sidebarIndex: 4,
    tableColumn: emailMasterListColumn,
    tableDataApi: getEmailMaster,
    searchApi: getEmailMasterSearch,
    // newFormPath: ROUTE_PATHS.EMAILMASTER_FORM,
    listPath: ROUTE_PATHS.EMAILMASTER_LIST,
  },
  emailCreation: {
    name: "Email Creator",
    sidebarIndex: 4,
    tableColumn: emailCreationListColumn,
    tableDataApi: getEmailCreation,
    searchApi: getEmailCreationSearch,
    newFormPath: ROUTE_PATHS.EMAILCREATION_FORM,
    listPath: ROUTE_PATHS.EMAILCREATION_LIST,
  },
  dataCollectionContent: {
    name: "Data Collection Form Design",
    sidebarIndex: 4,
    tableColumn: dataCollectionListColumn,
    tableDataApi: getAllDataForms,
    searchApi: getAllDataFormsSearch,
    newFormPath: ROUTE_PATHS.DATA_COLLECTION_FORM,
    listPath: ROUTE_PATHS.DATA_COLLECTION_LIST,
  },
  emailResponses: {
    name: "Email Responses",
    sidebarIndex: 4,
    tableColumn: emailResponsesListColumn,
    tableDataApi: getTableEmailResponses,
    searchApi: getEmailResponseSearch,
    // newFormPath: ROUTE_PATHS.DATA_COLLECTION_FORM,
    listPath: ROUTE_PATHS.EMAIL_RESPONSES_LIST,
  },
};
