export const apiRoutes = {
  LOG_IN_API: "userLogin",
  FORGET_PASSWORD: "forgotPassword",
  RESET_PASSWORD: "resetPassword",
  CHANGE_PASSWORD: "changePassword",

  //create user
  CREATE_USER: "createUser",
  UPDATE_USER: "updateUser",
  GET_USERS_DETAILS: "getAllUserDetailsWithCount",
  UPDATE_USER_STATUS: "updateUserStatus",
  GET_USERS_DETAILS_BY_ID: "getUserDetailsById",
  USER_ACCESS: "userAccess",
  USER_DOWNLOAD_DETAILS: "userDownloadDetails",

  //customers
  GET_ALL_CUSTOMERS_DETAILS: "getAllCustomerDetailsWithCount",
  GET_CUSTOMERS_DETAILS_BY_ID: "getCustomerDetailsById",
  UPDATE_CUSTOMERS_DETAILS: "updateCustomerDetails",
  CREATE_CUSTOMERS_DETAILS: "createCustomerDetails",
  CUSTOMER_DOWNLOAD_DETAILS: "customerDownload",

  //invoices
  GET_ALL_INVOICES_DETAILS: "getAllInvoiceDetailsWithCount",
  INVOICES_DOWNLOAD: "invoiceDownload",
  CREATE_INVOICE_DETAILS: "createInvoiceDetails",
  UPDATE_INVOICE_DETAILS: "updateInvoiceDetails",
  GET_INVOICE_DETAILS_BY_ID: "getInvoiceDetailsById",

  //seeds
  GET_PAYMENT_STATUS: "getPaymentStatus",
  GET_ALL_STATUS: "getStatus",
  GET_CUSTOMERS_ID: "getCustomers",

  GET_FORM_DATA_BY_USER: "getFormDataByCustomer",

  //get invoice setup
  GET_INVOICE_DOWNLOAD: "getInvoice",
  GET_INVOICE_SETUP: "getInvoiceDetails",

  //dashboard
  GET_GRAPH_DETAILS: "getGraphDetails",
  GET_INVOICE_GRAPH_DETAILS: "getInvoiceGraphDetails",
  GET_USER_GRAPH_DETAILS: "getUserGraphDetails",

  //send remainder
  SEND_REMAINDER: "sendReminder",

  //user

  // new api
  // parameter
  CREATE_PARAMETER: "createFinancialyear",
  GET_PARAMETER_BY_ID: "getFinancialyearById",
  GET_ALL_PARAMETER: "getFinancialyear",
  UPDATE_PARAMETER: "updateFinancialyear",

  GET_FORM_SEEDS: "getForm",
  GET_ADVERSE_IMAPCT_SECTION_SEEDS: "getAdverseImpactSection",
  CREATE_USER_ACESSS: "userAccess",

  // user
  GET_FORM: "form",
  UPDATE_FORM: "form",

  CREATE_INFORMATION_DETAILS: "informationDetails",
  GET_INFORMATION_DETAILS: "informationDetails",
  DELETE_INFORMATION_DETAILS: "informationDetails",

  CREATE_SUPPORTING_DATA: "supportingData",
  GET_SUPPORTING_DATA: "supportingData",
  DELETE_SUPPORTING_DATA: "supportingData",

  CREATE_OTHERINFORMATION_DETAILS: "otherInformationDetails",
  GET_OTHERINFORMATION_DETAILS: "otherInformationDetails",
  DELETE_OTHERINFORMATION_DETAILS: "otherInformationDetails",

  CREATE_AUDITLOG: "audit",
  GET_AUDITLOG: "audit",

  DOWNLOAD_FILE: "downloadLink",

  CREATE_ADVERSE_IMPACT: "adverseImpact",
  GET_ADVERSE_IMPACT: "adverseImpact",
  DELETE_ADVERSE_IMPACT: "adverseImpact",

  CREATE_EMAILMASTER: "createEmailMaster",
  GET_EMAILMASTER: "getEmailMaster",
  UPDATE_EMAILMASTER: "updateEmailMaster",
  GET_EMAILMASTER_BY_ID: "getEmailMasterById",
  DELETE_EMAILMASTER: "emailMaster",

  // DOWNLOAD_EMAILMASTER: "norway/downloadEmailMaster",

  GET_ALL_DATAFORMS: "getDataFormContent",
  CREATE_DATAFORM_COLLECTION: "createDataFormContent",
  GET_DATAFORM_BY_ID: "getDataFormContentById",
  UPDATE_DATAFORM_COLLECTION: "updateDataFormContent",

  CREATE_EMAILCREATION: "createEmailCreation",
  GET_EMAILCREATION: "getEmailCreation",
  UPDATE_EMAILCREATION: "updateEmailCreation",
  GET_EMAILCREATION_BY_ID: "getEmailCreationById",
  // DOWNLOAD_EMAILCREATION: "norway/downloadEmailCreation",

  GET_TABLE_EMAIL_RESPONSES: "getEmailResponses",
  GET_TABLE_EMAIL_RESPONSES_BY_ID: "getEmailResponseById",

  // linkForm
  GET_EMAIL_LINK_FORM: "getEmailForm",
  GET_EMAIL_LINK_RESPONSES: "getEmailResponse",
  CREATE_EMAIL_RESPONSES: "upsertEmailResponse",

  //imageKey
  GET_IMAGEKEY: "contentUpload",
  DOWNLOAD_IMAGE_BY_KEY: "contentDownload",

  GET_REPORT_DATA: "generateReport",

  GET_REPORT_TABLE: "report",
};
