import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import {
  entryFormAuditlogColumn,
  entryFormDataFields,
  entryFormDataInitial,
  entryFormDataOptions,
  entryFormInformationColumn,
  entryFormInformationFields,
  entryFormInformationInitial,
  entryFormSupportingColumn,
  entryFormSupportingFields,
  entryFormSupportingInitial,
  entryFormValidation,
  informationDetailsValidation,
} from "../../../constant";
import {
  CustomRadioButton,
  CustomTextField,
  CustomToggleButton,
  DropdownField,
} from "../../reusable";
import { formStyles } from "../../../styles";
import { InformationDetails } from "../../pages/dataEntry/InformationDetails";
import { SupportingData } from "../../pages/dataEntry/SupportingData";
import CustomReactTable from "../../reusable/CustomReactTable";
import { useNavigate, useParams } from "react-router-dom";
import {
  createAuditlog,
  getAllParamter,
  getAuditlog,
  getFormById,
  getFormSeed,
  getInformationDetails,
  getSupportingData,
  updateFormById,
} from "../../../api/api";
import Cookies from "universal-cookie";
import { ROUTE_PATHS } from "../../../routes/routePath";
import ErrorModalBox from "../../reusable/ErrorModalBox";
import { toastMessageAction } from "../../../redux/action";
import { useDispatch } from "react-redux";
import { lookup } from "./lookup";
// import moment from "moment";

const DATAForm = (props) => {
  const {
    root,
    accordionContainer,
    formContainer,
    titleContainer,
    title,
    formTitle,
    subTitle,
    formBox,
    divider,
    bottomBtnContainer,
    cancelBtn,
    notePara,
    tableContainer,
  } = formStyles();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const custId = cookies.get("custId");

  //modal
  // const [open, setOpen] = React.useState(false);
  const [showAuditLog, setShowAuditLog] = React.useState(false);
  const [errorModal, setErrorModal] = React.useState(false);
  const [renderTable, setRenderTable] = React.useState(false);
  const [formSeed, setFormSeed] = React.useState([]);
  const [financialYearInput, setFinancialYearInput] = React.useState([]);
  const [formDataId, setFormDataId] = React.useState(null);
  const [informationTabledata, setInformationTableData] = React.useState([]);
  const [supportingTabledata, setSupportingTabledata] = React.useState([]);
  const [deletedInformation, setDeletedInformation] = React.useState([]);
  const [deletedSupporting, setDeletedSupporting] = React.useState([]);
  const [auditlogTableData, setAuditlogTabledata] = React.useState([]);

  let { screen } = useParams();
  let getForm = formSeed?.find((item) => item.name === screen);
  let sidebarIndex = lookup?.[screen]?.sidebarIndex;

  // const handleClickOpen = (btnValue) => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  //formik
  const onSubmit = (values) => {
    // handleClickOpen();
    let formValues = {
      sendEmail: false,
      formStatusId: values.saveAs,
    };

    let updateFormValue = {
      informationText: values?.informationText,
      hasAdverseImpact: false,
    };

    formDataId &&
      createAuditlog(formDataId, formValues)
        .then((res) => console.log(res))
        .catch((res) => console.log(res));

    formDataId &&
      getForm &&
      updateFormById(getForm?.id, values.financialYear, updateFormValue)
        .then((res) => console.log(res))
        .catch((res) => console.log(res));

    navigate(ROUTE_PATHS.HOMEPAGE);
    dispatch(
      toastMessageAction({
        severity: "success",
        message: "Saved Successfully !",
        messageStatus: true,
      })
    );
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: entryFormDataInitial,
    validationSchema: entryFormValidation,
    onSubmit,
  });

  const { handleChange, handleBlur, errors, touched, values, resetForm } =
    formik;

  // let workData = {
  //   financialYear: values.financialYear,
  //   informationText: values.informationText,
  //   saveAs: values.saveAs,
  // };

  // error modal
  const errorModalOpen = () => {
    Object.keys(errors).length > 0 && setErrorModal(true);
  };

  useEffect(() => {
    formDataId
      ? getInformationDetails(formDataId)
          .then((res) => setInformationTableData(res.data))
          .catch((res) => console.log(res))
      : setInformationTableData([]);

    formDataId
      ? getSupportingData(formDataId)
          .then((response) => setSupportingTabledata(response.data))
          .catch((response) => console.log(response))
      : setSupportingTabledata([]);

    formDataId
      ? getAuditlog(formDataId)
          .then((response) => setAuditlogTabledata(response.data))
          .catch((response) => console.log(response))
      : setAuditlogTabledata([]);
  }, [formDataId, renderTable, screen]);

  useEffect(() => {
    getFormSeed().then((res) => setFormSeed(res.data));
    setFormDataId(null);
    resetForm();
  }, [resetForm, screen]);

  useEffect(() => {
    getAllParamter().then((res) => {
      let filterInput = res.data.filter((item) => item.customerId === custId);
      let inputValues = filterInput.map((item) => ({
        id: item.id,
        accessor: "id",
        // name: `${moment(item.year.split("-")[0]).format("ll")} - ${moment(
        //   item.year.split("-")[1]
        // ).format("ll")}`,
        name: item.year,
      }));
      setFinancialYearInput(inputValues);
    });
  }, [custId]);

  useEffect(() => {
    getForm &&
      values.financialYear &&
      getFormById(getForm?.id, values.financialYear)
        .then((res) => {
          setFormDataId(res.data.id);
          values.informationText = res.data.informationText;
        })
        .catch((res) => console.log(res));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.financialYear]);

  return (
    <div className={root}>
      <div className={accordionContainer}>
        <CustomToggleButton />
      </div>
      <div className={formContainer}>
        <div className={titleContainer}>
          <Typography variant="h6" className={title}>
            {props.header}
          </Typography>
        </div>
        <Box className={formBox}>
          {props.subHeader && (
            <Typography className={formTitle}>{props.subHeader}</Typography>
          )}
          <form onSubmit={formik.handleSubmit}>
            <Grid container rowSpacing={3} columnSpacing={3} p={4}>
              <Grid item xs={5}>
                <DropdownField
                  label={entryFormDataFields.financialYear.label}
                  name={entryFormDataFields.financialYear.name}
                  value={values.financialYear}
                  onChange={handleChange}
                  error={touched.financialYear && errors.financialYear}
                  onBlur={handleBlur}
                  inputValues={financialYearInput}
                />
              </Grid>
              <Grid item xs={7}>
                <CustomTextField
                  name={entryFormDataFields.informationText.name}
                  label={entryFormDataFields.informationText.label}
                  type={entryFormDataFields.informationText.type}
                  onChange={handleChange}
                  value={values.informationText}
                  error={touched.informationText && errors.informationText}
                  onBlur={handleBlur}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={12} className={notePara}>
                Note : Above fields must be completed prior to Continuing
              </Grid>
              <Grid item xs={12} className={notePara}>
                {(sidebarIndex === 1 || sidebarIndex === 2) &&
                  "Note : Type text in Description or See Upload Information"}
                {sidebarIndex === 3 &&
                  "Note : Type text in Description or See Attachment"}
              </Grid>
            </Grid>
            <Divider className={divider} />

            <InformationDetails
              informationDataFields={entryFormInformationFields(sidebarIndex)}
              informationDataInitial={entryFormInformationInitial}
              informationDataValidation={informationDetailsValidation}
              tableColumn={entryFormInformationColumn(
                setInformationTableData,
                setRenderTable,
                setDeletedInformation,
                informationTabledata,
                financialYearInput,
                sidebarIndex
              )}
              tableRawdata={formDataId ? informationTabledata : []}
              setRenderTable={setRenderTable}
              formValues={values}
              formDataId={formDataId}
            />

            <SupportingData
              supportingDataFields={entryFormSupportingFields(sidebarIndex)}
              supportingDataInitial={entryFormSupportingInitial}
              supportingDataValidation={informationDetailsValidation}
              tableColumn={entryFormSupportingColumn(
                setSupportingTabledata,
                setRenderTable,
                setDeletedSupporting,
                supportingTabledata,
                sidebarIndex
              )}
              tableRawdata={formDataId ? supportingTabledata : []}
              setRenderTable={setRenderTable}
              formDataId={formDataId}
              supportingDataOptional={props?.supportingDataOptional}
            />

            <Grid container rowSpacing={3} columnSpacing={3} pl={4}>
              <Grid item xs={5}>
                <CustomRadioButton
                  label={entryFormDataFields.saveAs.label}
                  name={entryFormDataFields.saveAs.name}
                  value={values.saveAs}
                  onChange={handleChange}
                  error={touched.saveAs && errors.saveAs}
                  onBlur={handleBlur}
                  inputValues={entryFormDataOptions}
                  helpertext={touched.saveAs && errors.saveAs}
                />
              </Grid>
            </Grid>

            <Box className={bottomBtnContainer}>
              <Button
                variant="outlined"
                className={cancelBtn}
                onClick={() => navigate(ROUTE_PATHS.HOMEPAGE)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="success"
                type="submit"
                onClick={() => {
                  formik.handleSubmit();
                  errorModalOpen();
                }}
              >
                Submit
              </Button>
            </Box>
          </form>
          <Divider className={divider} />

          <Typography
            className={subTitle}
            onClick={() => setShowAuditLog(!showAuditLog)}
          >
            {showAuditLog ? "Hide Audit Logs" : "Show Audit Logs"}
          </Typography>
          {showAuditLog && (
            <div className={tableContainer}>
              <CustomReactTable
                columnData={entryFormAuditlogColumn}
                rawData={formDataId ? auditlogTableData : []}
                disablePagination
                disableColumnHiding={true}
                disableRowSelection={true}
                disableSort={true}
                style={{
                  th: {
                    color: "#0000008A",
                    font: "normal normal bold 14px/19px Roboto",
                    display: "flex !important",
                    alignItems: "center",
                    height: "64px !important",
                  },
                  body: {
                    position: "relative",
                    zIndex: "0",
                    backgroundColor: "white",
                    color: "#40403A",
                    font: "normal normal bold 14px/19px Roboto",
                  },
                }}
              />
            </div>
          )}
        </Box>
      </div>
      {/* <DialogBox
        open={open}
        handleClose={handleClose}
        workData={workData}
        formDataId={formDataId}
        deletedInformation={deletedInformation}
        deletedSupporting={deletedSupporting}
        formId={getForm && getForm.id}
      /> */}
      <ErrorModalBox setErrorModal={setErrorModal} errorModal={errorModal} />
    </div>
  );
};

export default DATAForm;
