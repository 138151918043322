import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import {
  deleteOtherInformationDetails,
  deleteSupportingData,
  downloadFileByLink,
} from "../../api/api";
import moment from "moment";
import { saveAs } from "file-saver";
import { documentTypesDropdown } from "./entryFormConstant";
import { addressingPriorityOtherInformationValidation } from "../validation";

export const addressingPriorityDataFields = {
  financialYear: {
    name: "financialYear",
    label: "Select Year",
  },
  informationText: {
    name: "informationText",
    label: "Description",
  },
  saveAs: {
    name: "saveAs",
    label: "Save As",
  },
};

export const addressingPriorityDataInitial = {
  financialYear: "",
  informationText: "",
  saveAs: "af6f2eb0-2147-47e0-9da3-5128cd5e4b12",
  data: {
    informationDetails: [],
    supportingData: [],
  },
};

export const addressingPriorityDataOptions = [
  {
    id: "af6f2eb0-2147-47e0-9da3-5128cd5e4b12",
    name: "Draft",
    value: "DRAFT",
    accessor: "id",
  },
  {
    id: "2b328687-4562-47ad-be73-40ebfd557ea1",
    name: "Final",
    value: "FINAL",
    accessor: "id",
  },
];

export const addressingPriorityOtherInformationFields = [
  {
    id: 1,
    name: "uploadInformation",
    label: "Text or See uploaded information",
    type: "text",
    xs: 12,
  },
  // {
  //   id: 2,
  //   name: "planDescription",
  //   label: "Plan Description",
  //   type: "text",
  // },
  // {
  //   id: 3,
  //   name: "planImplementation",
  //   label: "Plan Implementation",
  //   type: "text",
  // },
  // {
  //   id: 4,
  //   name: "planResults",
  //   label: "Plan Results",
  //   type: "text",
  // },
  {
    id: 3,
    name: "fileType",
    label: "Document Type",
    type: "select",
    inputValues: documentTypesDropdown,
  },
  {
    id: 2,
    name: "fileContent",
    label: `${"File Name"}`,
    type: "text",
  },

  {
    id: 4,
    name: "uploadFile",
    label: "Upload Document",
    type: "file",
  },
];

export const addressingPriorityOtherInformationInitial = {
  uploadInformation: "",
  // planDescription: "",
  // planImplementation: "",
  // planResults: "",
  fileType: "",
  fileContent: "",
  uploadFile: "",
};

export const addressingPrioritySupportingFields = {
  fileType: {
    name: "fileType",
    label: "Document Type",
    inputValues: documentTypesDropdown,
  },
  fileContent: {
    name: "fileContent",
    label: "File Name",
  },
  uploadFile: {
    name: "uploadFile",
    label: "Upload Document",
  },
};

export const addressingPrioritySupportingInitial = {
  fileType: "",
  fileContent: "",
  uploadFile: "",
};

export const addressingPriorityOtherInformationColumn = (
  setInformationTableData,
  setRenderTable,
  setDeletedInformation,
  informationTabledata,
  financialYearInput
) => [
  {
    Header: " Year",
    accessor: "data.financialYear",
    sticky: "left",
    id: 1,
    width: 250,
    Cell: (props) => {
      return financialYearInput.map(
        (item) => item.id === props.value && item.name
      );
    },
  },
  {
    Header: "DATE",
    accessor: "createdAt",
    id: 2,
    width: 140,
    Cell: (props) => {
      return moment(props.value).format("L");
    },
  },
  {
    Header: "Doc Type",
    accessor: "fileType",
    id: 3,
    width: 140,
  },

  {
    Header: "Document Type",
    accessor: "fileType",
    id: 8,
    width: 160,

    Cell: (props) => {
      return props.value.split("/")[1].toUpperCase();
    },
  },

  {
    Header: " Document ",
    accessor: "fileName",
    id: 9,
    width: 250,

    Cell: (props) => (
      <span style={{ textDecoration: "underline", color: "#2A5BA8" }}>
        {props.value}
      </span>
    ),
  },
  {
    Header: "Update By",
    accessor: "user.name",
    id: 10,
    width: 160,
    Cell: (props) => {
      return (
        <div
          style={{
            display: "flex ",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span> {props.value} </span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "60px",
              color: "#2A5BA8",
            }}
          >
            <IconButton
              onClick={() => {
                let rowId = props.row.original.id;
                downloadFileByLink("otherInformationDetails", rowId).then(
                  (res) => saveAs(res.data.url)
                );
              }}
            >
              <DownloadIcon color="primary" />
            </IconButton>
            <IconButton
              onClick={() => {
                let copyValue = { ...informationTabledata };
                delete copyValue?.[
                  props.row.original?.adverseImpactSection?.name
                ].splice(props.row.index, 1);

                setInformationTableData(copyValue);

                deleteOtherInformationDetails(props.row.original.id)
                  .then((res) => res)
                  .catch((res) => res);
              }}
            >
              <DeleteIcon color="primary" />
            </IconButton>
          </div>
        </div>
      );
    },
  },
];

export const addressingPriorityUploadInformation = [
  {
    id: 1,
    fields: addressingPriorityOtherInformationFields,
    initialValue: addressingPriorityOtherInformationInitial,
    subHeader: "Impact Potential / Actual",
    tableColumn: addressingPriorityOtherInformationColumn,
    validation: addressingPriorityOtherInformationValidation,
  },
  {
    id: 2,
    fields: addressingPriorityOtherInformationFields,
    initialValue: addressingPriorityOtherInformationInitial,
    subHeader: "Plan Description",
    tableColumn: addressingPriorityOtherInformationColumn,
    validation: addressingPriorityOtherInformationValidation,
  },
  {
    id: 3,
    fields: addressingPriorityOtherInformationFields,
    initialValue: addressingPriorityOtherInformationInitial,
    subHeader: "Plan Implementation",
    tableColumn: addressingPriorityOtherInformationColumn,
    validation: addressingPriorityOtherInformationValidation,
  },
  {
    id: 4,
    fields: addressingPriorityOtherInformationFields,
    initialValue: addressingPriorityOtherInformationInitial,
    subHeader: "Plan Results",
    tableColumn: addressingPriorityOtherInformationColumn,
    validation: addressingPriorityOtherInformationValidation,
  },
];

export const addressingPriorityInformationRawdata = [
  {
    id: 1,
    financialYear: "May 22 - Apr 23",
    date: "11/2/2022",
    uploadType: "Information",
    impactPotential: "Lorem",
    planDescription: "text",
    planImplementation: "test",
    planResults: "Pass",
    fileType: "PDF",
    file: "Filename.pdf",
    updateBy: "user",
  },
  {
    id: 2,
    financialYear: "May 22 - Apr 23",
    date: "11/2/2022",
    uploadType: "Information",
    impactPotential: "Lorem",
    planDescription: "text",
    planImplementation: "test",
    planResults: "Pass",
    fileType: "JPG",
    file: "Filename.JPG",
    updateBy: "userOne",
  },
];

export const addressingPrioritySupportingColumn = (
  setSupportingTabledata,
  setRenderTable,
  setDeletedSupporting,
  supportingTabledata
) => [
  {
    Header: "DATE",
    accessor: "createdAt",
    sticky: "left",
    id: 1,
    Cell: (props) => {
      return moment(props.value).format("L");
    },
  },
  {
    Header: "Document Type",
    accessor: "fileType",
    id: 2,
    Cell: (props) => {
      return props.value.split("/")[1].toUpperCase();
    },
  },
  {
    Header: "File Name",
    accessor: "data.fileContent",
    id: 3,
  },
  {
    Header: " File ",
    accessor: "fileName",
    id: 4,
    Cell: (props) => (
      <span style={{ textDecoration: "underline", color: "#2A5BA8" }}>
        {props.value}
      </span>
    ),
  },
  {
    Header: "Update By",
    accessor: "user.name",
    id: 5,
    Cell: (props) => {
      return (
        <div
          style={{
            display: "flex ",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span> {props.value} </span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "60px",
              color: "#2A5BA8",
            }}
          >
            <IconButton
              onClick={() => {
                let rowId = props.row.original.id;
                downloadFileByLink("informationDetails", rowId).then((res) =>
                  saveAs(res.data.url)
                );
              }}
            >
              <DownloadIcon color="primary" />
            </IconButton>
            <IconButton
              onClick={() => {
                supportingTabledata.splice(props.row.index, 1);

                setSupportingTabledata(supportingTabledata);

                deleteSupportingData(props.row.original.id)
                  .then((res) => res)
                  .catch((res) => res);
              }}
            >
              <DeleteIcon color="primary" />
            </IconButton>
          </div>
        </div>
      );
    },
  },
];

export const addressingPrioritySupportingRawdata = [
  {
    id: 1,
    date: "11/2/2022",
    fileType: "PDF",
    contentFileText: "Lorem.",
    file: "Filename.pdf",
    updateBy: "user",
  },
  {
    id: 2,
    date: "11/2/2022",
    fileType: "JPG",
    contentFileText: "Lorem.",
    file: "Filename.JPG",
    updateBy: "userOne",
  },
];

export const addressingPriorityAuditlogColumn = [
  {
    Header: "Name",
    accessor: "user.name",
    sticky: "left",
    id: 1,
  },
  {
    Header: "Email ID",
    accessor: "user.emailId",
    id: 2,
  },
  {
    Header: "Date Modified",
    accessor: "updatedAt",
    id: 3,
    Cell: (props) => {
      return moment(props.value).format("L");
    },
  },
  {
    Header: " Saved as ",
    accessor: "formStatusId",
    id: 4,
    Cell: (props) => {
      return addressingPriorityDataOptions.map(
        (item) => item.id === props.value && item.name
      );
    },
  },
];

export const addressingPriorityAuditLogRawdata = [
  {
    id: 1,
    name: "example",
    emailId: "ex@gmail.cc",
    dateModified: "11/2/2022",
    savedAs: "Draft",
  },
  {
    id: 2,
    name: "example",
    emailId: "ex@gmail.cc",
    dateModified: "11/2/2022",
    savedAs: "Draft",
  },
];
