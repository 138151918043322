const labels = {
  name: "User Name*",
  emailId: "User Email*",
  mobileNo: "User Mobile number*",
  profile: "Profile Image",
  password: "Password *",
  confirmPassword: "Confirm Password *",
};

export const formGroup = {
  name: {
    label: labels.name,
    variant: "outlined",
    type: "text",
    name: "name",
  },
  emailId: {
    label: labels.emailId,
    type: "text",
    required: true,
    name: "emailId",
    variant: "outlined",
  },
  mobileNo: {
    label: labels.mobileNo,
    required: true,
    type: "text",
    name: "mobileNo",
    variant: "outlined",
  },
  profile: {
    label: labels.profile,
    required: true,
    name: "profile",
  },
  password: {
    name: "password",
    label: labels.password,
    type: "password",
    variant: "outlined",
  },
  confirmPassword: {
    label: labels.confirmPassword,
    name: "confirmPassword",
    type: "password",
    variant: "outlined",
  },
};

export const myUserInitial = {
  name: "",
  emailId: "",
  mobileNo: "",
  profile: "",
  password: "",
  confirmPassword: "",
};

export const myUserUpdateInitial = {
  name: "",
  emailId: "",
  mobileNo: "",
  profile: "",
};
