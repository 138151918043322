import { makeStyles } from "@mui/styles";
import { ThemeProvider } from "@mui/material/styles";
import { Suspense, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  NavBar,
  SuperAdminNavBar,
  ToastMessage,
  UserNavbar,
} from "./components/reusable";
import { loginRoutes, routes } from "./routes/routes";
import CircularProgress from "@mui/material/CircularProgress";
import { theme } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import DDRAnalysis from "./components/user/dd-requirement-analysis";
import EmailList from "./components/user/EmailInformation/EmailList";
import { EmailForm } from "./components/user/EmailInformation/EmailForm";
import CompanyUserForm from "./components/pages/companyUsersAdmin/companyUsers/CompanyUserForm";
import UserForm from "./components/pages/companyUsersAdmin/companyUsers/UserForm";
import Cookies from "universal-cookie";
import FormAccess from "./components/pages/companyUsersAdmin/companyUsers/FormAccess";
import { ROUTE_PATHS } from "./routes/routePath";
import { COMPANY_ADMIN, COMPANY_USER, SUPER_ADMIN } from "./constant";
import ViewForm from "./components/user/EmailInformation/ViewForm";
import { getAllStatus, getPaymentStatus } from "./api/api";
import { allSeeds } from "./redux/action";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 62,
  },
  loaderContainer: {
    // backgroundColor: "white",
    // height: "100vh",
    // width: "100%",
    // position: "relative",
  },
  loaderCircle: {
    position: "absolute !important",
    top: "50vh",
    left: "50%",
    zIndex: 100,
  },
}));

function Root() {
  const { root, loaderCircle, loaderContainer } = useStyles();
  const globalState = useSelector((state) => state);
  const { toastMessageState, isLoading } =
    globalState?.toastMessageStateReducer;

  const location = useLocation();
  const dispatch = useDispatch();
  const pathName = location.pathname;
  const cookies = new Cookies();
  const userTypeName = cookies.get("userTypeName");

  // console.log(isLoading);

  useEffect(() => {
    getAllStatus().then((res) => {
      dispatch(allSeeds("customerStatus", res.data));
    });
    getPaymentStatus().then((res) => {
      dispatch(allSeeds("paymentStatus", res.data));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={<CircularProgress className={loaderCircle} />}>
      {isLoading && (
        <div className={loaderContainer}>
          <CircularProgress className={loaderCircle} />
        </div>
      )}

      <ThemeProvider theme={theme}>
        {pathName === ROUTE_PATHS.LOGIN ||
        pathName === ROUTE_PATHS.FORGETPASSWORD ||
        pathName === ROUTE_PATHS.UPDATEPASSWORD ||
        pathName === ROUTE_PATHS.EMAIL_LINK_FORM ||
        pathName === ROUTE_PATHS.RESETPASSWORD ? (
          <UserNavbar disableProfile />
        ) : (
          (userTypeName === SUPER_ADMIN && <SuperAdminNavBar />) ||
          (userTypeName === COMPANY_ADMIN && <NavBar />) ||
          (userTypeName === COMPANY_USER && <UserNavbar />)
        )}
        <div className={root}>
          <ToastMessage toastMessageState={toastMessageState} />

          <Routes>
            {loginRoutes.map(({ component, path, exact }) => {
              const Component = component;
              return (
                <Route
                  key={path}
                  exact={exact}
                  path={path}
                  element={<Component />}
                />
              );
            })}
            {routes.map(({ component, path, exact }) => {
              const Component = component;
              return (
                <Route
                  key={path}
                  exact={exact}
                  path={path}
                  element={<Component />}
                />
              );
            })}

            <Route
              exact={true}
              path={ROUTE_PATHS.COMPANYUSERSFORM}
              element={<CompanyUserForm />}
            >
              <Route path={ROUTE_PATHS.USER_FORM} element={<UserForm />} />
              <Route
                path={ROUTE_PATHS.COMPANY_USER_ACCESS}
                element={<FormAccess />}
              />
            </Route>

            <Route
              path={`${ROUTE_PATHS.DD_REQUIREMENTS_ANALYSIS}/:screen`}
              element={<DDRAnalysis />}
            />
            <Route
              path={`${ROUTE_PATHS.DUE_DILLIGENCE_DESCRIPTION}/:screen`}
              element={<DDRAnalysis />}
            />

            <Route
              path={`${ROUTE_PATHS.TRANSPARENCY_ACT_REPORT}/:screen`}
              element={<DDRAnalysis />}
            />
            <Route
              path={`${ROUTE_PATHS.EMAIL_CREATION_REQUEST_CREATOR}/:screen`}
            >
              <Route path="list" element={<EmailList />} />
              <Route path="form" element={<EmailForm />} />
              <Route path="viewForm" element={<ViewForm />} />
            </Route>
          </Routes>
        </div>
      </ThemeProvider>
    </Suspense>
  );
}

export default Root;
