const labels = {
  value: "Value",
  typeLabel: "Query",
};

export const emailCrationFilter = {
  date: {
    label: labels.value,
    type: "text",
    name: "date",
  },
  senderName: {
    label: labels.value,
    type: "text",
    name: "senderName",
  },
  senderEmail: {
    label: labels.value,
    type: "number",
    name: "senderEmail",
  },
  status: {
    label: labels.value,
    type: "text",
    name: "status",
  },
};

export const emailCrationFilterType = {
  dateType: {
    label: labels.typeLabel,
    type: "text",
    name: "dateType",
  },
  senderNameType: {
    label: labels.typeLabel,
    type: "text",
    name: "senderNameType",
  },
  senderEmailType: {
    label: labels.typeLabel,
    type: "text",
    name: "senderEmailType",
  },
  statusType: {
    label: labels.typeLabel,
    type: "text",
    name: "statusType",
  },
};

export const filterTypeSeeds = [
  { id: "eq", name: "equal" },
  { id: "ne", name: "non-equal" },
  { id: "in", name: "in" },
  { id: "notIn", name: "not-in" },
];

export const emailCrationFilterInitial = {
  date: "",
  senderName: "",
  senderEmail: "",
  status: "",
  dateType: "",
  senderNameType: "",
  senderEmailType: "",
  statusType: "",
};

///  email master
export const emailMasterFilter = {
  organisationname: {
    label: labels.value,
    type: "text",
    name: "organisationname",
  },
  name: {
    label: labels.value,
    type: "text",
    name: "name",
  },
  email: {
    label: labels.value,
    type: "number",
    name: "email",
  },
};

export const emailMasterFilterType = {
  organisationnameType: {
    label: labels.typeLabel,
    type: "text",
    name: "organisationnameType",
  },
  nameType: {
    label: labels.typeLabel,
    type: "text",
    name: "nameType",
  },

  emailType: {
    label: labels.typeLabel,
    type: "text",
    name: "emailType",
  },
};

export const emailMasterFilterInitial = {
  organisationname: "",
  name: "",
  email: "",
  organisationnameType: "",
  nameType: "",
  emailType: "",
};

// datacollection
export const dataCollectionFilter = {
  topicName: {
    label: labels.value,
    type: "text",
    name: "topicName",
  },
  heading: {
    label: labels.value,
    type: "text",
    name: "heading",
  },
  status: {
    label: labels.value,
    type: "number",
    name: "status",
  },
};

export const dataCollectionFilterType = {
  topicNameType: {
    label: labels.typeLabel,
    type: "text",
    name: "topicNameType",
  },
  headingType: {
    label: labels.typeLabel,
    type: "text",
    name: "headingType",
  },

  statusType: {
    label: labels.typeLabel,
    type: "text",
    name: "statusType",
  },
};

export const dataCollectionFilterInitial = {
  topicName: "",
  heading: "",
  status: "",
  headingType: "",
  topicNameType: "",
  statusType: "",
};

// email responses
export const emailResponsesFilter = {
  name: {
    label: labels.value,
    type: "text",
    name: "name",
  },
  senderName: {
    label: labels.value,
    type: "number",
    name: "senderName",
  },
  senderEmail: {
    label: labels.value,
    type: "text",
    name: "senderEmail",
  },
};

export const emailResponsesFilterType = {
  nameType: {
    label: labels.typeLabel,
    type: "text",
    name: "nameType",
  },

  senderNameType: {
    label: labels.typeLabel,
    type: "text",
    name: "senderNameType",
  },
  senderEmailType: {
    label: labels.typeLabel,
    type: "text",
    name: "senderEmailType",
  },
};

export const emailResponsesFilterInitial = {
  name: "",
  senderName: "",
  nameType: "",
  senderNameType: "",
  senderEmail: "",
  senderEmailType: "",
};
