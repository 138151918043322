import moment from "moment";
import { EmailPopover } from "../../components/reusable";
import { ROUTE_PATHS } from "../../routes/routePath";

export const emailCreationListColumn = () => [
  {
    Header: "Email Name",
    accessor: "name",
    sticky: "left",
    id: 1,
    width: 300,
    Cell: (props) => (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {props.value}
        <EmailPopover
          values={props}
          toShow={{
            edit: props.row.original.status !== "completed" && true,
            cancelEmail: props.row.original.status === "open" && true,
          }}
          paths={{
            edit: ROUTE_PATHS.EMAILCREATION_FORM,
          }}
        />
      </div>
    ),
  },
  {
    Header: "Date",
    accessor: "emailDate",
    id: 2,

    Cell: (props) => moment(props.value).format("L"),
  },
  {
    Header: "Sender Name",
    accessor: "senderName",
    id: 3,
  },
  {
    Header: "Sender email",
    accessor: "senderEmail",
    id: 4,
  },
  {
    Header: "Status",
    accessor: "status",
    id: 5,
  },
];

export const emailCreationListRawdata = [
  {
    id: 1,
    emailName: "example",
    date: "01/09/2022",
    senderName: "Draft",
    senderEmail: "ex@gmail.cc",
    status: "Draft",
    createdBy: "Draft",
  },
  {
    id: 2,
    emailName: "example",
    date: "01/09/2022",
    senderName: "Draft",
    senderEmail: "ex@gmail.cc",
    status: "Draft",
    createdBy: "Draft",
  },
];
