import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  entryFormDataOptions,
  modalFields,
  modalFieldsOptions,
  modalFieldsTitle,
  modalInitialValues,
  saveModalValidation,
} from "../../constant";
import { useFormik } from "formik";
import { Box, Grid } from "@mui/material";
import { CustomRadioButton, CustomTextField } from ".";
import { makeStyles } from "@mui/styles";
import {
  createAuditlog,
  // deleteAdverseImpact,
  // deleteInformationDetails,
  // deleteSupportingData,
  updateFormById,
} from "../../api/api";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../routes/routePath";
import { useDispatch } from "react-redux";
import { toastMessageAction } from "../../redux/action";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2, backgroundColor: "#2A5BA8", color: "white" }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: "10px !important",
    fontWeight: "bold !important",
  },
  bottomBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "89%",
    margin: "auto",
  },
  sendBtn: {
    backgroundColor: "#2A5BA8 !important",
  },
  cancelBtn: {
    backgroundColor: "#00000061 !important",
    color: "white !important",
    border: "none !important",
  },
}));

export function DialogBox({
  open,
  handleClose,
  workData,
  formDataId,
  formId,
  deletedInformation,
  deletedSupporting,
  deletedAdverse,
}) {
  const { title, bottomBtnContainer, cancelBtn, sendBtn } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    let formValues = {
      ...values,
      sendEmail: Boolean(values.send),
      formStatusId: workData.saveAs,
    };

    let updateFormValue = {
      informationText: workData?.informationText,
      hasAdverseImpact: false,
    };

    formDataId &&
      createAuditlog(formDataId, formValues)
        .then((res) => console.log(res))
        .catch((res) => console.log(res));

    formDataId &&
      formId &&
      updateFormById(formId, workData.financialYear, updateFormValue)
        .then((res) => console.log(res))
        .catch((res) => console.log(res));

    // deletedInformation?.forEach((item) => {
    //   deleteInformationDetails(item.id)
    //     .then((res) => res)
    //     .catch((res) => res);
    // });

    // deletedSupporting?.forEach((item) => {
    //   deleteSupportingData(item.id)
    //     .then((res) => res)
    //     .catch((res) => res);
    // });

    // deletedAdverse?.forEach((item) => {
    //   deleteAdverseImpact(item.id)
    //     .then((res) => res)
    //     .catch((res) => res);
    // });

    navigate(ROUTE_PATHS.HOMEPAGE);
    dispatch(
      toastMessageAction({
        severity: "success",
        message: "Saved Successfully !",
        messageStatus: true,
      })
    );
  };

  const formik = useFormik({
    initialValues: modalInitialValues,
    validationSchema: saveModalValidation,
    onSubmit,
  });

  const { handleChange, handleBlur, errors, touched, values } = formik;
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {entryFormDataOptions.map(
            (item) =>
              item.id === workData?.saveAs && modalFieldsTitle[item.value]
          )}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container rowSpacing={3} columnSpacing={3} pl={4}>
            <Grid item xs={5}>
              <CustomRadioButton
                label={entryFormDataOptions.map(
                  (item) =>
                    item.id === workData?.saveAs &&
                    modalFields.sendEmail[item.value]
                )}
                name={modalFields.sendEmail.name}
                value={values.sendEmail}
                onChange={handleChange}
                error={touched.sendEmail && errors.sendEmail}
                onBlur={handleBlur}
                inputValues={modalFieldsOptions}
                helpertext={touched.sendEmail && errors.sendEmail}
                vertical={true}
              />
            </Grid>
          </Grid>

          <form>
            <Grid container rowSpacing={3} columnSpacing={3} p={4}>
              <Grid item xs={12}>
                <Typography className={title}>If Yes</Typography>
                <CustomTextField
                  label={modalFields.emailAddresses.label}
                  name={modalFields.emailAddresses.name}
                  type={modalFields.emailAddresses.type}
                  value={values.emailAddresses}
                  onChange={handleChange}
                  error={touched.emailAddresses && errors.emailAddresses}
                  onBlur={handleBlur}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={title}>Subject</Typography>

                <CustomTextField
                  name={modalFields.subject.name}
                  label={modalFields.subject.label}
                  type={modalFields.subject.type}
                  onChange={handleChange}
                  value={values.subject}
                  error={touched.subject && errors.subject}
                  onBlur={handleBlur}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={title}>Message</Typography>

                <CustomTextField
                  name={modalFields.message.name}
                  label={modalFields.message.label}
                  type={modalFields.message.type}
                  onChange={handleChange}
                  value={values.message}
                  error={touched.message && errors.message}
                  onBlur={handleBlur}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Box className={bottomBtnContainer}>
            <Button
              variant="outlined"
              className={cancelBtn}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              type="submit"
              className={sendBtn}
              onClick={formik.handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
