const labels = {
  statusId: "Company Status",
  effectiveDate: "Effective Date",
  reason: "Reason",
};

export const formGroup = {
  statusId: {
    label: labels.statusId,
    variant: "outlined",
    type: "text",
    name: "statusId",
  },
  effectiveDate: {
    label: labels.dateValue,
    type: "text",
    name: "effectiveDate",
  },
  reason: {
    label: labels.reason,
    type: "text",
    name: "reason",
    variant: "outlined",
  },
};

export const updateStatusInitial = {
  statusId: "",
  effectiveDate: new Date(),
  reason: "",
};
