const labels = {
  paymentStatusId: "Payment Status",
  pymtReferenceNo: "Reference No.",
};

export const formGroup = {
  paymentStatusId: {
    label: labels.paymentStatusId,
    variant: "outlined",
    type: "text",
    name: "paymentStatusId",
  },

  pymtReferenceNo: {
    label: labels.pymtReferenceNo,
    type: "text",
    name: "pymtReferenceNo",
    variant: "outlined",
  },
};

export const updatePaymentStatusContentInitial = {
  paymentStatusId: "c2e48603-8c4f-4055-b373-3d21fa3beafb",
  pymtReferenceNo: "",
};
