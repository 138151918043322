import { Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  CustomAutoComplete,
  CustomDateTimePicker,
  CustomRadioButton,
  CustomTextarea,
  CustomTextField,
  CustomToggleButton,
  DropdownField,
  EmailUploadModal,
} from "../../reusable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DatePickerField from "../../reusable/DatePickerField";
import {
  emailCreationFields,
  emailCreationFollow,
  // emailCreationFormInitial,
  emailCreationFormValidation,
  emailCreationParameters,
  emailCreationSubFields,
  emailCreationUploadFields,
  emailUserDataFields,
} from "../../../constant";
import { useFormik } from "formik";
import { ROUTE_PATHS } from "../../../routes/routePath";
import { useLocation, useNavigate } from "react-router-dom";
import { emailCreationFormStyle } from "../../../styles";
import ReviewDraft from "./EmailView";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  createEmailCreation,
  getAllDataForms,
  getAllParamter,
  getDataformById,
  // getEmailCreation,
  getEmailCreationById,
  getEmailMaster,
  updateEmailCreation,
} from "../../../api/api";
import ErrorModalBox from "../../reusable/ErrorModalBox";
import moment from "moment";
import { emailViewData, viewFormData } from "../../../redux/action";
import { parseQueryParams } from "../../../utils/queryParams";

export const EmailCreationForm = () => {
  const {
    root,
    accordionContainer,
    formContainer,
    formBox,
    titleContainer,
    arrowBtn,
    divider,
    uploadTitle,
    subTitle,
    saveBtnContainer,
    btn,
    emailBtn,
    formBtn,
  } = emailCreationFormStyle();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const cookies = new Cookies();
  const customerId = cookies.get("custId");
  const search = useLocation().search;
  const searchParam = new URLSearchParams(search);
  const pageParams = parseQueryParams(location?.search);
  const pageSize = parseInt(pageParams?.pageSize) || 100;
  const editId = searchParam?.get("editId");
  const [openEmail, setOpenEmail] = React.useState(false);
  const [errorModal, setErrorModal] = React.useState(false);
  const [sendValue, setSendValue] = React.useState(false);
  const [financialYearInput, setFinancialYearInput] = React.useState([]);
  const [topicNameValues, setTopicNameValues] = React.useState([]);

  const globalState = useSelector((state) => state);
  const emailViewValues = globalState?.toastMessageStateReducer.emailViewData;

  //modal
  const openModal = (btnValue) => {
    setOpenEmail(true);
  };
  const closeModal = () => {
    setOpenEmail(false);
  };

  //formik
  const onSubmit = (values, { resetForm }) => {
    let workData = {
      ...values,
      emails:
        values.emails &&
        `${values?.emails
          ?.map((item) => (item?.email ? item.email : item))
          .join(",")}`,
      scheduleDate: moment(values.scheduleDate).unix(),
      status: sendValue ? "open" : "savedraft",
    };

    if (editId) {
      updateEmailCreation(editId, workData)
        .then((res) => navigate(ROUTE_PATHS.EMAILCREATION_LIST))
        .catch((res) => console.log(res));
    } else {
      createEmailCreation(workData)
        .then((res) => navigate(ROUTE_PATHS.EMAILCREATION_LIST))
        .catch((res) => console.log(res));
    }

    // resetForm();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: emailViewValues,
    validationSchema: emailCreationFormValidation,
    onSubmit,
  });

  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
    setFieldValue,
    setValues,
  } = formik;

  // console.log(values);

  // error modal
  const errorModalOpen = () => {
    Object.keys(errors).length > 0 && setErrorModal(true);
  };

  const viewFormValues = () => {
    if (values.dataCollectionFormId) {
      getDataformById(values.dataCollectionFormId).then((res) => {
        dispatch(
          viewFormData({
            ...res.data,
            financialYearInput,
          })
        );
        dispatch(
          emailViewData({
            ...values,
          })
        );
        navigate(ROUTE_PATHS.VIEW_FORM);
      });
    }
  };

  const reviewDraft = () => {
    dispatch(
      emailViewData({
        ...values,
      })
    );

    navigate(ROUTE_PATHS.EMAIL_VIEW);
  };

  useEffect(() => {
    getAllParamter().then((res) => {
      let filterInput = res.data.filter(
        (item) => item.customerId === customerId
      );
      let inputValues = filterInput.map((item) => ({
        id: item.id,
        accessor: "id",
        // name: `${moment(item.year.split("-")[0]).format("ll")} - ${moment(
        //   item.year.split("-")[1]
        // ).format("ll")}`,
        name: item.year,
      }));
      setFinancialYearInput(inputValues);
    });
  }, [customerId]);

  useEffect(() => {
    if (values.financialYearId) {
      getAllDataForms({}, pageSize, 0)
        .then((res) => {
          let filterData = res.data.rows?.filter(
            (item) => item.financialYearId === values.financialYearId
          );
          setTopicNameValues(
            filterData.map((item) => ({ ...item, name: item.topicName }))
          );
        })
        .catch((res) => console.log(res));
    }
  }, [values.financialYearId, pageSize]);

  const fetchEditData = async () => {
    let getallEmails = await getEmailMaster({}, pageSize, 0);
    if (editId) {
      getEmailCreationById(editId)
        .then((res) => {
          let filterEmails = res.data.emails
            ? res.data.emails
                .split(",")
                .map((email) =>
                  getallEmails.data.rows.find((item) => email === item?.email)
                    ? getallEmails.data.rows.find(
                        (item) => email === item?.email
                      )
                    : email
                )
            : [];

          setValues({
            ...res.data,
            emails: filterEmails,
            scheduleDate: moment.unix(res.data.scheduleDate).format(),
          });
        })
        .catch((res) => console.log(res));
    }
  };

  useEffect(() => {
    fetchEditData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, setValues]);

  return (
    <div className={root}>
      <div className={accordionContainer}>
        <CustomToggleButton />
      </div>
      <div className={formContainer}>
        <div className={titleContainer}>
          <IconButton
            className={arrowBtn}
            onClick={() => navigate(ROUTE_PATHS.EMAILCREATION_LIST)}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">New Email </Typography>
        </div>
        <div className={formBox}>
          <Grid container rowSpacing={3} columnSpacing={3} p={4}>
            {emailCreationFields.map((item, index) =>
              item.type === "date" ? (
                <Grid item xs={item.xs} key={index}>
                  <DatePickerField
                    name={item.name}
                    label={item.label}
                    type={item.type}
                    value={values[item.name]}
                    fullWidth={true}
                    minDate={new Date()}
                    onChange={(val) => {
                      setFieldValue(item.name, val);
                    }}
                    error={touched[item.name] && errors[item.name]}
                    helperText={touched[item.name] && errors[item.name]}
                  />
                </Grid>
              ) : (
                <Grid item xs={item.xs} key={index}>
                  <CustomTextField
                    name={item.name}
                    label={item.label}
                    type={item.type}
                    onChange={handleChange}
                    value={values[item.name]}
                    error={touched[item.name] && errors[item.name]}
                    onBlur={handleBlur}
                    fullWidth={true}
                  />
                </Grid>
              )
            )}
          </Grid>
          <Divider className={divider} />

          <Grid container rowSpacing={3} columnSpacing={3} p={4}>
            {emailCreationSubFields.map((item, index) => (
              <Grid item xs={item.xs} key={index}>
                <CustomAutoComplete
                  name={item.name}
                  label={item.label}
                  inputValues={[]}
                  setFieldValue={setFieldValue}
                  // onChange={handleChange}
                  value={values[item.name]}
                  error={touched[item.name] && errors[item.name]}
                  onBlur={handleBlur}
                  fullWidth={true}
                />
              </Grid>
            ))}
            <Grid item xs={12} pt={3}>
              <Typography className={uploadTitle} onClick={() => openModal()}>
                Upload From Master
              </Typography>
            </Grid>
          </Grid>

          <Divider className={divider} />

          <Grid container rowSpacing={3} columnSpacing={3} p={4}>
            {emailCreationUploadFields.map((item, index) =>
              item.type === "textarea" ? (
                <Grid item xs={12} key={index}>
                  <CustomTextarea
                    name={item.name}
                    label={item.label}
                    type={item.type}
                    onChange={handleChange}
                    value={values[item.name]}
                    error={touched[item.name] && errors[item.name]}
                    helpertext={touched[item.name] && errors[item.name]}
                    onBlur={handleBlur}
                    fullWidth={true}
                  />
                </Grid>
              ) : (
                <Grid item xs={item.xs} key={index}>
                  <CustomTextField
                    name={item.name}
                    label={item.label}
                    type={item.type}
                    onChange={handleChange}
                    value={values[item.name]}
                    error={touched[item.name] && errors[item.name]}
                    onBlur={handleBlur}
                    fullWidth={true}
                  />
                </Grid>
              )
            )}
          </Grid>
          <Divider className={divider} />

          <Grid container rowSpacing={3} columnSpacing={3} p={4}>
            {emailUserDataFields(financialYearInput, topicNameValues).map(
              (item, index) =>
                item.type === "radio" ? (
                  <Grid item xs={item.xs} key={index}>
                    <CustomRadioButton
                      label={item.label}
                      name={item.name}
                      value={values[item.name]}
                      onChange={handleChange}
                      error={touched[item.name] && errors[item.name]}
                      onBlur={handleBlur}
                      inputValues={item.inputValues}
                      helpertext={touched[item.name] && errors[item.name]}
                      vertical={true}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={item.xs} key={index}>
                    <DropdownField
                      label={item.label}
                      name={item.name}
                      value={values[item.name]}
                      onChange={handleChange}
                      error={touched[item.name] && errors[item.name]}
                      onBlur={handleBlur}
                      inputValues={item.inputValues}
                    />
                  </Grid>
                )
            )}
          </Grid>
          <Divider className={divider} />

          <Typography className={subTitle}>Email Sending Parameters</Typography>

          <Grid container rowSpacing={3} columnSpacing={3} p={4}>
            {emailCreationParameters.map((item, index) => (
              <Grid item xs={item.xs} key={index}>
                <CustomDateTimePicker
                  name={item.name}
                  label={item.label}
                  type={item.type}
                  value={values[item.name]}
                  fullWidth={true}
                  minDate={new Date()}
                  onChange={(val) => {
                    setFieldValue(item.name, val);
                  }}
                  error={touched[item.name] && errors[item.name]}
                  helperText={touched[item.name] && errors[item.name]}
                />
              </Grid>
            ))}
          </Grid>
          <Divider className={divider} />

          <Typography className={subTitle}>Automated Follow up</Typography>
          <Grid container rowSpacing={3} columnSpacing={3} p={4}>
            {emailCreationFollow.map((item, index) => (
              <Grid item xs={item.xs} key={index}>
                <CustomTextField
                  name={item.name}
                  label={item.label}
                  type={item.type}
                  onChange={handleChange}
                  value={values[item.name]}
                  error={touched[item.name] && errors[item.name]}
                  onBlur={handleBlur}
                  fullWidth={true}
                />
              </Grid>
            ))}
          </Grid>

          <div className={saveBtnContainer}>
            <Button
              variant="contained"
              className={formBtn}
              onClick={() => viewFormValues()}
            >
              FORM VIEW
            </Button>
            <Button
              variant="contained"
              className={emailBtn}
              onClick={() => reviewDraft()}
            >
              EMAIL VIEW
            </Button>
            <Button
              variant="contained"
              color="info"
              // className={btn}
              onClick={() => {
                setSendValue(false);
                formik.handleSubmit();
              }}
            >
              SAVE DRAFT
            </Button>
            <Button
              variant="contained"
              className={btn}
              onClick={() => {
                setSendValue(true);
                formik.handleSubmit();
                errorModalOpen();
              }}
            >
              SEND
            </Button>
          </div>
        </div>
        <div style={{ display: "none" }}>
          <ReviewDraft values={values} />
        </div>
      </div>
      <EmailUploadModal
        open={openEmail}
        handleClose={closeModal}
        setFieldValue={setFieldValue}
        value={values.emails}
      />
      <ErrorModalBox setErrorModal={setErrorModal} errorModal={errorModal} />
    </div>
  );
};
