import { Button, Grid, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { CustomTextField, CustomToggleButton } from "../../reusable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  emailMasterForm,
  emailMasterFormInitial,
  emailMasterFormValidation,
} from "../../../constant";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { ROUTE_PATHS } from "../../../routes/routePath";
import {
  createEmailMaster,
  getEmailMasterById,
  updateEmailMaster,
} from "../../../api/api";
import { toastMessageAction } from "../../../redux/action";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  accordionContainer: {
    width: "320px",
    height: "calc(100vh - 64px)",
    position: "fixed",
    overflowY: "scroll",
    backgroundColor: "white",
    borderRight: "2px solid #7394c6",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent !important",
      width: "0px !important",
    },
  },
  formContainer: {
    width: "calc(98vw - 330px)",
    position: "absolute",
    left: "330px",
    padding: "0 20px 20px 20px",
    boxSizing: "border-box",
  },
  formBox: {
    padding: "20px",
    border: "3px solid #2A5BA8",
    borderRadius: "4px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    margin: "20px 0",
  },
  title: {
    fontWeight: "bold !important",
  },
  arrowBtn: {
    color: "black !important",
    marginRight: "10px !important",
  },
  saveBtnContainer: {
    width: "95.5%",
    margin: "auto",
    display: "flex",
    justifyContent: "flex-end",
  },
  saveBtn: {
    padding: "6px 30px !important",
  },
  cancelBtn: {
    padding: "6px 30px !important",
    backgroundColor: "#C0C1CE !important",
  },
}));

export const EmailMasterForm = () => {
  const {
    root,
    accordionContainer,
    formContainer,
    formBox,
    titleContainer,
    title,
    arrowBtn,
    cancelBtn,
    saveBtn,
    saveBtnContainer,
  } = useStyles();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const customerId = cookies.get("custId");
  const userId = cookies.get("userId");
  const search = useLocation().search;
  const searchParam = new URLSearchParams(search);
  const editId = searchParam?.get("editId");
  // const pageSize = searchParam?.get("pageSize");
  // console.log(pageSize);

  //formik
  const onSubmit = (values, { resetForm }) => {
    let workData = {
      ...values,
      userId,
      customerId,
    };

    if (editId) {
      updateEmailMaster(editId, workData)
        .then((res) => navigate(ROUTE_PATHS.EMAILMASTER_LIST))
        .catch((res) => console.log(res));
    } else {
      createEmailMaster(workData)
        .then((res) => navigate(ROUTE_PATHS.EMAILMASTER_LIST))
        .catch((res) => {
          dispatch(
            toastMessageAction({
              severity: "error",
              message: res.response.data.error,
              messageStatus: true,
            })
          );
        });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: emailMasterFormInitial,
    validationSchema: emailMasterFormValidation,
    onSubmit,
  });

  const { handleChange, handleBlur, errors, touched, values, setValues } =
    formik;

  useEffect(() => {
    if (editId) {
      getEmailMasterById(editId)
        .then((res) => setValues(res.data))
        .catch((res) => console.log(res));
    }
  }, [editId, setValues]);

  return (
    <div className={root}>
      <div className={accordionContainer}>
        <CustomToggleButton />
      </div>
      <div className={formContainer}>
        <div className={titleContainer}>
          <IconButton
            className={arrowBtn}
            onClick={() => navigate(ROUTE_PATHS.EMAILMASTER_LIST)}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" className={title}>
            New Email Master
          </Typography>
        </div>
        <div className={formBox}>
          <Grid container rowSpacing={3} columnSpacing={3} p={4}>
            {emailMasterForm.map((item, index) => (
              <Grid item xs={4} key={index}>
                <CustomTextField
                  name={item.name}
                  label={item.label}
                  type={item.type}
                  onChange={handleChange}
                  value={values[item.name]}
                  error={touched[item.name] && errors[item.name]}
                  onBlur={handleBlur}
                  fullWidth={true}
                />
              </Grid>
            ))}
          </Grid>
          <div className={saveBtnContainer}>
            <Button
              variant="contained"
              className={cancelBtn}
              type="submit"
              onClick={() => navigate(ROUTE_PATHS.EMAILMASTER_LIST)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={saveBtn}
              type="submit"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {editId ? "Update" : "Save"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
