import { makeStyles } from "@mui/styles";

export const loginPageStyle = makeStyles((theme) => ({
  container: {
    height: 64,
    background: "#021256 0% 0% no-repeat padding-box",
    // borderRadius: "0px 40px 0px 0px",
  },
  root: {
    height: 85,
    background: "#E46D35 0% 0% no-repeat padding-box !important",
  },
  rootAlign: {
    height: 64,
    background: "#021256 0% 0% no-repeat padding-box !important",
  },
  maskGroup2Image: {
    right: 0,
    bottom: 0,
  },
  hidePassword: {
    "& div > input": {
      "-webkit-text-security": "disc",
    },
  },
  loginContainerAlign: {
    width: "86%",
    height: "calc(100vh - 64px)",
    margin: "auto",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  loginContainerImg: {
    width: "542px",
    height: "355px",
    boxShadow: "18px 18px #E46D35",
  },
  formContainer: {
    // position: "absolute",
    // top: "31%",
    // left: "35%",
  },
  form: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "0 0 0 0 !important",
    backgroundColor: "#eeeeee !important",
    padding: "20px !important",
    // [theme.breakpoints.down("lg")]: {
    //   minWidth: "0px !important",
    //   height: "320px",
    //   marginLeft: "50px !important",
    // },
  },
  heading: {
    color: "#2C3E50",
    font: "normal normal bold 20px/24px Montserrat",
    margin: "15px 15px 25px 15px",
    marginBottom: "20px !important",
  },
  userName: {
    width: "390px !important",
    border: "1px solid #0000008A",
    borderRadius: "4px",
  },
  forgetPassword: {
    textTransform: "capitalize !important",
    font: "normal normal 600 16px/24px Montserrat !important",
    color: "#021256 !important",
  },
  password: {
    display: "flex !important",
    justifyContent: "flex-end !important",
    width: "100% !important",
    marginBottom: "25px",
    marginRight: "50px",
    textDecoration: "underline",
  },
  loginButton: {
    width: "80%",
    font: "normal normal bold 17px/27px Nunito Sans !important",
    background: "#59B961 0% 0% no-repeat padding-box !important",
    borderRadius: "40px !important",
    // [theme.breakpoints.down("lg")]: {
    //   font: "bold 16px / 22px Montserrat !important",
    //   width: "180px ",
    // },
  },
  subHeading: {
    color: "#2C3E50 ",
    font: "normal normal normal 18px/22px Montserrat",
    marginLeft: 15,
    marginBottom: "25px !important",
  },
  backToLogin: {
    textTransform: "capitalize !important",
    font: "normal normal 600 16px/24px Montserrat !important",
    color: "#021256 !important",
    margin: "20px !important",
    textDecoration: "underline",
  },
  resetPassword: {
    display: "flex",
    position: "absolute",
    top: "33%",
    left: "33%",
    boxShadow: "0 0 0 0 !important",
    border: "1px solid black",
    borderRadius: "20px !important",
  },
  backToPage: {
    textTransform: "capitalize !important",
    font: "normal normal 600 16px/24px Montserrat !important",
    color: "#021256 !important",
    textDecoration: "underline !important",
  },
  formTitle: {
    width: "70%",
    textAlign: "center",
    marginBottom: "30px !important",
    // [theme.breakpoints.down("lg")]: {
    //   font: "bold 22px / 39px Montserrat !important",
    // },
  },
}));
