export const uploadEmailFields = [
  {
    label: "Organisation Name",
    name: "organizationName",
    type: "text",
  },
  {
    label: "Name",
    name: "name",
    type: "text",
  },
  {
    label: "Email",
    name: "email",
    type: "text",
  },
];

export const uploadEmailInitial = {
  organizationName: "",
  name: "",
  email: "",
};

export const uploadEmailColumn = () => [
  {
    Header: "Organization Name",
    accessor: "organizationName",
    sticky: "left",
    id: 1,
  },
  {
    Header: "Name",
    accessor: "name",
    id: 2,
  },
  {
    Header: "Email",
    accessor: "email",
    id: 3,
  },
];

export const uploadEmailRawData = [
  {
    organisationname: "ABC",
    name: "example",
    email: "mm@gmail.com",
  },
  {
    organisationname: "ABC",
    name: "example",
    email: "mm@gmail.com",
  },
  {
    organisationname: "ABC",
    name: "example",
    email: "mm@gmail.com",
  },
];
