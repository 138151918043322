export const navBarHeading = [
  {
    id: 1,
    label: "DASHBOARD",
    path: "/dashboard",
  },
  {
    id: 2,

    label: "COMPANY USERS",
    path: "/companyUsers",
  },
  {
    id: 3,
    label: "ENTRIES",
    path: "/homeScreen",
  },
  { id: 4, label: "INVOICES", path: "/invoices" },
];

export const adminNavBarHeading = [
  {
    id: 1,
    label: "DASHBOARD",
    path: "/adminDashboard",
  },
  {
    id: 2,

    label: "Customers",
    path: "/customersList",
  },
  {
    id: 3,
    label: "Invoices Setup",
    path: "/invoiceSetupList",
  },
];
