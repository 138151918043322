import {
  WidgetDateField,
  WidgetRadioBtn,
  WidgetSelectField,
  WidgetTextField,
  WidgetTimeField,
  WidgetUploadFile,
} from "../components/reusable/widgets";

export const widgets = {
  TextWidget: WidgetTextField,
  DateWidget: WidgetDateField,
  SelectWidget: WidgetSelectField,
  RadioWidget: WidgetRadioBtn,
  time: WidgetTimeField,
  FileWidget: WidgetUploadFile,

  //   multiSelect: CustomAutocomplete,
};
