import React, { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { read, utils } from "xlsx";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { createEmailMaster } from "../../api/api";
import { useDispatch } from "react-redux";
import { toastMessageAction } from "../../redux/action";
import Cookies from "universal-cookie";
import BulkEmailUpload from "./BulkEmailModal";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "4px",
  borderWidth: 2,
  borderRadius: 6,
  borderColor: "#707070",
  borderStyle: "dashed",
  // backgroundColor: "#fafafa",
  color: "#707070",
  outline: "none",
  transition: "border .24s ease-in-out",
  font: "normal normal normal 18px/19px Lato",
};

const focusedStyle = {
  borderColor: "#2A5BA8",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export function DropZoneField({ getTableData }) {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const customerId = cookies.get("custId");
  const userId = cookies.get("userId");
  const [open, setOpen] = React.useState(false);
  const [uploadStatus, setUploadStatus] = useState({});

  const updateCandidate = useCallback(
    async (bulkData) => {
      let workData = bulkData?.map((item) => ({ ...item, userId, customerId }));

      createEmailMaster(workData)
        .then((res) => {
          setOpen(true);
          setUploadStatus(res.data);
          getTableData();
        })
        .catch((res) => {
          dispatch(
            toastMessageAction({
              severity: "error",
              message: res.response.data.error,
              messageStatus: true,
            })
          );
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerId, getTableData, userId]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      let fileReader = new FileReader();
      fileReader.readAsArrayBuffer(acceptedFiles[0]);
      fileReader.onload = (e) => {
        let bufffeArray = e.target.result;
        const wb = read(bufffeArray);
        const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);

        updateCandidate(
          data.map(({ __rowNum__, Name = "", ...remains }) => {
            return {
              organizationName: remains?.["OrganizationName"],
              name: remains?.["ContactName"],
              email: remains?.["Email"],
            };
          })
        );
      };
    },
    [updateCandidate]
  );

  const { getRootProps, getInputProps, isDragAccept, isDragReject, isFocused } =
    useDropzone({
      onDrop,
      accept: {
        "text/csv": [".csv"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      },
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  return (
    <div>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FileUploadIcon style={{ marginRight: 20 }} />
          <p>Bulk upload of email</p>
        </div>
      </div>
      <BulkEmailUpload
        open={open}
        setOpen={setOpen}
        uploadStatus={uploadStatus}
      />
    </div>
  );
}
