import moment from "moment";
import { downloadFileByLink } from "../../api/api";
import { saveAs } from "file-saver";
import { sidebarData } from "../sidebar";

export const ddRequirementsInformationList = (
  financialYearInput,
  formSeed,
  parent
) => [
  {
    id: 1,
    Header: "Year",
    accessor: "financialYearId",
    sticky: "left",
    Cell: (props) => {
      return financialYearInput?.map(
        (item) => item.id === props.value && item.name
      );
    },

    width: 250,
  },
  {
    Header: "Date",
    accessor: "updatedAt",
    id: 2,
    Cell: (props) => moment(props.value).format("L"),
    width: 200,
  },
  {
    Header: "Module",
    accessor: "formId",
    id: 3,
    Cell: (props) => {
      let getName = formSeed.find((item) => item.id === props.value);

      return parent
        ? sidebarData[0].buttons.map(
            (item) => item.value === getName.name && item.name
          )
        : sidebarData[1].buttons.map((item) =>
            item.buttons
              ? item.buttons.map(
                  (subBtn) => subBtn.value === getName.name && subBtn.name
                )
              : item.value === getName.name && item.name
          );
    },
    width: 350,
  },
  {
    Header: "File",
    accessor: "fileName",
    id: 4,
    Cell: (props) => (
      <div
        style={{
          color: "#3A95D8",
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={() => {
          downloadFileByLink(
            props.row.original.detail === "Information"
              ? "informationDetails"
              : "supportingData",
            props.row.original.id
          ).then((res) => saveAs(res.data.url));
        }}
      >
        {props.value}
      </div>
    ),
    width: 200,
  },
  {
    Header: "Upload Detail",
    accessor: "detail",
    id: 5,
    width: 200,
  },
  {
    Header: "Update By",
    accessor: "user.name",
    id: 6,
    width: 200,
  },
];

export const rawData = [
  {
    year: "1999",
    date: "12/09/1999",
    module: "size of organisation",
    file: "filename",
    uploadDetail: "information",
    updateBy: "dev",
  },
];
