/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import { filterStyle } from "../../../styles/dashboard";
import { useFormik } from "formik";
import { useNavigate, createSearchParams } from "react-router-dom";
import { ROUTE_PATHS } from "../../../routes/routePath";
import {
  filterTypeSeeds,
  invoiceSetupFilter,
  invoiceSetupFilterInitial,
} from "../../../constant";
import { getAllStatus } from "../../../api/api";
import { CustomButton, CustomTextField, DropdownField } from "..";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 712,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const InvoiceSetupFilter = () => {
  const useStyles = filterStyle();

  const navigate = useNavigate();
  // let pathName = useLocation().pathname.split("/")[2];
  const [seeds, getSeeds] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = async (data) => {
    let workData = { ...data };
    Object.keys(workData).map((item) => {
      if (!workData[item]) {
        delete workData[item];
      }
    });

    let filter = {
      invoiceNo: {
        [workData.invoiceNoType]:
          workData.invoiceNoType === "in" || workData.invoiceNoType === "notIn"
            ? [...workData.invoiceNo.split(",")]
            : workData.invoiceNo,
      },
      name: {
        [workData?.nameType || "eq"]:
          workData.nameType === "in" || workData.nameType === "notIn"
            ? [...workData.name.split(",")]
            : workData.name,
      },
      paymentStatusId: {
        [workData?.paymentStatusIdType || "eq"]:
          workData.paymentStatusIdType === "in" ||
          workData.paymentStatusIdType === "notIn"
            ? [...workData.paymentStatusId.split(",")]
            : workData.paymentStatusId,
      },
    };

    Object.keys(filter).map((item) => {
      if (
        filter[item].eq === undefined &&
        filter[item].ne === undefined &&
        filter[item].in === undefined &&
        filter[item].notIn === undefined
      ) {
        delete filter[item];
      }
    });

    navigate({
      pathName: ROUTE_PATHS.INVOICES_SETUP_LIST,
      search: `?${createSearchParams({
        filter: JSON.stringify(filter),
      })}`,
    });
    setOpen(false);
    resetForm();
  };

  //to handle formik data
  const formik = useFormik({
    initialValues: invoiceSetupFilterInitial,
    onSubmit,
  });

  const handleClearFilter = () => {
    navigate({
      pathName: ROUTE_PATHS.MY_USERS_LIST,
    });
    setOpen(true);
    resetForm();
  };

  const { values, handleChange, resetForm, handleBlur } = formik;

  useEffect(() => {
    getAllStatus().then((response) => {
      getSeeds(response.data);
    });
  }, []);

  return (
    <div>
      <span onClick={handleOpen} className={useStyles.superAdminTopbarIcons}>
        <FilterListIcon style={{ marginRight: 10 }} />
        Filter
      </span>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={useStyles.title}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Filter
            </Typography>
            <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className={useStyles.invoiceTitle}>
              <Typography
                id="modal-modal-description"
                sx={{ p: 3 }}
                style={{ width: "120px" }}
              >
                Invoice No
              </Typography>
              <DropdownField
                label={invoiceSetupFilter.invoiceNoType.label}
                inputValues={filterTypeSeeds || []}
                name={invoiceSetupFilter.invoiceNoType.name}
                onChange={handleChange}
                className={useStyles.titleContentStyle}
                value={values.invoiceNoType}
              />
              <CustomTextField
                label={invoiceSetupFilter.invoiceNo.label}
                name={invoiceSetupFilter.invoiceNo.name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.invoiceNo}
                variant="outlined"
                logInField
                style={{ width: "250px" }}
              />
            </div>
            <div className={useStyles.invoiceTitle}>
              <Typography id="modal-modal-description" sx={{ p: 3 }}>
                Customer name
              </Typography>
              <DropdownField
                label={invoiceSetupFilter.nameType.label}
                inputValues={filterTypeSeeds || []}
                name={invoiceSetupFilter.nameType.name}
                onChange={handleChange}
                className={useStyles.titleContentStyle}
                value={values.nameType}
              />
              <CustomTextField
                label={invoiceSetupFilter.name.label}
                name={invoiceSetupFilter.name.name}
                onChange={handleChange}
                value={values.name}
                variant="outlined"
                onBlur={handleBlur}
                logInField
                style={{ width: "250px" }}
              />
            </div>
            <div className={useStyles.invoiceTitle}>
              <Typography
                id="modal-modal-description"
                style={{ width: "150px", paddingLeft: "20px" }}
              >
                Payment Status
              </Typography>
              <DropdownField
                label={invoiceSetupFilter.paymentStatusType.label}
                inputValues={filterTypeSeeds || []}
                name={invoiceSetupFilter.paymentStatusType.name}
                onChange={handleChange}
                value={values.paymentStatusIdType}
                className={useStyles.titleContentStyle}
              />
              <DropdownField
                label={invoiceSetupFilter.paymentStatus.label}
                inputValues={seeds || []}
                name={invoiceSetupFilter.paymentStatus.name}
                onChange={handleChange}
                value={values.paymentStatusId}
                className={useStyles.statusActiveStyle}
                variant="outlined"
              />
            </div>

            <div className="buttonModal">
              <Typography
                id="modal-modal-description"
                sx={{ p: 3 }}
                onClick={handleClose}
                style={{ cursor: "pointer" }}
                className={useStyles.cancelButton}
              >
                CANCEL
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ p: 3 }}
                onClick={handleClearFilter}
                style={{ cursor: "pointer" }}
                className={useStyles.cancelButton}
              >
                CLEAR FILTER
              </Typography>
              <CustomButton
                variant="contained"
                label="Apply"
                className={useStyles.saveButton}
                type="submit"
              />
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default InvoiceSetupFilter;
