import { saveAs } from "file-saver";

export const downloadFile = (props) => {
  let url = props.row.original.fileUrl;
  let fileName = props.row.original.fileName;

  // console.log(url);

  // let index = props.cell.row.index;
  // let data = props.data[index].uploadFile;
  // // const file = new Blob([data], { type: "text/pdf;charset=utf-8" });

  saveAs(url, fileName);
};

export const downloadTableData = (data, filename) => {
  const file = new Blob([data], { type: "text/csv;charset=utf-8" });
  saveAs(file, filename);
};
