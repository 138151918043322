export const ROUTE_PATHS = {
  LOGIN: "/",
  FORGETPASSWORD: "/forgetPassword",
  RESETPASSWORD: "/resetPassword",
  UPDATEPASSWORD: "/forget-password",

  DASHBOARD: "/dashboard",
  COMPANYUSERS: "/companyUsers",
  BRSRENTRY: "/brsrEntry",
  COMPANYUSERSFORM: "/companyUsersForm",
  INVOICES: "/invoices",
  PRINTPDF: "/printPdf",
  USER_FORM: "/companyUsersForm/userForm",
  COMPANY_USER_ACCESS: "/companyUsersForm/userAccess",
  PARAMETER: "/parameter",

  // company admin profile
  PROFILE: "/profile",
  CHAHGE_PASSWORD: "/changePassword",
  EDIT_PROFILE: "/editProfile",

  //company user profile
  USER_PROFILE: "/userProfile",
  USER_CHAHGE_PASSWORD: "/userChangePassword",
  USER_EDIT_PROFILE: "/userEditProfile",
  WELCOME_PAGE: "/welcomePage",

  //my users
  MY_USERS_LIST: "/myUsersList",
  MY_USERS_FORM: "/myUsers/MyUserForm",

  //customers

  CUSTOMERS_FORM: "/customersForm",
  CUSTOMERS_LIST: "/customersList",

  //invoice setup

  INVOICES_SETUP_FORM: "/invoiceSetupForm",
  INVOICES_SETUP_LIST: "/invoiceSetupList",

  //company profile

  COMPANY_PROFILE: "/companyProfile",
  COMPANY_EDIT_PROFILE: "/companyEditProfile",

  //super admin

  SUPER_ADMIN_DASHBOARD: "/adminDashboard",
  INVOICE_DASHBOARD: "/invoiceDashboard",

  //user entry
  USER_ENTRY: "/userEntry",
  HOMEPAGE: "/homeScreen",

  //end user
  DD_REQUIREMENTS_ANALYSIS: "/dd-requiremetns-analysis",
  DUE_DILLIGENCE_DESCRIPTION: "/due-dilligence-description",
  TRANSPARENCY_ACT_REPORT: "/transparency-act-report-creator",
  EMAIL_CREATION_REQUEST_CREATOR: "/email-information-request-creator",

  VIEW_REPORT_GENERATION: "/viewReportGeneration",

  EMAILMASTER_LIST: "/email-information-request-creator/emailMaster/list",
  EMAILMASTER_FORM: "/email-information-request-creator/emailMaster/form",
  EMAILCREATION_LIST: "/email-information-request-creator/emailCreation/list",
  EMAILCREATION_FORM: "/email-information-request-creator/emailCreation/form",
  DATA_COLLECTION_FORM:
    "/email-information-request-creator/dataCollectionContent/form",
  DATA_COLLECTION_LIST:
    "/email-information-request-creator/dataCollectionContent/list",
  EMAIL_RESPONSES_LIST:
    "/email-information-request-creator/emailResponses/list",

  VIEW_FORM: "/viewForm",
  EMAIL_LINK_FORM: "/emailLinkForm",
  VIEW_RESPONSES: "/viewResponses",

  EMAIL_VIEW: "/emailView",
  LOG_HISTORY: "/logHistory",
};
