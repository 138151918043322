export const profileDetailSytle = {
  listContainer: {
    position: "absolute !important",
    top: "63px",
    width: "100%",
  },
  superAdminRoot: {
    // backgroundColor: "#F0EDED!important",
  },
  superAdminTitle: {
    fontSize: "18px",
    textTransform: "uppercase",
    // background: "#F0EDED",
    opacity: 1,
    padding: "20px 50px",
  },
  root: {
    height: 85,
    background: "#E46D35 !important",
  },
  title: {
    font: "normal normal medium 24px/16px Rubik !important",
    color: "white",
    textTransform: "uppercase",
    background: "#021256 0% 0% no-repeat padding-box",
    // borderRadius: "0px 40px 0px 0px",
    opacity: 1,
    padding: "0px 50px",
    height: 64,
    display: "flex",
    alignItems: "center",
  },
  emailTitle: {
    fontSize: "14px !important",
    letterSpacing: 0,
    color: "#0000008A !important",
    opacity: 1,
  },
  profileContainer: {
    display: "flex !important",
    justifyContent: "space-between !important",
    width: "712px",
    background: "#FFFFFF 0% 0% no-repeat padding-box !important",
    boxShadow: "0px 1px 3px #00000029 !important",
    border: "1px solid #707070 !important",
    borderRadius: "4px !important",
    opacity: 1,
    margin: "30px 50px ",
    padding: "20px",
  },
  profile: {
    display: "flex",
    alignItems: "center ",
  },
  editProfile: {
    marginTop: "10px",
  },
  accountCircleIcon: {
    width: "150px !important",
    height: "150px !important",
    color: "#bdbdbd",
    borderRadius: "50%",
    marginRight: "20px ",
  },
  lockIcon: {
    width: "22px !important",
    height: "22px !important",
    background: "#F0EDED 0% 0% no-repeat padding-box",
    borderRadius: "2px",
    opacity: 1,
    color: "#0000008A !important",
    marginRight: "15px !important",
    padding: "15px !important",
    cursor: "pointer",
  },
  type: {
    background: "#F0EDED 0% 0% no-repeat padding-box",
    textAlign: "center",
    fontSize: 14,
    padding: 10,
    marginTop: 20,
  },
  companyProfile: {
    display: "flex !important",
    justifyContent: "space-between !important",
    width: "500px",
    alignItems: "center",
    background: "#FFFFFF 0% 0% no-repeat padding-box !important",
    boxShadow: "0px 1px 3px #00000029 !important",
    border: "1px solid #707070 !important",
    borderRadius: "4px !important",
    opacity: 1,
    margin: "60px",
    padding: "30px 40px",
  },
  userProfileContainer: {
    width: "calc(100vw - 370px)",
    position: "absolute",
    left: "330px",
    padding: 0,
    boxSizing: "border-box",
  },
  userRoot: {
    display: "flex",
  },
  accordionContainer: {
    width: "320px",
    height: "calc(100vh - 64px)",
    position: "fixed",
    overflowY: "scroll",
    backgroundColor: "white",
    borderRight: "2px solid #7394c6",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent !important",
      width: "0px !important",
    },
  },
};
