import React, { useEffect, useRef } from "react";
import User from "../../../asset/user.png";
import { FormControl } from "@mui/material";
export const WidgetUploadFile = (props) => {
  const { label, error, url, touched, value, onChange, readonly } = props;
  const { profileUrl } = props.formContext;

  const [imgUrl, setImgUrl] = React.useState(url);
  const [fileName, setFileName] = React.useState(value && value[0]?.name);
  const myRefname = useRef(null);

  const handleClick = (e) => {
    myRefname.current.click();
  };
  // const name = "profile";
  const onImageChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setImgUrl(reader.result);
        onChange([event.target.files[0]]);
        setFileName(event.target.files[0].name);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
    return "";
  };

  useEffect(() => {
    if (typeof value === "string") setFileName(value);
  }, [value]);

  useEffect(() => {
    setImgUrl(url);
  }, [url]);

  console.log(profileUrl);

  return (
    <FormControl fullWidth>
      <input
        ref={myRefname}
        className="hiddenFile"
        // name={name}
        type={"file"}
        error={touched && error}
        onChange={(event) => onImageChange(event)}
        helperText={touched && error}
        label={label}
        disabled={readonly}
      />
      <div onClick={(e) => handleClick(e)} className="fileInputHolderWidget">
        <img
          src={imgUrl || profileUrl || User}
          className="userImg"
          alt=""
        ></img>
        <span style={{ color: "grey" }}>{fileName || label}</span>
      </div>
    </FormControl>
  );
};
