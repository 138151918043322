export const steps = [
  {
    id: 1,
    label: "User Details",
  },
  {
    id: 2,
    label: "User Access",
  },
];
