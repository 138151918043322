import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  // emailMasterFormValidation,
  uploadEmailColumn,
  uploadEmailFields,
  uploadEmailInitial,
} from "../../constant";
import { useFormik } from "formik";
import { Box, Grid } from "@mui/material";
import { CustomTextField } from ".";
import { makeStyles } from "@mui/styles";
import CustomReactTable from "./CustomReactTable";
import { getEmailMaster } from "../../api/api";
import Cookies from "universal-cookie";
import { useLocation } from "react-router-dom";
import { parseQueryParams } from "../../utils/queryParams";
// import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root ": {
    minWidth: "90% !important",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2, backgroundColor: "#2A5BA8", color: "white" }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: "10px !important",
    fontWeight: "bold !important",
  },
  gridItem: {
    display: "flex !important",
    alignItems: "center !important",
    paddingLeft: "40px !important",
  },
  tableContainer: {
    width: "95%",
    margin: "auto",
  },
  bottomBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "89%",
    margin: "auto",
  },
  btn: {
    padding: "6px 36px !important",
    backgroundColor: "#33CE5E !important",
  },
  cancelbtn: {
    padding: "6px 36px !important",
    background: "#0000001A !important",
    color: "black !important",
  },
}));

export function EmailUploadModal({ open, handleClose, setFieldValue, value }) {
  const { bottomBtnContainer, btn, cancelbtn, gridItem, tableContainer } =
    useStyles();
  const cookies = new Cookies();
  // const customerId = cookies.get("custId");
  // const userId = cookies.get("userId");
  const userTypeId = cookies.get("userTypeId");
  const location = useLocation();
  const pageParams = parseQueryParams(location?.search);
  const pageSize = parseInt(pageParams?.pageSize) || 100;

  const [tableData, setTableData] = React.useState([]);
  // const navigate = useNavigate();

  let getValues = [];
  const selectedRows = (values) => {
    getValues = values.map((item) => item.original);
  };

  const onSubmit = (values, { resetForm }) => {
    let workData = {
      ...values,
    };
    Object.keys(workData).map((item) => {
      if (!workData[item]) {
        delete workData[item];
      }
      return "";
    });

    let filter = {
      organizationName: {
        eq: workData?.organizationName,
      },
      name: {
        eq: workData?.name,
      },
      email: {
        eq: workData?.email,
      },
    };

    Object.keys(filter).forEach((item) => {
      if (filter[item].eq === undefined) {
        delete filter[item];
      }
    });

    let filtervalue = {
      filter: {
        userTypeId: { eq: userTypeId },
        ...filter,
      },
    };

    getEmailMaster(filtervalue, pageSize, 0)
      .then((res) => setTableData(res.data.rows))
      .catch((res) => console.log(res));

    // resetForm();
  };

  const formik = useFormik({
    initialValues: uploadEmailInitial,
    // validationSchema: emailMasterFormValidation,
    onSubmit,
  });

  const { handleChange, handleBlur, errors, touched, values } = formik;

  React.useEffect(() => {
    let valueKeys = Object.values(values).every((item) => item.length === 0);

    valueKeys &&
      getEmailMaster({}, pageSize, 0)
        .then((res) => setTableData(res.data.rows))
        .catch((res) => console.log(res));
  }, [pageSize, values]);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Upload Email
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form>
            <Grid container rowSpacing={3} columnSpacing={3} p={4}>
              {uploadEmailFields.map((item, index) => (
                <Grid item xs={3} key={index}>
                  <CustomTextField
                    label={item.label}
                    name={item.name}
                    type={item.type}
                    value={values[item.name]}
                    onChange={handleChange}
                    error={touched[item.name] && errors[item.name]}
                    onBlur={handleBlur}
                    fullWidth={true}
                  />
                </Grid>
              ))}
              <Grid item xs={3} className={gridItem}>
                <Button
                  variant="contained"
                  className={cancelbtn}
                  onClick={formik.resetForm}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className={btn}
                  onClick={formik.handleSubmit}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </form>
          <div className={tableContainer}>
            <CustomReactTable
              columnData={uploadEmailColumn()}
              rawData={tableData}
              selectedRows={selectedRows}
              disablePagination
              disableColumnHiding={true}
              disableSort={true}
              style={{
                th: {
                  color: "#0000008A",
                  font: "normal normal bold 14px/19px Roboto",
                  display: "flex !important",
                  alignItems: "center",
                  height: "64px !important",
                },
                body: {
                  position: "relative",
                  zIndex: "0",
                  backgroundColor: "white",
                  color: "#40403A",
                  font: "normal normal bold 14px/19px Roboto",
                  maxHeight: "250px !important",
                },
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Box className={bottomBtnContainer}>
            {/* <Button
              variant="outlined"
              //   className={cancelBtn}
              onClick={handleClose}
            >
              Cancel
            </Button> */}
            <Button
              variant="contained"
              color="success"
              className={btn}
              onClick={() => {
                setFieldValue("emails", [...getValues, ...value]);
                handleClose();
              }}
            >
              Select
            </Button>
          </Box>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
