import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export function WidgetRadioBtn(props) {
  const { label, value, onChange, options, readonly } = props;

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        aria-label={label}
        name={label}
        value={value}
        onChange={(event) => {
          onChange(event.target.value === "true" ? true : false);
        }}
        style={{ display: "flex", flexDirection: "row" }}
      >
        {options.enumOptions.map((option, index) => {
          return (
            <FormControlLabel
              value={option?.value}
              key={index}
              control={<Radio color="primary" />}
              label={option.label}
              disabled={readonly}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
