import { makeStyles } from "@mui/styles";

export const userNavBarStyle = makeStyles({
  root: {
    height: "64px",
    background: "#EEEEEE 0% 0% no-repeat padding-box !important",
  },
  rootAlign: {
    height: "64px",
    // background: "#021256 0% 0% no-repeat padding-box !important",
  },
  container: {
    display: "flex ",
    // height: 64,
    // background: "#E46D35 0% 0% no-repeat padding-box !important",
    boxShadow: "none !important",
  },
  logoImg: {
    height: 64,
    width: "360px",
  },
  profileContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingLeft: 20,
    width: "calc(100% - 200px)",
    font: "normal normal 600 19px/19px Rubik !important",
    color: "#FFFFFF ",
    height: 66,
  },
  superAdminProfileContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 20,
    width: "calc(100% - 200px)",
    font: "normal normal 600 16px/16px Rubik !important",
    color: "#FFFFFF ",
    height: 66,
  },
  navBarContentStyle: {
    font: "normal normal 600 16px/19px Montserrat !important",
    height: "61px !important",
    color: "#021256 !important",
  },
  navBarContentAdminStyle: {
    font: "normal normal 600 16px/19px Montserrat !important",
    height: "61px !important",
    color: "#FFFFFF !important",
  },
  userProfile: {
    display: "flex",
    flexDirection: "column",
  },
  profile: {
    display: "flex",
    alignItems: "center",
  },
  adminName: {
    font: "normal normal 16px/16px Rubik",
    letterSpacing: "0px",
    color: "#021256",
    opacity: 1,
    textTransform: "capitalize",
  },
  adminRole: {
    font: "normal normal 12px/16px Rubik",
    letterSpacing: "0px",
    color: "#021256",
    marginTop: 3,
    opacity: 1,
    textTransform: "capitalize",
  },
  menuAppBar: {
    position: "absolute !important",
    top: "48px !important",
    right: "20px",
  },
  superAdminRoot: {
    background: "#2A5BA8 0% 0% no-repeat padding-box !important",
    opacity: 1,
  },
  adminContainer: {
    display: "flex ",
    height: 64,
    background: "#2A5BA8 0% 0% no-repeat padding-box !important",
    boxShadow: "none !important",
  },
});
