import { Button, Divider, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { formStyles } from "../../../styles";
import {
  CustomTextField,
  CustomUploadImage,
  DropdownField,
} from "../../reusable";
import CustomReactTable from "../../reusable/CustomReactTable";
import { useParams } from "react-router-dom";
import { createInformationDetails } from "../../../api/api";

export const InformationDetails = ({
  tableColumn,
  tableRawdata,
  informationDataInitial,
  informationDataValidation,
  informationDataFields,
  setRenderTable,
  formValues,
  formDataId,
}) => {
  const { divider, subTitle, addBtn, addBtnContainer, tableContainer } =
    formStyles();

  let { screen } = useParams();

  const onSubmit = (values, { resetForm }) => {
    let workData = {
      information: values.uploadFile,
      data: JSON.stringify({
        ...values,
        financialYear: formValues.financialYear,
      }),
    };

    console.log(workData);

    let form = new FormData();
    Object.keys(workData)
      .filter((fill) => workData[fill] !== null && workData[fill] !== "")
      .map((item) => {
        //to remove spaces in value
        return form.append(
          item,
          typeof workData[item] === "string"
            ? workData[item]
                .toString()
                .replace(/\s{2,}/g, " ")
                .trim()
            : workData[item]
        );
      });

    formDataId &&
      createInformationDetails(formDataId, form)
        .then((res) => setRenderTable((prevData) => !prevData))
        .catch((res) => console.log(res));

    resetForm();
  };

  const formik = useFormik({
    initialValues: informationDataInitial,
    validationSchema: informationDataValidation,
    onSubmit,
  });

  const {
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    touched,
    values,
    resetForm,
  } = formik;

  useEffect(() => {
    resetForm();
  }, [resetForm, screen]);

  return (
    <div>
      <Typography className={subTitle}>
        Upload Information Details (Optional)
      </Typography>
      <form>
        <Grid container rowSpacing={3} columnSpacing={3} p={4}>
          {informationDataFields.map((item, index) => {
            return item.type === "text" ? (
              <Grid item xs={4} key={index}>
                <CustomTextField
                  label={item.label}
                  name={item.name}
                  type={item.type}
                  value={values[item.name]}
                  onChange={handleChange}
                  error={touched[item.name] && errors[item.name]}
                  onBlur={handleBlur}
                  fullWidth={true}
                />
              </Grid>
            ) : item.type === "select" ? (
              <Grid item xs={4} key={index}>
                <DropdownField
                  label={item.label}
                  name={item.name}
                  value={values[item.name]}
                  onChange={handleChange}
                  error={touched[item.name] && errors[item.name]}
                  onBlur={handleBlur}
                  inputValues={item.inputValues}
                  fullWidth={true}
                />
              </Grid>
            ) : (
              <Grid item xs={4} key={index}>
                <CustomUploadImage
                  name={item.name}
                  label={item.label}
                  onChange={setFieldValue}
                  value={values[item.name]}
                  error={touched[item.name] && errors[item.name]}
                  uploadFile={true}
                />
              </Grid>
            );
          })}
        </Grid>
        <div className={addBtnContainer}>
          <Button
            variant="contained"
            className={addBtn}
            onClick={formik.handleSubmit}
          >
            ADD
          </Button>
        </div>
        <div className={tableContainer}>
          <CustomReactTable
            columnData={tableColumn}
            rawData={tableRawdata}
            disablePagination
            disableColumnHiding={true}
            disableRowSelection={true}
            disableSort={true}
            style={{
              th: {
                color: "#0000008A",
                font: "normal normal bold 14px/19px Roboto",
                backgroundColor: "#D2E1FC",
                display: "flex !important",
                alignItems: "center",
                height: "64px !important",
              },
              body: {
                position: "relative",
                zIndex: "0",
                backgroundColor: "white",
                color: "#40403A",
                font: "normal normal bold 14px/19px Roboto",
              },
            }}
          />
        </div>
        <Divider className={divider} />
      </form>
    </div>
  );
};
