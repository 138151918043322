import * as React from "react";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

export function CustomAutoComplete(props) {
  const {
    inputValues,
    className,
    setFieldValue,
    label,
    name,
    onChange,
    onBlur,
    error,
    helperText,
    value,
  } = props;
  return (
    <Stack spacing={3}>
      <Autocomplete
        multiple
        id="tags-filled"
        autoComplete="off"
        className={className}
        onBlur={onBlur}
        value={value || []}
        // onChange={onChange}
        onChange={(event, newValue) => {
          onChange ? onChange(event, newValue) : setFieldValue(name, newValue);
        }}
        // options={inputValues.map((option) => option)}
        options={inputValues}
        getOptionLabel={(option) => option?.email || option?.name || option}
        freeSolo
        renderTags={(value, getTagProps) =>
          value?.map((option, index) => (
            <Chip
              variant="outlined"
              label={option?.email || option?.name || option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            autoComplete="off"
            label={label}
            name={name}
            error={Boolean(error)}
            helperText={helperText}
            fullWidth
          />
        )}
      />
    </Stack>
  );
}
