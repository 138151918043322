import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import html2pdf from "html2pdf.js";
// import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import Cookies from "universal-cookie";
import { getAllParamter, getReportData } from "../../api/api";
import { reportInitial } from "../../constant";
import { toastMessageAction } from "../../redux/action";
import { ROUTE_PATHS } from "../../routes/routePath";
import { reportStyles } from "../../styles";
import { createQueryParams } from "../../utils/queryParams";
import { CustomToggleButton, DropdownField } from "../reusable";
import ErrorModalBox from "../reusable/ErrorModalBox";

// @print {
//   @page :footer {
//       display: none
//   }

//   @page :header {
//       display: none
//   }
// }

// const pageStyle = "@page { size: 210mm 297mm ; margin:80px 80px; }  ";

export const Report = () => {
  const {
    root,
    accordionContainer,
    formContainer,
    titleContainer,
    title,
    reportGenText,
    captionTitle,
    captionTitleContainer,
    pdfTitle,
    checkBoxTitle,
    pdfPara,
    reportBtn,
  } = reportStyles();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const componentRef = React.useRef();
  const cookies = new Cookies();
  const custId = cookies.get("custId");
  const [reportData, setReportData] = useState([]);
  const [allBox, setAllBox] = useState({ all: true });
  const [selectedValues, setSelectedValues] = useState(reportInitial);
  const [financialYearInput, setFinancialYearInput] = React.useState([]);
  const [financialYearId, setFinancialYearId] = React.useState("");
  const [errorModal, setErrorModal] = React.useState(false);

  const handleAllCheckbox = (e) => {
    let changeValues = {};
    Object.keys(selectedValues).forEach((item) => {
      changeValues = { ...changeValues, [item]: e.target.checked };
    });
    setAllBox({ all: e.target.checked });
    setSelectedValues(changeValues);
  };

  const handleCheckbox = (e) => {
    setSelectedValues({ ...selectedValues, [e.target.name]: e.target.checked });
  };

  useEffect(() => {
    let checkAllValues = Object.keys(selectedValues).some(
      (item) => selectedValues[item] === false
    );

    checkAllValues && setAllBox({ all: false });
  }, [selectedValues]);

  const fetchData = useCallback(
    (interfaceName, adverseSecId) => {
      let findData = reportData?.find(
        (item) => item.form.name === interfaceName
      );

      let adverseImpactDetails = ``;

      findData &&
        adverseSecId &&
        findData?.otherInformationDetails?.forEach(
          (item) =>
            item.adverseImpactSectionId === adverseSecId &&
            (adverseImpactDetails += `${item.data?.uploadInformation} ${"\n"}`)
        );

      return findData && adverseSecId
        ? adverseImpactDetails
        : findData?.informationText;

      // return findData && findData?.informationText;
    },
    [reportData]
  );

  useEffect(() => {
    financialYearId &&
      getReportData(financialYearId)
        .then((res) => {
          setReportData(res.data);
          console.log("res.data: ", res.data);
        })
        .catch((res) => console.log(res));
  }, [financialYearId]);

  useEffect(() => {
    getAllParamter().then((res) => {
      let filterInput = res.data.filter((item) => item.customerId === custId);
      let inputValues = filterInput.map((item) => ({
        id: item.id,
        accessor: "id",
        // name: `${moment(item.year.split("-")[0]).format("ll")} - ${moment(
        //   item.year.split("-")[1]
        // ).format("ll")}`,
        name: item.year,
      }));
      setFinancialYearInput(inputValues);
    });
  }, [custId]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Transparency Act Report.pdf`,
    copyStyles: true,
    print: async (printIframe) => {
      const document = printIframe.contentDocument;
      var opt = {
        filename: "Transparency Act Report.pdf",
      };
      if (document) {
        const html = document.getElementsByTagName("html")[0];
        await html2pdf().from(html).set(opt).save();
      }
    },
  });

  const errorHadler = () => {
    let checkFalseValue = Object.keys(selectedValues).every(
      (item) => selectedValues[item] === false
    );
    if (!financialYearId) {
      return dispatch(
        toastMessageAction({
          severity: "error",
          message: "Select any Year",
          messageStatus: true,
        })
      );
    }
    if (checkFalseValue) {
      setErrorModal(true);
    } else {
      handlePrint();
    }
  };

  const navigateViewReport = () => {
    const newParams = createQueryParams({
      selectedValues: JSON.stringify(selectedValues),
      financialYearId,
    });
    navigate(`${ROUTE_PATHS.VIEW_REPORT_GENERATION}?${newParams}`);
  };

  return (
    <div className={root}>
      <ErrorModalBox
        setErrorModal={setErrorModal}
        errorModal={errorModal}
        message={"Please select any module"}
      />

      <div className={accordionContainer}>
        <CustomToggleButton />
      </div>
      <div className={formContainer}>
        <div className={titleContainer}>
          <Typography variant="h6" className={clsx(title, reportGenText)}>
            REPORT GENERATION
          </Typography>
          <Button
            variant="contained"
            className={reportBtn}
            onClick={() => navigateViewReport()}
          >
            VIEW REPORT
          </Button>
          <Button
            variant="contained"
            className={reportBtn}
            onClick={errorHadler}
          >
            DOWNLOAD REPORT
          </Button>

          {/* <ReactToPrint
            pageStyle={pageStyle}
            documentTitle={"Transparency Act Report"}
            trigger={() => (
              <Button variant="contained" className={reportBtn}>
                DOWNLOAD REPORT
              </Button>
            )}
            content={() => componentRef.current}
          /> */}
        </div>
        <Grid container>
          <Grid item xs={7} sx={{ margin: "18px 0" }}>
            <DropdownField
              name="financialYearId"
              label={"Select Year"}
              value={financialYearId}
              fullWidth={true}
              inputValues={financialYearInput || []}
              onChange={(e) => setFinancialYearId(e.target.value)}
            />
          </Grid>
        </Grid>
        <FormControlLabel
          className={checkBoxTitle}
          control={
            <Checkbox
              name="all"
              onChange={handleAllCheckbox}
              checked={allBox?.all || false}
            />
          }
          label="ALL"
        />
        <div ref={componentRef}>
          <FormControlLabel
            className={checkBoxTitle}
            control={
              <Checkbox
                name="organisationStructure"
                onChange={handleCheckbox}
                checked={selectedValues?.organisationStructure || false}
              />
            }
            label="ORGANISATION STRUCTURE"
          />
          <Typography className={pdfPara}>
            {fetchData("OrganizationStructure")}
          </Typography>

          <FormControlLabel
            className={checkBoxTitle}
            control={
              <Checkbox
                name="areasOfOperation"
                onChange={handleCheckbox}
                checked={selectedValues?.areasOfOperation || false}
              />
            }
            label="AREAS OF OPERATION"
          />
          <Typography className={pdfPara}>
            {fetchData("areasOfOperation")}
          </Typography>

          <FormControlLabel
            className={checkBoxTitle}
            control={
              <Checkbox
                name="guildelinesPolicies"
                onChange={handleCheckbox}
                checked={selectedValues?.guildelinesPolicies || false}
              />
            }
            label="GUIDELINES/POLICIES TO ADDRESS ADVERSE IMPACTS ON HR - DWC"
          />
          <Typography className={pdfPara}>
            {fetchData("guidelinesPolicies")}
          </Typography>

          <Typography className={pdfTitle}>
            ADVERSE IMPACTS -
            {reportData?.find((item) => item.form.name === "adverseImpacts")
              ?.otherInformationDetails?.length > 0
              ? "YES"
              : "NO"}
          </Typography>

          <FormControlLabel
            className={checkBoxTitle}
            control={
              <Checkbox
                name="assessment"
                onChange={handleCheckbox}
                checked={selectedValues?.assessment || false}
              />
            }
            label="Assessment IMPACT DESCRIPTION"
          />
          <Typography className={pdfPara} component="pre">
            {fetchData(
              "adverseImpacts",
              "d5170633-48dc-4c45-9271-225e662fecd3"
            )}
          </Typography>

          <FormControlLabel
            className={checkBoxTitle}
            control={
              <Checkbox
                name="Plan"
                onChange={handleCheckbox}
                checked={selectedValues?.Plan || false}
              />
            }
            label="Plan To Cease & Mitigate Risk"
          />
          <Typography className={pdfPara} component="pre">
            {fetchData(
              "adverseImpacts",
              "0b8a1c46-da40-4fc0-8830-7cccbe927b5c"
            )}
          </Typography>

          <FormControlLabel
            className={checkBoxTitle}
            control={
              <Checkbox
                name="results"
                onChange={handleCheckbox}
                checked={selectedValues?.results || false}
              />
            }
            label="Results Or Expected Results"
          />
          <Typography className={pdfPara} component="pre">
            {fetchData(
              "adverseImpacts",
              "b7823d53-9ffc-4102-bb4d-6d9dc02c148d"
            )}
          </Typography>
        </div>

        {/* ***  report View  *** */}

        <div style={{ display: "none" }}>
          <div ref={componentRef} style={{ margin: "80px" }}>
            <div className={captionTitleContainer}>
              <Typography className={captionTitle}>
                Transparency Act Report
              </Typography>
            </div>

            {selectedValues.organisationStructure && (
              <>
                <Typography className={pdfTitle}>
                  ORGANISATION STRUCTURE
                </Typography>
                <Typography className={pdfPara}>
                  {fetchData("OrganizationStructure")}
                </Typography>
              </>
            )}

            {selectedValues.areasOfOperation && (
              <>
                <Typography className={pdfTitle}>AREAS OF OPERATION</Typography>
                <Typography className={pdfPara}>
                  {fetchData("areasOfOperation")}
                </Typography>
              </>
            )}

            {selectedValues.guildelinesPolicies && (
              <>
                <Typography className={pdfTitle}>
                  GUIDELINES/POLICIES TO ADDRESS ADVERSE IMPACTS ON HR - DWC
                </Typography>
                <Typography className={pdfPara}>
                  {fetchData("guidelinesPolicies")}
                </Typography>
              </>
            )}

            <Typography className={pdfTitle}>
              ADVERSE IMPACTS -{" "}
              {reportData?.find((item) => item.form.name === "adverseImpacts")
                ?.otherInformationDetails?.length > 0
                ? "YES"
                : "NO"}
            </Typography>

            {selectedValues.assessment && (
              <>
                <Typography className={pdfTitle}>
                  Assessment Impact Description
                </Typography>
                <Typography className={pdfPara} component="pre">
                  {fetchData(
                    "adverseImpacts",
                    "d5170633-48dc-4c45-9271-225e662fecd3"
                  )}
                </Typography>
              </>
            )}

            {selectedValues.Plan && (
              <>
                <Typography className={pdfTitle}>
                  Plan To Cease & Mitigate Risk
                </Typography>
                <Typography className={pdfPara} component="pre">
                  {fetchData(
                    "adverseImpacts",
                    "0b8a1c46-da40-4fc0-8830-7cccbe927b5c"
                  )}
                </Typography>
              </>
            )}

            {selectedValues.results && (
              <>
                <Typography className={pdfTitle}>
                  Results Or Expected Results
                </Typography>
                <Typography className={pdfPara} component="pre">
                  {fetchData(
                    "adverseImpacts",
                    "b7823d53-9ffc-4102-bb4d-6d9dc02c148d"
                  )}
                </Typography>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
