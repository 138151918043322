const labels = {
  value: "Value",
  typeLabel: "Query",
};

export const companyFilter = {
  name: {
    label: labels.value,
    type: "text",
    name: "name",
  },
  emailId: {
    label: labels.value,
    type: "text",
    name: "emailId",
  },
  mobileNo: {
    label: labels.value,
    type: "number",
    name: "mobileNo",
  },
  status: {
    label: labels.value,
    type: "text",
    name: "status",
  },
};

export const companyFilterInitial = {
  name: "",
  emailId: "",
  mobileNo: "",
  status: "",
  nameType: "",
  emailIdType: "",
  mobileNoType: "",
  statusType: "",
};

export const invoiceFilter = {
  invoiceNo: {
    label: labels.value,
    type: "text",
    name: "invoiceNo",
  },
  paymentStatus: {
    label: labels.value,
    type: "text",
    name: "paymentStatusId",
  },
  invoiceNoType: {
    label: labels.typeLabel,
    type: "text",
    name: "invoiceNoType",
  },
  paymentStatusType: {
    label: labels.typeLabel,
    type: "text",
    name: "paymentStatusType",
  },
};

export const invoiceFilterInitial = {
  invoiceNo: "",
  paymentStatusId: "",
  invoiceNoType: "",
  paymentStatusType: "",
};

export const filterType = {
  nameType: {
    label: labels.typeLabel,
    type: "text",
    name: "nameType",
  },
  emailIdType: {
    label: labels.typeLabel,
    type: "text",
    name: "emailIdType",
  },
  mobileNoType: {
    label: labels.typeLabel,
    type: "text",
    name: "mobileNoType",
  },
  statusType: {
    label: labels.typeLabel,
    type: "text",
    name: "statusType",
  },
};

export const filterTypeSeeds = [
  { id: "eq", name: "equal" },
  { id: "ne", name: "non-equal" },
  { id: "in", name: "in" },
  { id: "notIn", name: "not-in" },
];

export const invoiceSetupFilter = {
  invoiceNo: {
    label: labels.value,
    type: "text",
    name: "invoiceNo",
  },
  name: {
    label: labels.value,
    type: "text",
    name: "name",
  },
  paymentStatus: {
    label: labels.value,
    type: "text",
    name: "paymentStatusId",
  },
  invoiceNoType: {
    label: labels.typeLabel,
    type: "text",
    name: "invoiceNoType",
  },
  nameType: {
    label: labels.typeLabel,
    type: "text",
    name: "nameType",
  },
  paymentStatusType: {
    label: labels.typeLabel,
    type: "text",
    name: "paymentStatusIdType",
  },
};

export const invoiceSetupFilterInitial = {
  invoiceNo: "",
  name: "",
  paymentStatusId: "",
  nameType: "",
  invoiceNoType: "",
  paymentStatusIdType: "",
};
