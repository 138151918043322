import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  fontFamily: "'Poppins', Arial, sans-serif",
  typography: {
    // h6: {
    //   fontWeight: 900,
    // },
    // subtitle1: {
    //   fontWeight: 900,
    // },
    // subtitle: {
    //   margin: "10px 0",
    //   lineHeight: "1.5",
    //   fontSize: "18px",
    // },
  },
  //   palette: {
  //     secondary: {
  //       main: "#021256 ",
  //     },
  //   },

  components: {
    // MuiTableCell: {
    //   styleOverrides: {
    //     root: {
    //       fontWeight: 900,
    //       background: "transparent",
    //       border: " 0.1px solid rgba(224,224,224,1) ",
    //       fontSize: "1rem ",
    //       padding: "0.5rem ",
    //     },
    //     head: {
    //       background: "#e7ebf0 ",
    //       border: " 0.1px solid rgb(211 208 208) ",
    //       textAlign: "center ",
    //     },
    //   },
    // },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          padding: "12px 24px",
          margin: "10px ",
          color: "black",
          textAlign: "left",
          display: "block",
        },
      },
    },

    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          background: "white",
          width: "100%",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 14,
        },
      },
    },
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       marginLeft: 10,
    //       paddingBottom: 10,
    //       "& input[type=number]::-webkit-inner-spin-button": {
    //         "-webkit-appearance": "none",
    //         // webkitApperance: " none",
    //         margin: 0,
    //       },
    //     },
    //   },
    // },
    MuiButton: {
      styleOverrides: {
        root: {
          margin: "0 8px ",
        },
      },
    },

    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          flexBasis: "auto",
        },
      },
    },
  },
});
