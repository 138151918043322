import moment from "moment";
import { EmailPopover } from "../../components/reusable";
import { ROUTE_PATHS } from "../../routes/routePath";

export const emailResponsesListColumn = () => [
  {
    Header: "Email Name",
    accessor: "scheduler.name",
    sticky: "left",
    id: 1,
    width: 300,
    Cell: (props) => (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {props.value}
        <EmailPopover
          values={props}
          toShow={{
            response: true,
          }}
          paths={{
            viewResponse: ROUTE_PATHS.VIEW_RESPONSES,
          }}
        />
      </div>
    ),
  },
  {
    Header: "Sender Name",
    accessor: "scheduler.senderName",
    id: 2,
    width: 200,
  },
  {
    Header: "Sent Date & time",
    accessor: "email.createdAt",
    id: 3,
    width: 200,
    Cell: (props) =>
      ` ${moment(props.value).format("L")} , ${moment(props.value).format(
        "LT"
      )}`,
  },
  {
    Header: "Recipient Mail",
    accessor: "email.fromAddresses",
    id: 4,
    width: 200,
  },

  {
    Header: "Text",
    accessor: "dataCollections.formSetupData.dataCollection",
    id: 8,
  },
  {
    Header: "Document Upload",
    accessor: "dataCollections.formSetupData.documentUpload",
    id: 9,
    width: 200,
  },
];

export const dataCollectionRawData = {
  name: "org",
  senderName: "sender",
  sentDateTime: "12",
  recipientMail: "Lorem",
  submitted: "Yes",
  submittedDateTime: "12",
  dataRequired: "Yes",
  text: "text",
  doscumentUpload: "Yes",
};
