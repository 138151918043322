import { Grid, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { CustomToggleButton } from "../../reusable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";
// import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

// import Cookies from "universal-cookie";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  accordionContainer: {
    width: "320px",
    height: "calc(100vh - 64px)",
    position: "fixed",
    overflowY: "scroll",
    backgroundColor: "white",
    borderRight: "2px solid #7394c6",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent !important",
      width: "0px !important",
    },
  },
  formContainer: {
    width: "calc(98vw - 330px)",
    position: "absolute",
    left: "330px",
    padding: "0 20px 20px 20px",
    boxSizing: "border-box",
  },

  titleContainer: {
    display: "flex",
    alignItems: "center",
    margin: "20px 0",
  },
  arrowBtn: {
    color: "black !important",
    marginRight: "10px !important",
  },
  title: {
    font: "normal normal medium 24px/16px Rubik !important",
    fontWeight: "bold !important",
  },
  subTitle: {
    fontWeight: "bold !important",
    fontSize: "18px !important",
    marginBottom: "10px !important",
  },

  gridItem: {
    display: "flex !important",
  },

  mailBox: {
    border: "1px solid  #707070",
    minHeight: "32px",
    borderRadius: "4px",
    color: "#898989",
    padding: "8px 16px",
    boxSizing: "border-box",
  },
  mailKey: {
    fontWeight: "600",
    marginTop: "8px",
  },
  saluation: {
    font: "normal normal normal 16px/19px Rubik",
    color: "#000000DE",
    margin: "32px 0",
  },
  mailContent: {
    font: "normal normal normal 16px/19px Rubik",
    marginBottom: "32px",
  },
}));

const EmailView = (props) => {
  const {
    root,
    accordionContainer,
    formContainer,
    titleContainer,
    arrowBtn,
    title,
    mailBox,
    mailKey,
    saluation,
    mailContent,
  } = useStyles();

  const navigate = useNavigate();
  // const cookies = new Cookies();
  // const userId = cookies.get("userId");
  const search = useLocation().search;
  const searchParam = new URLSearchParams(search);
  const editId = searchParam?.get("editId");
  const globalState = useSelector((state) => state);
  const { emailViewData } = globalState?.toastMessageStateReducer;
  const [formData, setFormData] = useState({});
  // const [financialYearInput, setFinancialYearInput] = React.useState([]);
  // const [topicNameValues, setTopicNameValues] = React.useState([]);

  console.log(formData);

  useEffect(() => {
    // getAllParamter().then((res) => {
    //   let inputValues = res.data.map((item) => ({
    //     id: item.id,
    //     name: `${moment(item.year.split("-")[0]).format("ll")} - ${moment(
    //       item.year.split("-")[1]
    //     ).format("ll")}`,
    //   }));
    //   setFinancialYearInput(inputValues);
    // });

    // getAllDataForms().then((res) => setTopicNameValues(res.data.rows));

    setFormData(emailViewData);
  }, [emailViewData]);

  return (
    <div className={root}>
      <div className={accordionContainer}>
        <CustomToggleButton />
      </div>
      <div className={formContainer}>
        <div className={titleContainer}>
          <IconButton className={arrowBtn} onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" className={title}>
            {editId ? "View Responses" : "Email View"}
          </Typography>
        </div>

        <Grid container columnSpacing={3} rowSpacing={3}>
          <Grid item xs={1} className={mailKey}>
            <div className={mailKey}>From</div>
          </Grid>
          <Grid item xs={11}>
            <div className={mailBox}>
              {`${formData?.senderName} <${formData.senderEmail}>`}
            </div>
          </Grid>
          <Grid item xs={1} className={mailKey}>
            <div className={mailKey}>To</div>
          </Grid>
          <Grid item xs={11}>
            <div className={mailBox}>
              {formData?.emails &&
                formData?.emails?.map((item) => {
                  return typeof item === "string"
                    ? `<${item}>, `
                    : `${item.name} <${item.email}>, `;
                })}
            </div>
          </Grid>
          <Grid item xs={1} className={mailKey}>
            <div className={mailKey}>Subject</div>
          </Grid>
          <Grid item xs={11}>
            <div className={mailBox}>{formData?.name}</div>
          </Grid>
        </Grid>

        <div className={saluation}>{formData?.emailSalutation}</div>

        <div className={mailContent}>{formData?.emailContent}</div>

        <div className={mailContent}>
          {/* {formData?.emailClosing} */}
          {formData?.emailClosing &&
            formData?.emailClosing
              ?.split(",")
              .map((item, index) => <div key={index}>{`${item},`}</div>)}
        </div>
      </div>
    </div>
  );
};

export default EmailView;
