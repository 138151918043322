import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { makeStyles } from "@mui/styles";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../routes/routePath";
import { getUsersDetailsById } from "../../api/api";

const useStyles = makeStyles((theme) => ({
  stepperContainer: {
    minWidth: 250,
    backgroundColor: "white",
    padding: 20,
    marginRight: "20px",
    borderRadius: 4,
    boxShadow: "0px 0px 10px #0000001A",
  },
}));

export function CustomStepper(props) {
  const { steps, activeStep, handleNext, handleBack } = props;
  const { stepperContainer } = useStyles();

  const navigate = useNavigate();
  const search = useLocation().search;
  const searchParam = new URLSearchParams(search);
  const editId = searchParam?.get("editId");
  const isViewMode = searchParam?.get("isViewMode");
  const [userId, setUserId] = React.useState("");

  React.useEffect(() => {
    if (editId) {
      getUsersDetailsById(editId).then((response) => {
        setUserId(response.data.id);
      });
    }
  }, [editId]);

  return (
    <Box className={stepperContainer}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => {
          return (
            <Step
              key={step.label}
              onClick={
                () =>
                  index === 0
                    ? userId && (handleBack(), navigate(-1))
                    : editId &&
                      (navigate({
                        pathname: ROUTE_PATHS.COMPANY_USER_ACCESS,
                        search: `?${createSearchParams({
                          userId: JSON.stringify(userId),
                          isViewMode: isViewMode ? isViewMode : false,
                        })}`,
                      }),
                      handleNext())
                // index === 0 ? editId && handleNext() : editId && handleBack()
                // index === 0 ? console.log("one") : console.log("two")
              }
            >
              <StepLabel>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
