import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import { useNavigate } from "react-router-dom";
import { invoiceDownload, userDownloadDetails } from "../../api/api";
import { downloadTableData } from "../../utils/downloadFile";
import CustomSearchField from "./CustomSearchField";
import CompanyUserFilter from "./filter/CompanyUserFilter";
import InvoiceFilter from "./filter/InvoiceFilter";

const useStyles = makeStyles((theme) => ({
  container: {
    height: 64,
    background: "#021256 0% 0% no-repeat padding-box",
    // borderRadius: "0px 40px 0px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  root: {
    height: 85,
    background: "#E46D35 0% 0% no-repeat padding-box !important",
  },
  contianerAlign: {
    width: "90%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    color: "white",
  },
  iconsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  topbarIcons: {
    color: "white",
    backgroundColor: "#E46D35",
    padding: 6,
    borderRadius: 4,
  },
  downloadBarIcons: {
    color: "white",
    backgroundColor: "#E46D35",
    padding: 6,
    borderRadius: 4,
  },
}));

export function Topbar(props) {
  const {
    root,
    container,
    title,
    contianerAlign,
    topbarIcons,
    iconsContainer,
    downloadBarIcons,
  } = useStyles();
  const [downloadData, setDownloadData] = useState();

  const navigate = useNavigate();

  const {
    label,
    searchField,
    filter,
    download,
    newForm,
    pathName,
    customerId,
    userTypeId,
  } = props;

  useEffect(() => {
    if (label === "Invoices")
      invoiceDownload(customerId).then(
        (response) => {
          setDownloadData(response.data);
        },
        (response) => {
          console.log(response, "error");
        }
      );
    else if (userTypeId !== undefined) {
      userDownloadDetails(userTypeId || customerId).then(
        (response) => {
          setDownloadData(response.data);
        },
        (response) => {
          console.log(response, "error");
        }
      );
    }
  }, [userTypeId, label, customerId]);

  const downLoadRecord = () => {
    downloadTableData(downloadData, label);
  };

  return (
    <div className={root}>
      <div className={container}>
        <div className={contianerAlign}>
          <Typography variant="h6" className={title}>
            {label}
          </Typography>
          <div className={iconsContainer}>
            {searchField && <CustomSearchField />}
            {filter && (
              <IconButton>
                {pathName.split("/")[1] === "companyUsersForm" ? (
                  <CompanyUserFilter />
                ) : pathName.split("/")[1] === "myUsers" ? (
                  <CompanyUserFilter />
                ) : (
                  <InvoiceFilter />
                )}
              </IconButton>
            )}
            {newForm && (
              <IconButton onClick={() => navigate(pathName)}>
                <AddIcon className={topbarIcons} />
              </IconButton>
            )}
            {download && (
              <IconButton onClick={downLoadRecord}>
                <DownloadIcon className={downloadBarIcons} />
              </IconButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
