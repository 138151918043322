/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import CustomReactTable from "../../reusable/CustomReactTable";
import {
  createQueryParams,
  parseQueryParams,
} from "../../../utils/queryParams";
import { CustomToggleButton } from "../../reusable";
import { Box, Button, Typography } from "@mui/material";
// import DownloadIcon from "@mui/icons-material/Download";
import CustomSearchField from "../../reusable/CustomSearchField";
import { lookup } from "../common/lookup";
import { emailListStyle } from "../../../styles";
import { useDispatch } from "react-redux";
import {
  dataCollectionInitialValues,
  emailCreationFormInitial,
} from "../../../constant";
import EmailMasterFilter from "../../reusable/filter/EmailMasterFilter";
import EmailCreationFilter from "../../reusable/filter/EmailCreationFilter";
import { deleteEmailMaster, getAllParamter } from "../../../api/api";
// import moment from "moment";
import {
  emailViewData,
  toastMessageAction,
  viewFormData,
} from "../../../redux/action";
import DataCollectionFilter from "../../reusable/filter/DataCollectionFilter";
import EmailResponsesFilter from "../../reusable/filter/EmailResponsesFilter";
import { DropZoneField } from "../../reusable/DropZoneField";

function EmailList() {
  const {
    tableContainer,
    root,
    accordionContainer,
    iconsContainer,
    topbarContainer,
    bulkUploadContainer,
    title,
    newButton,
    filterBtn,
  } = emailListStyle();

  let { screen } = useParams();
  let { name, tableColumn, tableDataApi, searchApi, newFormPath, listPath } =
    lookup[screen];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const custId = cookies.get("custId");
  const userTypeId = cookies.get("userTypeId");
  const location = useLocation();
  const pathName = location.pathname;
  const search = location.search;
  const searchParam = useMemo(() => new URLSearchParams(search), [search]);
  const searchdata = JSON.parse(searchParam?.get("search"));
  const filterdata = useMemo(
    () => JSON.parse(searchParam?.get("filter")),
    [searchParam]
  );
  const pageParams = parseQueryParams(location?.search);
  const pageSize = parseInt(pageParams?.pageSize) || 10;
  const currentPage = parseInt(pageParams?.currentPage) || 1;
  const [count, setCount] = useState(0);
  const filterLength =
    filterdata !== null ? Object.keys(filterdata)?.length : 0;
  const [tableData, setTableData] = useState([]);
  const [status, setStatus] = useState(false);
  // const [downloadData, setDownloadData] = useState();
  const [financialYearInput, setFinancialYearInput] = React.useState([]);

  const getTableData = useCallback(() => {
    const offset = pageSize * (currentPage - 1);

    let filtervalue = {
      filter: {
        userTypeId: { eq: userTypeId },
      },
    };
    if (filterLength > 0) {
      filtervalue = {
        filter: {
          userTypeId: { eq: userTypeId },
          ...filterdata,
        },
      };
    }

    tableDataApi(filterdata ? filtervalue : {}, pageSize, offset)
      .then((res) => {
        setCount(res.data.count);
        setTableData(res.data.rows);
      })
      .catch((res) => console.log(res));

    if (searchdata) {
      searchApi(searchdata, pageSize, offset)
        .then((res) => {
          setCount(res.data.count);
          setTableData(res.data.rows);
          console.log(res.data.rows);
        })
        .catch((res) => console.log(res));
    }
  }, [screen, searchdata, filterdata, pageSize, currentPage]);

  useEffect(() => {
    getTableData();
  }, [getTableData, pathName, screen, searchdata, filterdata]);

  useEffect(() => {
    getAllParamter().then((res) => {
      let filterInput = res.data.filter((item) => item.customerId === custId);
      let inputValues = filterInput.map((item) => ({
        id: item.id,
        accessor: "id",
        // name: `${moment(item.year.split("-")[0]).format("ll")} - ${moment(
        //   item.year.split("-")[1]
        // ).format("ll")}`,
        name: item.year,
      }));
      setFinancialYearInput(inputValues);
    });
  }, [custId]);

  const onChangePageSize = (size) => {
    const newParams = createQueryParams({
      pageSize: size,
      currentPage: size === pageSize ? currentPage : 1,
      ...(searchdata && { search: searchdata }),
    });
    navigate(`${listPath}?${newParams}`);
  };

  //to change page number (set offset)
  const onPageNumberChange = (page) => {
    const newParams = createQueryParams({
      pageSize,
      currentPage: page,
      ...(searchdata && { search: searchdata }),
    });
    navigate(`${listPath}?${newParams}`);
  };

  // const downLoadRecord = () => {
  //   downloadTableData(downloadData, "Company Admin Dashboard");
  // };

  const deleteEmail = (data) => {
    const id = data?.row?.original?.id;

    if (id) {
      deleteEmailMaster(id)
        .then((res) => {
          dispatch(
            toastMessageAction({
              severity: "success",
              message: "Deleted Successfully",
              messageStatus: true,
            })
          );
          getTableData();
        })
        .catch((res) => console.log(res));
    }
  };

  const showFilter = () => {
    if (screen === "emailMaster") {
      return <EmailMasterFilter listPath={listPath} />;
    }
    if (screen === "emailCreation") {
      return <EmailCreationFilter listPath={listPath} />;
    }
    if (screen === "dataCollectionContent") {
      return <DataCollectionFilter listPath={listPath} />;
    }
    if (screen === "emailResponses") {
      return <EmailResponsesFilter listPath={listPath} />;
    }
  };

  return (
    <div className={root}>
      <div className={accordionContainer}>
        <CustomToggleButton />
      </div>
      <div className={tableContainer}>
        <div className={topbarContainer}>
          <Typography variant="h6" className={title}>
            {name}
          </Typography>
          <div className={iconsContainer}>
            <CustomSearchField />

            <Button>
              <span className={filterBtn}>{showFilter()}</span>
            </Button>

            {newFormPath && (
              <Button
                onClick={() => {
                  dispatch(
                    viewFormData({
                      ...dataCollectionInitialValues,
                    })
                  );
                  dispatch(
                    emailViewData({
                      ...emailCreationFormInitial,
                    })
                  );
                  navigate(`${newFormPath}`);
                }}
              >
                <span className={newButton}>NEW</span>
              </Button>
            )}

            {/* <IconButton onClick={downLoadRecord}>
              <DownloadIcon className={downloadBarIcons} />
            </IconButton> */}
          </div>
        </div>

        {screen === "emailMaster" ? (
          <Box className={bulkUploadContainer}>
            <DropZoneField getTableData={getTableData} />
          </Box>
        ) : (
          <></>
        )}

        <CustomReactTable
          columnData={tableColumn(
            financialYearInput,
            status,
            setStatus,
            deleteEmail
          )}
          rawData={tableData}
          // columnSize={true}
          // disableSort={true}
          disableRowSelection={true}
          disableColumnHiding={true}
          onChangePageSize={onChangePageSize}
          count={count}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageNumberChange={onPageNumberChange}
          style={{
            th: {
              color: "#0000008A",
              font: "normal normal bold 14px/19px Roboto",
              display: "flex !important",
              alignItems: "center",
              height: "64px !important",
              backgroundColor: "#D2E1FC",
            },
            body: {
              position: "relative",
              zIndex: "0",
              backgroundColor: "white",
              color: "#40403A",
              font: "normal normal bold 14px/19px Roboto",
            },
          }}
        />
      </div>
    </div>
  );
}

export default EmailList;
