import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { makeStyles } from "@mui/styles";
import { sidebarData } from "../../constant/sidebar";
import CustomPopper from "./Popper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { lookup } from "../user/common/lookup";
import Cookies from "universal-cookie";
import { getFormSeed, getUsersDetailsById } from "../../api/api";

const useStyles = makeStyles((theme) => ({
  toggleBtn: {
    borderRadius: "5px !important",
    border: "1px solid #1976d2 !important",
    font: "normal normal bold 14px/18px Montserrat ",
  },

  accordionContainer: {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
  },
  acitveToggleBtn: {
    backgroundColor: "#3ca2f3 !important",
    color: "white !important",
    borderRadius: "5px !important",
    border: "1px solid #1976d2 !important",
  },
}));

export function CustomToggleButton() {
  const [alignment, setAlignment] = React.useState("web");
  const [popperValues, setPopperValues] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [secName, setSecName] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const cookies = new Cookies();
  // const customerId = cookies.get("custId");
  const userId = cookies.get("userId");
  const userTypeName = cookies.get("userTypeName");
  const [formSeed, setFormSeed] = React.useState([]);
  const [userAccessDetails, setUserAccessDetails] = React.useState(null);
  const { toggleBtn } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;

  let { screen } = useParams();
  let sidebarIndex = lookup[screen]?.sidebarIndex;

  React.useEffect(() => {
    getFormSeed().then((res) => setFormSeed(res.data));
  }, []);

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleClick = (event, buttons, secValue) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
    setPopperValues(buttons);
    setSecName(secValue);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen((previousOpen) => !previousOpen);
  };

  React.useEffect(() => {
    if (userId) {
      getUsersDetailsById(userId)
        .then((res) => setUserAccessDetails(res.data.formAccess))
        .catch((res) => console.log(res));
    }
  }, [userId]);

  const renderButtons = (item) => {
    return (
      <ToggleButton
        value={item.value}
        className={toggleBtn}
        style={
          sidebarIndex === item.sidebarIndex
            ? {
                backgroundColor: "#2A5BA8",
                color: "white",
                font: "normal normal medium 14px/18px Montserrat",
              }
            : {}
        }
        key={item.id}
        onClick={(e) => {
          handleClick(e, item?.buttons, item.value);
        }}
      >
        {item.name}
      </ToggleButton>
    );
  };

  const getSecForm = (value) => {
    return formSeed.filter((item) => item.section === value);
  };

  return (
    <>
      <ToggleButtonGroup
        color="primary"
        orientation="vertical"
        value={alignment}
        exclusive
        onChange={handleChange}
      >
        <ToggleButton
          value={"home"}
          className={toggleBtn}
          style={
            pathName === "/homeScreen"
              ? {
                  backgroundColor: "#2A5BA8",
                  color: "white",
                  font: "normal normal medium 14px/18px Montserrat",
                }
              : {}
          }
          onClick={(e) => {
            navigate("/homeScreen");
          }}
        >
          {"Home"}
        </ToggleButton>
        {sidebarData.map((item) => {
          return userTypeName === "Company User"
            ? getSecForm(item.value).some((form) =>
                userAccessDetails?.find(
                  (item) => item.formId === form.id && item.allowed === true
                )
              ) && renderButtons(item)
            : renderButtons(item);
        })}
      </ToggleButtonGroup>
      <CustomPopper
        buttons={popperValues}
        secName={secName}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
    </>
  );
}
