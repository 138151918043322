/* eslint-disable array-callback-return */
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { useNavigate, createSearchParams } from "react-router-dom";
import { filterStyle } from "../../../styles/dashboard";
import { CustomButton, CustomTextField, DropdownField } from "..";
import {
  emailCrationFilter,
  emailCrationFilterInitial,
  emailCrationFilterType,
  filterTypeSeeds,
} from "../../../constant/emailFilterContent";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const EmailCreationFilter = ({ listPath }) => {
  const useStyles = filterStyle();

  const navigate = useNavigate();
  // let pathName = useLocation().pathname.split("/")[2];
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = async (data) => {
    let workData = { ...data };
    Object.keys(workData).map((item) => {
      if (!workData[item]) {
        delete workData[item];
      }
    });

    let filter = {
      date: {
        [workData.dateType]:
          workData.dateType === "in" || workData.dateType === "notIn"
            ? [...workData.date.split(",")]
            : workData.date,
      },
      senderName: {
        [workData?.senderNameType || "eq"]:
          workData.senderNameType === "in" ||
          workData.senderNameType === "notIn"
            ? [...workData.senderName.split(",")]
            : workData.senderName,
      },
      senderEmail: {
        [workData?.senderEmailType || "eq"]:
          workData.senderEmailType === "in" ||
          workData.senderEmailType === "notIn"
            ? [...workData.senderEmail.split(",")]
            : workData.senderEmail,
      },
      status: {
        [workData?.statusType || "eq"]:
          workData.statusType === "in" || workData.statusType === "notIn"
            ? [...workData.status.split(",")]
            : workData.status,
      },
    };

    Object.keys(filter).map((item) => {
      if (
        filter[item].eq === undefined &&
        filter[item].ne === undefined &&
        filter[item].in === undefined &&
        filter[item].notIn === undefined
      ) {
        delete filter[item];
      }
    });

    navigate({
      pathName: listPath,
      search: `?${createSearchParams({
        filter: JSON.stringify(filter),
      })}`,
    });
    setOpen(false);
    resetForm();
  };

  //to handle formik data
  const formik = useFormik({
    initialValues: emailCrationFilterInitial,
    onSubmit,
  });

  const handleClearFilter = () => {
    navigate({
      pathName: listPath,
    });
    setOpen(true);
    resetForm();
  };

  const { values, handleChange, resetForm, handleBlur } = formik;

  return (
    <div>
      <span onClick={handleOpen} className={useStyles.superAdminTopbarIcons}>
        <FilterListIcon style={{ marginRight: 10 }} />
        Filter
      </span>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={useStyles.title}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Filter
            </Typography>
            <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className={useStyles.invoiceTitle}>
              <Typography
                id="modal-modal-description"
                sx={{ p: 3, width: "150px" }}
              >
                Date
              </Typography>
              <DropdownField
                label={emailCrationFilterType.dateType.label}
                inputValues={filterTypeSeeds || []}
                name={emailCrationFilterType.dateType.name}
                onChange={handleChange}
                className={useStyles.titleContentStyle}
                value={values.dateType}
              />
              <CustomTextField
                label={emailCrationFilter.date.label}
                name={emailCrationFilter.date.name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.date}
                variant="outlined"
                logInField
                style={{ width: "250px" }}
              />
            </div>
            <div className={useStyles.invoiceTitle}>
              <Typography
                id="modal-modal-description"
                sx={{ p: 3, width: "150px" }}
              >
                Sender Name
              </Typography>
              <DropdownField
                label={emailCrationFilterType.senderNameType.label}
                inputValues={filterTypeSeeds || []}
                name={emailCrationFilterType.senderNameType.name}
                onChange={handleChange}
                className={useStyles.titleContentStyle}
                value={values.senderNameType}
              />
              <CustomTextField
                label={emailCrationFilter.senderName.label}
                name={emailCrationFilter.senderName.name}
                onChange={handleChange}
                value={values.senderName}
                variant="outlined"
                onBlur={handleBlur}
                logInField
                style={{ width: "250px" }}
              />
            </div>
            <div className={useStyles.invoiceTitle}>
              <Typography
                id="modal-modal-description"
                sx={{ p: 3, width: "150px" }}
              >
                Sender Email
              </Typography>
              <DropdownField
                label={emailCrationFilterType.senderEmailType.label}
                inputValues={filterTypeSeeds || []}
                name={emailCrationFilterType.senderEmailType.name}
                onChange={handleChange}
                value={values.senderEmailType}
                className={useStyles.titleContentStyle}
              />
              <CustomTextField
                label={emailCrationFilter.senderEmail.label}
                name={emailCrationFilter.senderEmail.name}
                onChange={handleChange}
                value={values.senderEmail}
                variant="outlined"
                onBlur={handleBlur}
                logInField
                style={{ width: "250px" }}
              />
            </div>

            <div className={useStyles.invoiceTitle}>
              <Typography
                id="modal-modal-description"
                sx={{ p: 3, width: "120px" }}
              >
                Status
              </Typography>
              <DropdownField
                label={emailCrationFilterType.statusType.label}
                inputValues={filterTypeSeeds || []}
                name={emailCrationFilterType.statusType.name}
                onChange={handleChange}
                className={useStyles.titleContentStyle}
                value={values.statusType}
                style={{ paddingLeft: 40 }}
              />
              <CustomTextField
                label={emailCrationFilter.status.label}
                name={emailCrationFilter.status.name}
                onChange={handleChange}
                value={values.status}
                variant="outlined"
                onBlur={handleBlur}
                logInField
                style={{ width: "250px" }}
              />
              {/* <DropdownField
                label={emailCrationFilter.status.label}
                inputValues={seeds || []}
                name={emailCrationFilter.status.name}
                onChange={handleChange}
                value={values.status}
                className={useStyles.content}
                variant="outlined"
              /> */}
            </div>
            <div className="buttonModal">
              <Typography
                id="modal-modal-description"
                sx={{ p: 3 }}
                onClick={handleClose}
                style={{ cursor: "pointer" }}
                className={useStyles.cancelButton}
              >
                CANCEL
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ p: 3 }}
                onClick={handleClearFilter}
                style={{ cursor: "pointer" }}
                className={useStyles.cancelButton}
              >
                CLEAR FILTER
              </Typography>
              <CustomButton
                variant="contained"
                label="Apply"
                className={useStyles.saveButton}
                type="submit"
              />
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default EmailCreationFilter;
