import * as React from "react";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormHelperText } from "@mui/material";
import HelperText from "./HelperText";

export function CustomRadioButton(props) {
  const {
    inputValues,
    name,
    onChange,
    onBlur,
    error,
    value,
    // defaultValue,
    labelStyle,
    label,
    helpertext,
    style,
    modalOnclick,
    vertical,
    helperPoper,
  } = props;

  return (
    <FormControl>
      <div
        style={
          vertical
            ? { display: "block" }
            : {
                display: "flex",
                alignItems: "center",
                marginTop: "20px ",
              }
        }
      >
        {/* marginLeft: "32px", */}
        <span
          style={
            labelStyle
              ? labelStyle
              : {
                  color: "#5C6BC0",
                  display: "flex",
                  alignItems: "center",
                }
          }
        >
          {label}
          {helperPoper && <HelperText position="right" text={helperPoper} />}
        </span>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          onChange={onChange}
          onBlur={onBlur}
          error={error}
          value={value || ""}
          style={style}
          row
        >
          {inputValues?.map((option, i) => (
            <FormControlLabel
              value={
                option?.accessor
                  ? option[option?.accessor]
                  : option.id || option
              }
              control={<Radio />}
              label={option.name || option}
              key={i}
              name={name}
              sx={vertical ? { marginRight: "32px" } : { marginLeft: "22px" }}
              onClick={() => modalOnclick && modalOnclick(option.name)}
            />
          ))}
        </RadioGroup>
      </div>
      <FormHelperText style={{ marginLeft: "32px", color: "#d33730" }}>
        {helpertext}
      </FormHelperText>
    </FormControl>
  );
}
