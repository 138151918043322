import { EditPopover } from "../components/reusable";

export const dashboardColumn = [
  {
    Header: "User name",
    accessor: "name",
    sticky: "left",
    id: 1,
  },
  {
    Header: "User email",
    accessor: "emailId",
    id: 2,
  },
  {
    Header: "User mobile",
    accessor: "mobileNo",
    id: 3,
  },
  {
    Header: "Status",
    accessor: "status.name",
    id: 4,
  },
];

export const invoiceDashboard = [
  {
    Header: "Customer Name",
    accessor: "customer.name",
    sticky: "left",
    id: 1,
    width: 300,
  },
  {
    Header: "Invoice No",
    accessor: "invoiceNo",
    id: 2,
    width: 200,
  },
  {
    Header: "Invoice Date",
    accessor: "invoiceDate",
    id: 3,
    width: 200,
  },
  {
    Header: "Amount",
    accessor: "amount",
    id: 4,
    width: 200,
  },
  {
    Header: "Payment Status",
    accessor: "paymentStatus.name",
    id: 5,
    width: 200,
  },
];

export const customerColumns = (status, setStatus) => {
  return [
    {
      Header: "Customer Name",
      accessor: "name",
      sticky: "left",
      id: 1,
      width: 300,
      Cell: (props) => {
        return (
          <div
            style={{
              display: "flex ",
              justifyContent: "space-between",
              alignItems: "center",
              width: "280px ",
            }}
          >
            <span> {props.value} </span>
            <EditPopover
              {...props}
              superAdmin={true}
              setStatus={setStatus}
              status={status}
            />
          </div>
        );
      },
    },
    {
      Header: "Customer Email",
      accessor: "emailId",
      id: 2,
      width: 350,
    },
    {
      Header: "Customer Phone",
      accessor: "mobileNo",
      id: 3,
      width: 300,
    },
    {
      Header: "Status",
      accessor: "status.name",
      id: 4,
      width: 180,
    },
  ];
};

export const invoiceSetupColumn = (status, setStatus, setMailSent) => {
  return [
    {
      Header: "Customer Name",
      accessor: "customer.name",
      sticky: "left",
      id: 1,
      width: 300,
      Cell: (props) => {
        return (
          <div
            style={{
              display: "flex ",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span> {props.value} </span>
            <EditPopover
              {...props}
              invoiceSetupList={true}
              status={status}
              setStatus={setStatus}
              setMailSent={setMailSent}
            />
          </div>
        );
      },
    },
    {
      Header: "Invoice No",
      accessor: "invoiceNo",
      id: 2,
      width: 200,
    },
    {
      Header: "Invoice Date",
      accessor: "invoiceDate",
      id: 3,
      width: 200,
    },
    {
      Header: "Amount",
      accessor: "amount",
      id: 4,
      width: 200,
    },
    {
      Header: "Payment Status",
      accessor: "paymentStatus.name",
      id: 5,
      width: 200,
    },
    {
      Header: "Payment Over Due",
      accessor: "pymtOverDue",
      Cell: (props) => {
        return <span> {props.value ? "Yes" : "No"} </span>;
      },
      id: 6,
      width: 200,
    },
    {
      Header: "Reminder Sent",
      accessor: "reminderEmailSent",
      Cell: (props) => {
        return <span> {props.value ? "Yes" : "No"} </span>;
      },
      id: 7,
      width: 250,
    },
  ];
};

export const invoicecolumn = [
  {
    Header: "Invoice No",
    accessor: "invoiceNo",
    sticky: "left",
    width: 280,
    Cell: (props) => {
      return (
        <div
          style={{
            display: "flex ",
            justifyContent: "space-between",
            alignItems: "center",
            width: "260px ",
          }}
        >
          <span> {props.value} </span>
          <EditPopover {...props} invoice={true} />
        </div>
      );
    },
  },
  {
    Header: "Invoice Date",
    accessor: "invoiceDate",
    width: 300,
  },
  {
    Header: "Amount",
    accessor: "amount",
    width: 250,
  },
  {
    Header: "Payment Status",
    accessor: "paymentStatus.name",
    width: 200,
  },
];

export const column = (status, setStatus) => {
  return [
    {
      Header: "User name",
      accessor: "name",
      sticky: "left",
      id: "1",
      width: 300,
      Cell: (props) => {
        return (
          <div
            style={{
              display: "flex ",
              justifyContent: "space-between",
              alignItems: "center",
              width: "280px ",
            }}
          >
            <span> {props.value} </span>
            <EditPopover
              {...props}
              customer={true}
              setStatus={setStatus}
              status={status}
            />
          </div>
        );
      },
    },
    {
      Header: "User email",
      accessor: "emailId",
      id: "2",
      width: 300,
    },
    {
      Header: "User mobile",
      accessor: "mobileNo",
      id: "3",
      width: 230,
    },
    {
      Header: "Status",
      accessor: "status.name",
      id: "4",
      width: 230,
    },
  ];
};

export const updateStatusColumn = [
  {
    Header: "Status",
    accessor: "status.name",
    id: 1,
  },
  {
    Header: "Date",
    accessor: "effectiveDate",
    id: 2,
  },
  {
    Header: "Reason",
    accessor: "reason",
    id: 3,
  },
];
