import React from "react";
import { InputLabel, MenuItem, Select, FormControl } from "@mui/material";

export function WidgetSelectField(props) {
  const { schema, value, onChange, options, readonly } = props;

  const [dynamicOptions, setDynamicOptions] = React.useState(
    options.enumOptions
  );

  React.useEffect(() => {
    schema.options && setDynamicOptions(schema.options);
  }, [schema.options]);

  return (
    <FormControl variant="outlined">
      <InputLabel id="demo-simple-select-filled-label">
        {schema.label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={value || ""}
        required={false}
        onChange={(event) => onChange(event.target.value)}
        disabled={readonly}
      >
        {dynamicOptions &&
          dynamicOptions.map((option, index) => {
            return (
              <MenuItem key={index} value={option.id || option.value}>
                {option.name || option.label || "No data Found"}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}
