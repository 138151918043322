/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import User from "../../asset/user.png";
import { FormControl, FormHelperText } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";

export function CustomUploadImage({
  name,
  label,
  error,
  url,
  touched,
  value,
  sytle,
  onChange,
  profileUrl,
  isViewMode,
  uploadFile,
}) {
  const [imgUrl, setImgUrl] = React.useState(url);
  const [fileName, setFileName] = React.useState("");
  const myRefname = useRef(null);
  const handleClick = (e) => {
    myRefname.current.click();
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setImgUrl(reader.result);
        onChange(name, event.target.files[0]);
        setFileName(event.target.files[0].name);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  useEffect(() => {
    if (typeof value === "string") setFileName(value);
  }, [value]);

  useEffect(() => {
    setImgUrl(url);
  }, [url]);

  return (
    <FormControl fullWidth>
      <input
        ref={myRefname}
        className="hiddenFile"
        name={name}
        type={"file"}
        error={touched && error}
        onChange={onImageChange}
        helperText={touched && error}
        label={label}
        disabled={isViewMode}
      />
      <div
        onClick={(e) => handleClick(e)}
        className="fileInputHolder"
        sytle={sytle}
      >
        {uploadFile ? (
          <FileUploadIcon style={{ marginRight: 10 }} />
        ) : (
          <img
            src={imgUrl || profileUrl || User}
            className="userImg"
            alt=""
          ></img>
        )}
        <span style={{ color: "grey" }}>{fileName || label}</span>
      </div>
      <FormHelperText style={{ color: "#d32f2f" }}>{error}</FormHelperText>
    </FormControl>
  );
}
