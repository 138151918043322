import { apiRoutes } from "./apiPath";
import { appApi } from "./config";

export const loginApi = (value) => {
  return appApi.post(`${apiRoutes.LOG_IN_API}`, value);
};

export const forgetPasswordApi = (value) => {
  return appApi.put(`${apiRoutes.FORGET_PASSWORD}`, value);
};

export const resetPasswordApi = (data) => {
  return appApi.put(`${apiRoutes.RESET_PASSWORD}`, data);
};

////// Super Admin   &   company Admin
export const createUser = (data) => {
  return appApi.post(`${apiRoutes.CREATE_USER}`, data);
};

export const getUsersDetails = (data, pageSize, offset) => {
  return appApi.post(
    `${apiRoutes.GET_USERS_DETAILS}/${pageSize}/${offset}`,
    data
  );
};

export const getUsersDetailsSearch = (data, searchdata, pageSize, offset) => {
  return appApi.post(
    `${apiRoutes.GET_USERS_DETAILS}/${pageSize}/${offset}?search=${searchdata}`,
    data
  );
};

export const getAllStatus = () => {
  return appApi.get(`${apiRoutes.GET_ALL_STATUS}`);
};

export const updateUserStatus = (id, data) => {
  return appApi.put(`${apiRoutes.UPDATE_USER_STATUS}/${id}`, data);
};

export const getAllCustomerDetails = (data, pageSize, offset) => {
  return appApi.post(
    `${apiRoutes.GET_ALL_CUSTOMERS_DETAILS}/${pageSize}/${offset}`,
    data
  );
};

export const getAllCustomerDetailsSearch = (searchData, pageSize, offset) => {
  return appApi.post(
    `${apiRoutes.GET_ALL_CUSTOMERS_DETAILS}/${pageSize}/${offset}?search=${searchData}`
  );
};
export const getAllInvoiceDetails = (data, pageSize, offset) => {
  return appApi.post(
    `${apiRoutes.GET_ALL_INVOICES_DETAILS}/${pageSize}/${offset}`,
    data
  );
};

export const getAllInvoiceDetailsSearch = (
  data,
  searchdata,
  pageSize,
  offset
) => {
  return appApi.post(
    `${apiRoutes.GET_ALL_INVOICES_DETAILS}/${pageSize}/${offset}?search=${searchdata}`,
    data
  );
};

export const getPaymentStatus = () => {
  return appApi.get(`${apiRoutes.GET_PAYMENT_STATUS}`);
};

export const userAccessApi = (data) => {
  return appApi.post(`${apiRoutes.USER_ACCESS}`, data);
};

export const getCustomerDetailsById = (id) => {
  return appApi.get(`${apiRoutes.GET_CUSTOMERS_DETAILS_BY_ID}/${id}`);
};

export const updateCustomerDetails = (id, data) => {
  return appApi.put(`${apiRoutes.UPDATE_CUSTOMERS_DETAILS}/${id}`, data);
};

export const changePassword = (data) => {
  return appApi.put(`${apiRoutes.CHANGE_PASSWORD}`, data);
};

export const invoiceDownload = (id) => {
  return appApi.get(`${apiRoutes.INVOICES_DOWNLOAD}/${id}`);
};

export const customerDownloadDetails = () => {
  return appApi.get(`${apiRoutes.CUSTOMER_DOWNLOAD_DETAILS}`);
};

export const getFormDataByUser = (userId) => {
  return appApi.get(`${apiRoutes.GET_FORM_DATA_BY_USER}/${userId}`);
};

export const userDownloadDetails = (userTypeId) => {
  return appApi.get(`${apiRoutes.USER_DOWNLOAD_DETAILS}/${userTypeId}`);
};

export const createCustomerDetails = (data) => {
  return appApi.post(`${apiRoutes.CREATE_CUSTOMERS_DETAILS}`, data);
};

export const createInvoiceDetails = (data) => {
  return appApi.post(`${apiRoutes.CREATE_INVOICE_DETAILS}`, data);
};

export const updateInvoiceDetails = (id, data) => {
  return appApi.put(`${apiRoutes.UPDATE_INVOICE_DETAILS}/${id}`, data);
};
export const getCustomers = () => {
  return appApi.get(`${apiRoutes.GET_CUSTOMERS_ID}`);
};

export const updatePaymentStatus = (id) => {
  return appApi.post(`${apiRoutes.UPDATE_INVOICE_DETAILS}/${id}`);
};

export const getInvoiceDetailsById = (id) => {
  return appApi.get(`${apiRoutes.GET_INVOICE_DETAILS_BY_ID}/${id}`);
};

export const getInvoiceDownload = (id) => {
  return appApi.get(`${apiRoutes.GET_INVOICE_DOWNLOAD}/${id}`);
};

export const invoiceSetupDownload = () => {
  return appApi.get(`${apiRoutes.INVOICES_DOWNLOAD}`);
};

export const getAllCustomerDashboard = (pageSize, offset) => {
  return appApi.post(
    `${apiRoutes.GET_ALL_CUSTOMERS_DETAILS}/${pageSize}/${offset}`
  );
};

export const getGraphDetails = (data) => {
  return appApi.post(`${apiRoutes.GET_GRAPH_DETAILS}`, data);
};

export const getInvoiceGraphDetails = (data) => {
  return appApi.post(`${apiRoutes.GET_INVOICE_GRAPH_DETAILS}`, data);
};

export const getUserGraphDetails = (data) => {
  return appApi.post(`${apiRoutes.GET_USER_GRAPH_DETAILS}`, data);
};

export const sendReminder = (userTypeId, invoiceId) => {
  return appApi.put(`${apiRoutes.SEND_REMAINDER}/${userTypeId}/${invoiceId}`);
};

//////   USER
export const getUsersDetailsById = (id) => {
  return appApi.get(`${apiRoutes.GET_USERS_DETAILS_BY_ID}/${id}`);
};

export const updateUser = (id, data) => {
  console.log(id, data);
  return appApi.put(`${apiRoutes.UPDATE_USER}/${id}`, data);
};

// export const createInformationDetails = (value) => {
//   return appApi.post(`${apiRoutes.CREATE_INFORMATION_DETAILS}`, value);
// };

// export const getInformationDetails = (custId, interfaceName) => {
//   return appApi.get(
//     `${apiRoutes.GET_INFORMATION_DETAILS}/${custId}/${interfaceName}`
//   );
// };

// export const deleteInformationDetails = (id) => {
//   return appApi.delete(`${apiRoutes.DELETE_INFORMATION_DETAILS}/${id}`);
// };

// export const createSupportingData = (value) => {
//   return appApi.post(`${apiRoutes.CREATE_SUPPORTING_DATA}`, value);
// };

// export const getSupportingData = (custId, interfaceName) => {
//   return appApi.get(
//     `${apiRoutes.GET_SUPPORTING_DATA}/${custId}/${interfaceName}`
//   );
// };

// export const deleteSupportingData = (id) => {
//   return appApi.delete(`${apiRoutes.DELETE_SUPPORTING_DATA}/${id}`);
// };

// export const createAuditlog = (value) => {
//   return appApi.post(`${apiRoutes.CREATE_AUDITLOG}`, value);
// };

// export const getAuditlog = (custId, interfaceName) => {
//   return appApi.get(`${apiRoutes.GET_AUDITLOG}/${custId}/${interfaceName}`);
// };

///// email creation
// export const createEmailMaster = (value) => {
//   return appApi.post(`${apiRoutes.CREATE_EMAILMASTER}`, value);
// };

// export const getEmailMaster = (userId, custId, pageSize, offset, value) => {
//   return appApi.post(
//     `${apiRoutes.GET_EMAILMASTER}/${userId}/${custId}/${pageSize}/${offset}`,
//     value
//   );
// };

// export const getEmailMasterSearch = (
//   userId,
//   custId,
//   pageSize,
//   offset,
//   searchData,
//   value
// ) => {
//   return appApi.post(
//     `${apiRoutes.GET_EMAILMASTER}/${userId}/${custId}/${pageSize}/${offset}?search=${searchData}`,
//     value
//   );
// };

// export const updateEmailMaster = (id, data) => {
//   return appApi.put(`${apiRoutes.UPDATE_EMAILMASTER}/${id}`, data);
// };

// export const createEmailCreation = (value) => {
//   return appApi.post(`${apiRoutes.CREATE_EMAILCREATION}`, value);
// };

// export const getEmailCreation = (userId, custId, pageSize, offset, value) => {
//   return appApi.post(
//     `${apiRoutes.GET_EMAILCREATION}/${custId}/${pageSize}/${offset}`,
//     value
//   );
// };

// export const getEmailCreationSearch = (
//   userId,
//   custId,
//   pageSize,
//   offset,
//   searchData
// ) => {
//   return appApi.post(
//     `${apiRoutes.GET_EMAILCREATION}/${custId}/${pageSize}/${offset}?search=${searchData}`
//   );
// };

// export const updateEmailCreation = (id, data) => {
//   return appApi.put(`${apiRoutes.UPDATE_EMAILCREATION}/${id}`, data);
// };

export const downlaodEmailMaster = (custId) => {
  return appApi.get(`${apiRoutes.DOWNLOAD_EMAILMASTER}/${custId}`);
};

export const downlaodEmailCreation = (custId) => {
  return appApi.get(`${apiRoutes.DOWNLOAD_EMAILCREATION}/${custId}`);
};

// new api

export const createParameter = (value) => {
  return appApi.post(`${apiRoutes.CREATE_PARAMETER}`, value);
};

export const getParamterById = (id) => {
  return appApi.get(`${apiRoutes.GET_PARAMETER_BY_ID}/${id}`);
};

export const getAllParamter = () => {
  return appApi.get(`${apiRoutes.GET_ALL_PARAMETER}`);
};

export const updateParameter = (id, data) => {
  return appApi.put(`${apiRoutes.UPDATE_PARAMETER}/${id}`, data);
};

export const getFormSeed = () => {
  return appApi.get(`${apiRoutes.GET_FORM_SEEDS}`);
};

export const getAdverseImpactSectionSeed = () => {
  return appApi.get(`${apiRoutes.GET_ADVERSE_IMAPCT_SECTION_SEEDS}`);
};

export const createAccess = (userId, value) => {
  return appApi.put(`${apiRoutes.CREATE_USER_ACESSS}/${userId}`, value);
};

// user
export const getFormById = (formId, yearId) => {
  return appApi.get(`${apiRoutes.GET_FORM}/${formId}/${yearId}`);
};

export const updateFormById = (formId, yearId, value) => {
  return appApi.post(`${apiRoutes.UPDATE_FORM}/${formId}/${yearId}`, value);
};

export const createInformationDetails = (formDataId, value) => {
  return appApi.post(
    `${apiRoutes.CREATE_INFORMATION_DETAILS}/${formDataId}`,
    value
  );
};

export const getInformationDetails = (formDataId) => {
  return appApi.get(`${apiRoutes.GET_INFORMATION_DETAILS}/${formDataId}`);
};

export const deleteInformationDetails = (id) => {
  return appApi.delete(`${apiRoutes.DELETE_INFORMATION_DETAILS}/${id}`);
};

export const createSupportingData = (formDataId, value) => {
  return appApi.post(
    `${apiRoutes.CREATE_SUPPORTING_DATA}/${formDataId}`,
    value
  );
};

export const getSupportingData = (formDataId) => {
  return appApi.get(`${apiRoutes.GET_SUPPORTING_DATA}/${formDataId}`);
};

export const deleteSupportingData = (id) => {
  return appApi.delete(`${apiRoutes.DELETE_SUPPORTING_DATA}/${id}`);
};

export const createOtherInformationDetails = (formDataId, value) => {
  return appApi.post(
    `${apiRoutes.CREATE_OTHERINFORMATION_DETAILS}/${formDataId}`,
    value
  );
};

export const getOtherInformationDetails = (formDataId) => {
  return appApi.get(`${apiRoutes.GET_OTHERINFORMATION_DETAILS}/${formDataId}`);
};

export const deleteOtherInformationDetails = (id) => {
  return appApi.delete(`${apiRoutes.DELETE_OTHERINFORMATION_DETAILS}/${id}`);
};

export const createAuditlog = (formDataId, value) => {
  return appApi.post(`${apiRoutes.CREATE_AUDITLOG}/${formDataId}`, value);
};

export const getAuditlog = (formDataId) => {
  return appApi.get(`${apiRoutes.GET_AUDITLOG}/${formDataId}`);
};

//download
export const downloadFileByLink = (type, id) => {
  return appApi.get(`${apiRoutes.DOWNLOAD_FILE}/${type}/${id}`);
};

// ADVERSE IMPACT
export const createAdverseImpact = (formDataId, value) => {
  return appApi.post(`${apiRoutes.CREATE_ADVERSE_IMPACT}/${formDataId}`, value);
};

export const getAdverseImpact = (formDataId) => {
  return appApi.get(`${apiRoutes.GET_ADVERSE_IMPACT}/${formDataId}`);
};

export const deleteAdverseImpact = (id) => {
  return appApi.delete(`${apiRoutes.DELETE_ADVERSE_IMPACT}/${id}`);
};

// email master
export const createEmailMaster = (value) => {
  return appApi.post(`${apiRoutes.CREATE_EMAILMASTER}`, value);
};

export const deleteEmailMaster = (id) => {
  return appApi.delete(`${apiRoutes.DELETE_EMAILMASTER}/${id}`);
};

export const getEmailMaster = (value, pageSize, offset) => {
  return appApi.post(
    `${apiRoutes.GET_EMAILMASTER}/${pageSize}/${offset}`,
    value
  );
};

export const getEmailMasterSearch = (searchData, pageSize, offset) => {
  return appApi.post(
    `${apiRoutes.GET_EMAILMASTER}/${pageSize}/${offset}?search=${searchData}`
  );
};

export const updateEmailMaster = (id, data) => {
  return appApi.put(`${apiRoutes.UPDATE_EMAILMASTER}/${id}`, data);
};

export const getEmailMasterById = (editId) => {
  return appApi.get(`${apiRoutes.GET_EMAILMASTER_BY_ID}/${editId}`);
};

// data collection
export const getAllDataForms = (value, pageSize, offset) => {
  return appApi.post(
    `${apiRoutes.GET_ALL_DATAFORMS}/${pageSize}/${offset}`,
    value
  );
};

export const createDataCollection = (value) => {
  return appApi.post(`${apiRoutes.CREATE_DATAFORM_COLLECTION}`, value);
};

export const getDataformById = (editId) => {
  return appApi.get(`${apiRoutes.GET_DATAFORM_BY_ID}/${editId}`);
};

export const updateDataformCollection = (id, data) => {
  return appApi.put(`${apiRoutes.UPDATE_DATAFORM_COLLECTION}/${id}`, data);
};

export const getAllDataFormsSearch = (searchData, pageSize, offset) => {
  return appApi.post(
    `${apiRoutes.GET_ALL_DATAFORMS}/${pageSize}/${offset}?search=${searchData}`
  );
};

// email creation
export const createEmailCreation = (value) => {
  return appApi.post(`${apiRoutes.CREATE_EMAILCREATION}`, value);
};

export const getEmailCreation = (value, pageSize, offset) => {
  return appApi.post(
    `${apiRoutes.GET_EMAILCREATION}/${pageSize}/${offset}`,
    value
  );
};

export const updateEmailCreation = (id, data) => {
  return appApi.put(`${apiRoutes.UPDATE_EMAILCREATION}/${id}`, data);
};

export const getEmailCreationById = (editId) => {
  return appApi.get(`${apiRoutes.GET_EMAILCREATION_BY_ID}/${editId}`);
};

export const getEmailCreationSearch = (searchData, pageSize, offset) => {
  return appApi.post(
    `${apiRoutes.GET_EMAILCREATION}/${pageSize}/${offset}?search=${searchData}`
  );
};

// Email response table
export const getTableEmailResponses = (value, pageSize, offset) => {
  return appApi.post(
    `${apiRoutes.GET_TABLE_EMAIL_RESPONSES}/${pageSize}/${offset}`,
    value
  );
};

export const getTableEmailResponsesById = (editId) => {
  return appApi.get(`${apiRoutes.GET_TABLE_EMAIL_RESPONSES_BY_ID}/${editId}`);
};

export const getEmailResponseSearch = (searchData, pageSize, offset) => {
  return appApi.post(
    `${apiRoutes.GET_TABLE_EMAIL_RESPONSES}/${pageSize}/${offset}?search=${searchData}`
  );
};

// Email link
export const getEmailLinkForm = () => {
  return appApi.get(`${apiRoutes.GET_EMAIL_LINK_FORM}`);
};

export const getEmailLinkResponses = () => {
  return appApi.get(`${apiRoutes.GET_EMAIL_LINK_RESPONSES}`);
};

export const createEmailResponses = (value) => {
  return appApi.post(`${apiRoutes.CREATE_EMAIL_RESPONSES}`, value);
};

export const getContentImageKey = (value) => {
  return appApi.post(`${apiRoutes.GET_IMAGEKEY}`, value);
};

export const downloadImageByKey = (value) => {
  return appApi.post(`${apiRoutes.DOWNLOAD_IMAGE_BY_KEY}`, value);
};

//////    report
export const getReportData = (id) => {
  return appApi.get(`${apiRoutes.GET_REPORT_DATA}/${id}`);
};

export const getReportTable = (id) => {
  return appApi.get(`${apiRoutes.GET_REPORT_TABLE}/${id}`);
};
