import { makeStyles } from "@mui/styles";

export const formStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  accordionContainer: {
    width: "320px",
    height: "calc(100vh - 64px)",
    position: "fixed",
    overflowY: "scroll",
    backgroundColor: "white",
    borderRight: "2px solid #7394c6",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent !important",
      width: "0px !important",
    },
  },
  formContainer: {
    width: "calc(98vw - 330px)",
    position: "absolute",
    left: "330px",
    padding: "0 20px 20px 20px",
    boxSizing: "border-box",
  },
  titleContainer: {
    position: "sticky",
    top: "62px",
    backgroundColor: "white",
    height: "80px",
    display: "flex",
    alignItems: "center",
    zIndex: 2,
  },
  title: {
    fontWeight: "bolder !important",
    font: "normal normal  22px/16px Rubik",
  },
  formTitle: {
    fontWeight: "bold !important",
    width: "94%",
    margin: "20px auto 0 auto !important",
    font: "normal normal 18px/20px Lato !important",
  },
  subHeaderText: {
    font: " normal normal 600 18px/22px Lato !important",
    color: "#707070",
    marginBottom: "8px !important",
  },

  subTitle: {
    margin: "25px 32px 16px 32px !important",
    fontWeight: "bolder !important",
    cursor: "pointer",
    color: "#2A5BA8",
  },
  formBox: {
    border: "3px solid #2A5BA8",
    padding: 10,
    borderRadius: 4,
  },
  alignBox: {
    width: "80%",
  },
  notePara: {
    marginTop: "-12px !important",
    font: "normal normal normal 16px/19px Lato !important",
    color: "#707070 !important",
  },
  divider: {
    backgroundColor: "#40403A ",
    height: "2px",
    width: "94%",
    margin: "auto !important",
  },
  addBtn: {
    padding: "5px 30px !important",
  },
  addBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "95.5%",
    margin: "auto",
  },
  tableContainer: {
    width: "94%",
    margin: "26px auto",
  },
  bottomBtnContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "30px 0",
  },
  cancelBtn: {
    backgroundColor: "#00000061 !important",
    color: "white !important",
    border: "none !important",
  },
}));
