import { TextField } from "@mui/material";
import React from "react";

export function WidgetDateField(props) {
  const { className, schema, value, onChange, readonly } = props;
  return (
    <TextField
      type="date"
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      className={className}
      xs={6}
      disabled={readonly}
      label={schema.label}
      value={value || ""}
      onChange={(event) => onChange(event.target.value)}
      style={{ marginLeft: 0 }}
    />
  );
}
