import { makeStyles } from "@mui/styles";

export const emailCreationFormStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  accordionContainer: {
    width: "320px",
    height: "calc(100vh - 64px)",
    position: "fixed",
    overflowY: "scroll",
    backgroundColor: "white",
    borderRight: "2px solid #7394c6",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent !important",
      width: "0px !important",
    },
  },
  formContainer: {
    width: "calc(98vw - 330px)",
    position: "absolute",
    left: "330px",
    padding: "0 20px 20px 20px",
    boxSizing: "border-box",
  },
  formBox: {
    padding: "20px",
    border: "3px solid #2A5BA8",
    borderRadius: "4px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    margin: "20px 0",
  },
  arrowBtn: {
    color: "black !important",
    marginRight: "10px !important",
  },
  divider: {
    backgroundColor: "#40403A ",
    height: "2px",
    width: "94%",
    margin: " auto !important",
  },
  uploadTitle: {
    // textDecoration: "underline !important",
    // color: "#5C6BC0 !important",
    font: "normal normal 900 17px/24px Lato !important",
    // marginRight: "3% !important",
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "-12px !important",
  },
  subTitle: {
    // color: "#5C6BC0 !important",
    font: "normal normal 900 20px/24px Lato !important",
    marginLeft: "3% !important",
    marginTop: "30px !important",
  },
  saveBtnContainer: {
    width: "95.5%",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  btn: {
    padding: "6px 30px !important",
    backgroundColor: "#33CE5E !important",
  },
  emailBtn: {
    padding: "6px 40px !important",
    backgroundColor: "#2A5BA8A3 !important",
  },
  formBtn: {
    padding: "6px 40px !important",
    backgroundColor: "#E46D0F94 !important",
  },
}));
