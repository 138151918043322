import { makeStyles } from "@mui/styles";

export const myUserStyle = makeStyles((theme) => ({
  container: {
    height: 85,
    background: "#E46D35 0% 0% no-repeat padding-box !important",
  },
  root: {
    position: "absolute !important",
    top: "180px",
    left: "400px",
    zIndex: 10,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #7391B5",
    borderRadius: "8px",
    opacity: 1,
    width: "750px",
    padding: "30px 20px",
  },
  formConatainer: {
    // position: "absolute !important",
    // top: "425px",
    // left: "0",
    // right: "0",
    // bottom: "0",
    margin: "auto",
    zIndex: 10,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #7391B5",
    borderRadius: "8px",
    opacity: 1,
    width: "750px",
    padding: "30px 20px",
    // height: "14vw",
  },
  header: {
    fontWeight: "900 !important",
    marginLeft: "5px !important",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: "20px !important",
    height: 64,
    background: "#021256 0% 0% no-repeat padding-box",
    // borderRadius: "0px 40px 0px 0px",
    color: "white",
  },
  uploadImg: {
    border: "1px dashed #8284ab  !important",
    width: "200px !important",
    height: "100% !important",
    padding: "14px !important",
    color: "#8284ab !important",
  },
  superAdminRoot: {
    // backgroundColor: "#F0EDED!important",
  },
  userAdminTitle: {
    display: "flex",
    alignItems: "center",
  },

  superAdminTitle: {
    display: "flex",
    alignItems: "center",
    margin: "20px 30px !important",
    // background: "#F0EDED!important",
  },
  adminArrowBackIcon: {
    margin: 20,
    cursor: "pointer",
  },
  cancelBtn: {
    backgroundColor: "#c0c1ce !important",
    marginRight: "20px !important",
    color: "white !important",
  },
  arrowBackIcon: {
    color: "white",
    margin: 20,
    cursor: "pointer",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "end",
  },
  cancelButton: {
    background: "#C0C1CE 0% 0% no-repeat padding-box !important",
    color: "white",
  },
  saveButton: {
    background: "#3AD865 0% 0% no-repeat padding-box !important",
    color: "white",
    marginRight: "30px !important",
    marginLeft: "16px !important",
  },
  auditLog: {
    marginTop: 40,
    fontWeight: "500",
  },
  userDetails: {
    fontWeight: "normal",
  },
  auditPara: {
    margin: "10px 0",
  },
  userProfileContainer: {
    width: "calc(100vw - 370px)",
    position: "absolute",
    left: "330px",
    padding: 0,
    boxSizing: "border-box",
  },
  userRoot: {
    display: "flex",
  },
  accordionContainer: {
    width: "320px",
    height: "calc(100vh - 64px)",
    position: "fixed",
    overflowY: "scroll",
    backgroundColor: "white",
    borderRight: "2px solid #7394c6",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent !important",
      width: "0px !important",
    },
  },
}));
