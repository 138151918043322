import React, { useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { Outlet } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Typography } from "@mui/material";
import { ROUTE_PATHS } from "../../../../routes/routePath";
import { CustomStepper, ModalBox } from "../../../reusable";
import {
  EDIT_COMPANY_USER,
  NEW_COMPANY_USER,
  steps,
  VIEW_COMPANY_USER,
} from "../../../../constant";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "whitesmoke",
  },
  rootAlign: {
    width: "80%",
    paddingTop: 35,
    display: "flex",
    justifyContent: "center",
  },
  formContainer: {
    height: "60vh",
    width: "80%",
    backgroundColor: "white",
    padding: 40,
    borderRadius: 4,
    overflowY: "scroll",
  },
  headingContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: "20px !important",
    height: 64,
    background: "#021256 0% 0% no-repeat padding-box",
    // borderRadius: "0px 40px 0px 0px",
    color: "white",
  },
  rootContainer: {
    height: 85,
    background: "#E46D35 0% 0% no-repeat padding-box !important",
  },
  arrowBackIcon: {
    color: "white",
    margin: 20,
    cursor: "pointer",
  },
  header: {
    fontWeight: "900 !important",
    marginLeft: "5px !important",
  },
}));

function CompanyUserForm() {
  const {
    root,
    rootAlign,
    formContainer,
    headingContainer,
    arrowBackIcon,
    rootContainer,
    header,
  } = useStyles();

  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [modalOpen, setModalOpen] = React.useState(false);
  const search = useLocation().search;
  const searchParam = new URLSearchParams(search);
  const editId = searchParam?.get("editId");
  const userId = searchParam?.get("userId");
  const isViewMode = searchParam?.get("isViewMode");
  const myRef = useRef(null);

  const executeScroll = () => {
    window.scrollTo(0, myRef.current.offsetTop);
    myRef.current.scrollIntoView();
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    executeScroll();
  }, [modalOpen]);

  return (
    <>
      <div className={rootContainer}>
        <div className={headingContainer}>
          <ArrowBackIcon
            className={arrowBackIcon}
            onClick={() => navigate(ROUTE_PATHS.COMPANYUSERS)}
          />
          <Typography variant="h6" className={header}>
            {isViewMode === "true"
              ? VIEW_COMPANY_USER
              : editId || userId
              ? EDIT_COMPANY_USER
              : NEW_COMPANY_USER}
          </Typography>
        </div>
      </div>

      <div className={root}>
        <div className={rootAlign}>
          <div>
            <CustomStepper
              steps={steps}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          </div>
          <div className={formContainer} ref={myRef}>
            <Outlet context={{ handleNext, handleBack, setModalOpen }} />
          </div>
          <ModalBox modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </div>
      </div>
    </>
  );
}

export default CompanyUserForm;
