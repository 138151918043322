import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { createSearchParams } from "react-router-dom";
import { ROUTE_PATHS } from "../routes/routePath";

export const months = [
  { id: 1, name: "Jan" },
  { id: 2, name: "Feb" },
  { id: 3, name: "Mar" },
  { id: 4, name: "Apr" },
  { id: 5, name: "May" },
  { id: 6, name: "Jun" },
  { id: 7, name: "Jul" },
  { id: 8, name: "Aug" },
  { id: 9, name: "Sep" },
  { id: 10, name: "Oct" },
  { id: 11, name: "Nov" },
  { id: 12, name: "Dec" },
];

export const Years = [
  { id: 1, name: "2022" },
  { id: 2, name: "2023" },
  { id: 3, name: "2024" },
  { id: 4, name: "2025" },
  { id: 5, name: "2026" },
  { id: 6, name: "2027" },
  { id: 7, name: "2028" },
  { id: 8, name: "2029" },
  { id: 9, name: "2030" },
];

export const parameterFields = [
  {
    id: 1,
    label: "From Month",
    name: "fromMonth",
    inputValues: months,
  },
  {
    id: 2,
    label: "To Month",
    name: "toMonth",
    inputValues: months,
    isViewmode: true,
  },
  {
    id: 3,
    label: "From Year",
    name: "fromYear",
    inputValues: Years,
  },
  {
    id: 4,
    label: "To Year",
    name: "toYear",
    inputValues: Years,
    isViewmode: true,
  },
];

export const parameterInitialValues = {
  fromMonth: "",
  toMonth: "",
  fromYear: "",
  toYear: "",
};

export const parameterListColumn = (navigate) => [
  {
    Header: " Year",
    accessor: "year",
    sticky: "left",
    id: 1,
    width: 300,
    Cell: (props) => (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {props.value}
        <div>
          <IconButton
            onClick={() => {
              navigate({
                pathname: ROUTE_PATHS.PARAMETER,
                search: `?${createSearchParams({
                  editId: props.row.original.id,
                })}`,
              });
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    ),
  },
];
