import { EmailPopover } from "../../components/reusable";

export const emailMasterListColumn = (input, status, set, deleteEmail) => [
  {
    Header: "Organization Name",
    accessor: "organizationName",
    sticky: "left",
    id: 1,
    Cell: (props) => {
      const removeEmail = (boolean) => {
        if (boolean) {
          deleteEmail(props);
        }
      };

      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {props.value}
          <EmailPopover
            values={props}
            toShow={{ emailMasterDelete: true }}
            func={removeEmail}
          />
        </div>
      );
    },
  },
  {
    Header: "Contact Name",
    accessor: "name",
    id: 2,
  },
  {
    Header: "Email",
    accessor: "email",
    id: 3,
    Cell: (props) => (typeof props.value === "string" ? props.value : ""),
  },
];

export const emailMasterListRawdata = [
  {
    id: 1,
    name: "example",
    email: "ex@gmail.cc",
    organizationName: "Draft",
  },
  {
    id: 2,
    name: "example",
    email: "ex@gmail.cc",
    organizationName: "Draft",
  },
];
