export const cancelMailField = {
  reason: {
    label: "Text",
    name: "reason",
    type: "text",
  },
};

export const cancelMailInitial = {
  reason: "",
};
