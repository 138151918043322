import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import {
  getAllParamter,
  getFormSeed,
  getReportTable,
} from "../../../../api/api";
import { ddRequirementsInformationList } from "../../../../constant/dataEntry/ddRequirementsInformation";
import { parseQueryParams } from "../../../../utils/queryParams";
import { CustomToggleButton, DropdownField } from "../../../reusable";
import CustomReactTable from "../../../reusable/CustomReactTable";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  accordionContainer: {
    width: "320px",
    height: "calc(100vh - 64px)",
    position: "fixed",
    overflowY: "scroll",
    backgroundColor: "white",
    borderRight: "2px solid #7394c6",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent !important",
      width: "0px !important",
    },
  },
  tableContainer: {
    width: "calc(98vw - 330px)",
    position: "absolute",
    left: "330px",
    padding: "0 20px 20px 20px",
    boxSizing: "border-box",
  },

  title: {
    fontWeight: "bold !important",
  },
  topbarContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0",
  },
}));

const DueDiligenceInformation = () => {
  const { tableContainer, root, accordionContainer, topbarContainer, title } =
    useStyles();

  // const navigate = useNavigate();
  const location = useLocation();
  const cookies = new Cookies();
  const custId = cookies.get("custId");

  const pageParams = parseQueryParams(location?.search);
  const pageSize = parseInt(pageParams?.pageSize) || 10;
  const currentPage = parseInt(pageParams?.currentPage) || 1;
  const [count, setCount] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [financialYearInput, setFinancialYearInput] = React.useState([]);
  const [financialYearValue, setFinancialYearValue] = React.useState("");
  const [formSeed, setFormSeed] = React.useState([]);

  useEffect(() => {
    getAllParamter().then((res) => {
      let filterInput = res.data.filter((item) => item.customerId === custId);
      let inputValues = filterInput.map((item) => ({
        id: item.id,
        accessor: "id",
        name: item.year,
      }));
      setFinancialYearInput(inputValues);

      getFormSeed()
        .then((res) => {
          let filterValue = res.data.filter(
            (item) => item.section === "dueDiligenceDescription"
          );
          setFormSeed(filterValue);
        })
        .catch((res) => console.log(res));
    });
  }, [custId]);

  useEffect(() => {
    financialYearValue &&
      getReportTable(financialYearValue)
        .then((res) => {
          let tableValues = [];
          let filterValue = res.data.filter((item) =>
            formSeed.find((seed) => item.formId === seed.id)
          );

          filterValue?.forEach((val) => {
            val?.informationDetails.length !== 0 &&
              (tableValues = [
                ...tableValues,
                {
                  ...val?.informationDetails[
                    val?.informationDetails.length - 1
                  ],
                  financialYearId: val.financialYearId,
                  formId: val.formId,
                  detail: "Information",
                },
              ]);

            val?.supportingData.length !== 0 &&
              (tableValues = [
                ...tableValues,
                {
                  ...val?.supportingData[val?.supportingData.length - 1],
                  financialYearId: val.financialYearId,
                  formId: val.formId,
                  detail: "Supporting Data",
                },
              ]);

            ///////     to show all values
            // val?.informationDetails.length !== 0 &&
            // val?.informationDetails?.forEach((info) => {
            //   tableValues = [
            //     ...tableValues,
            //     {
            //       ...info,
            //       financialYearId: val.financialYearId,
            //       formId: val.formId,
            //       detail: "Information",
            //     },
            //   ];
            // });

            // val?.supportingData.length !== 0 &&
            //   val.supportingData?.forEach((support) => {
            //     tableValues = [
            //       ...tableValues,
            //       {
            //         ...support,
            //         financialYearId: val.financialYearId,
            //         formId: val.formId,
            //         detail: "Supporting Data",
            //       },
            //     ];
            //   });
          });

          setTableData(tableValues);
        })
        .catch((res) => console.log(res));
  }, [financialYearValue, formSeed]);

  // const onChangePageSize = (size) => {
  //   const newParams = createQueryParams({
  //     pageSize: size,
  //     currentPage: size === pageSize ? currentPage : 1,
  //     ...(searchdata && { search: searchdata }),
  //   });
  //   // navigate(`${listPath}?${newParams}`);
  // };

  // //to change page number (set offset)
  // const onPageNumberChange = (page) => {
  //   const newParams = createQueryParams({
  //     pageSize,
  //     currentPage: page,
  //     ...(searchdata && { search: searchdata }),
  //   });
  //   // navigate(`${listPath}?${newParams}`);
  // };
  return (
    <div className={root}>
      <div className={accordionContainer}>
        <CustomToggleButton />
      </div>
      <div className={tableContainer}>
        <div className={topbarContainer}>
          <Typography variant="h6" className={title}>
            DOCUMENT SUBMITTED FOR THE YEAR - DUE DILIGENCE DESCRIPTION
          </Typography>
        </div>
        <Grid container rowSpacing={4}>
          <Grid item xs={5}>
            <DropdownField
              name={"financialYearId"}
              label={"Select Year"}
              onChange={(e) => {
                setFinancialYearValue(e.target.value);
              }}
              value={financialYearValue}
              fullWidth={true}
              inputValues={financialYearInput}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomReactTable
              columnData={ddRequirementsInformationList(
                financialYearInput,
                formSeed
              )}
              rawData={tableData}
              disableRowSelection={true}
              //   disableColumnHiding={true}
              // onChangePageSize={onChangePageSize}
              count={count}
              pageSize={pageSize}
              currentPage={currentPage}
              // onPageNumberChange={onPageNumberChange}
              style={{
                th: {
                  color: "#0000008A",
                  font: "normal normal bold 14px/19px Roboto",
                  display: "flex !important",
                  alignItems: "center",
                  height: "64px !important",
                  backgroundColor: "#D2E1FC",
                },
                body: {
                  position: "relative",
                  zIndex: "0",
                  backgroundColor: "white",
                  color: "#40403A",
                  font: "normal normal bold 14px/19px Roboto",
                },
              }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default DueDiligenceInformation;
