import React, { forwardRef } from "react";
import { makeStyles } from "@mui/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { InputAdornment, TextField } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";

const useStyles = makeStyles((theme) => ({
  dateIcon: {
    color: "#081f57",
  },
  field: {
    "& :hover": {
      cursor: "pointer",
    },
  },
}));

export function DatePickerRangeField(props) {
  const { dateIcon, field } = useStyles();
  const { startDate, setStartDate, endDate, setEndDate } = props;

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <TextField
      className={field}
      onClick={onClick}
      ref={ref}
      value={value}
      style={{ padding: 0, cursor: "pointer" }}
      autoComplete="off"
      placeholder="Select date range"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <DateRangeIcon className={dateIcon} />
          </InputAdornment>
        ),
      }}
    ></TextField>
  ));
  return (
    <DatePicker
      customInput={<ExampleCustomInput />}
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
    />
  );
}
