import * as React from "react";
import Box from "@mui/material/Box";
import { Popover, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { getFormSeed, getUsersDetailsById } from "../../api/api";

const useStyles = makeStyles((theme) => ({
  titles: {
    margin: "20px 6px !important",
    cursor: "pointer",
  },
  popperBox: {
    boxShadow: "0px 0px 8px 7px #0000001A",
    marginLeft: "12px",
  },
}));

export default function CustomPopper(props) {
  const { buttons, secName, open, anchorEl, handleClose } = props;
  const { titles, popperBox } = useStyles();
  const navigate = useNavigate();
  const [subButtons, setSubButtons] = React.useState(false);
  const [buttonEl, setButtonEl] = React.useState(false);
  const [subOpen, setSubOpen] = React.useState(false);
  const [subSecName, setSubSecName] = React.useState("");
  const [formSeed, setFormSeed] = React.useState([]);
  const [userAccessDetails, setUserAccessDetails] = React.useState(null);

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;
  const cookies = new Cookies();
  const userId = cookies.get("userId");
  const userTypeName = cookies.get("userTypeName");

  const subHandleclose = () => {
    setSubOpen(false);
    setButtonEl(!subOpen);
  };

  const handleClickPoper = (e, item) => {
    if (item?.path) {
      navigate(item?.path, { replace: true });
      handleClose();
      subHandleclose();
    }
    if (item?.buttons) {
      setSubButtons(item?.buttons);
      setButtonEl(e.currentTarget);
      setSubOpen(true);
      // setSubSecName(item?.value);
      setSubSecName("dueDiligenceDescription");
    }
  };

  const eachButtons = (item) => {
    return (
      <Typography
        key={item.id}
        className={titles}
        onClick={(e) => handleClickPoper(e, item)}
      >
        {item.name}
      </Typography>
    );
  };

  const getSecForm = (value) => {
    return formSeed.filter((item) => item.section === value);
  };

  const showPopover = (buttons, secName, open, anchorEl, handleClose) => (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      sx={{ zIndex: 3 }}
      className={popperBox}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Box sx={{ p: 1, bgcolor: "background.paper", minWidth: "250px" }}>
        {buttons?.map((item) => {
          let getform = getSecForm(secName)?.find(
            (form) => form.name === item.value
          );

          if (item.buttons) {
            let getform = getSecForm(secName)?.filter((form) =>
              item.buttons.find((btn) => btn.value === form.name)
            );
            //to show principles as per the form availability
            return userTypeName === "Company User"
              ? getform.some((subform) =>
                  userAccessDetails.find(
                    (access) =>
                      access.formId === subform.id && access.allowed === true
                  )
                ) && eachButtons(item)
              : eachButtons(item);
          }

          return userTypeName === "Company User"
            ? userAccessDetails?.find(
                (item) => item.formId === getform?.id && item.allowed === true
              ) && eachButtons(item)
            : eachButtons(item);
        })}
      </Box>
    </Popover>
  );

  React.useEffect(() => {
    getFormSeed().then((res) => setFormSeed(res.data));
  }, []);

  React.useEffect(() => {
    if (userId) {
      getUsersDetailsById(userId)
        .then((res) => setUserAccessDetails(res.data.formAccess))
        .catch((res) => console.log(res));
    }
  }, [userId]);

  return (
    <div>
      {showPopover(buttons, secName, open, anchorEl, handleClose)}
      {subButtons &&
        showPopover(subButtons, subSecName, subOpen, buttonEl, subHandleclose)}
    </div>
  );
}
