import { makeStyles } from "@mui/styles";

export const reportStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  accordionContainer: {
    width: "320px",
    height: "calc(100vh - 64px)",
    position: "fixed",
    overflowY: "scroll",
    backgroundColor: "white",
    borderRight: "2px solid #7394c6",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent !important",
      width: "0px !important",
    },
  },
  formContainer: {
    width: "calc(98vw - 330px)",
    position: "absolute",
    left: "330px",
    padding: "0 20px 20px 20px",
    boxSizing: "border-box",
  },
  titleContainer: {
    position: "sticky",
    top: "62px",
    backgroundColor: "white",
    // height: "80px",
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "space-between",
    zIndex: 2,
  },
  reportTitle: {
    position: "sticky",
    top: "62px",
    backgroundColor: "white",
    height: "80px",
    display: "flex",
    alignItems: "center",
    zIndex: 2,
  },
  captionTitleContainer: {
    // width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "46px",
  },
  captionTitle: {
    fontWeight: "bolder !important",
    font: "normal normal bold 18px/19px Lato !important",
    fontSize: "24px !important",
  },
  title: {
    fontWeight: "bolder !important",
    font: "normal normal bold 16px/19px Lato",
  },
  reportGenText: {
    paddingTop: "20px",
  },
  pdfTitle: {
    fontWeight: "bolder !important",
    font: "normal normal bold 16px/19px Lato",
    marginBottom: "8px !important",
  },
  pdfPara: {
    font: "normal normal bold 16px/19px Lato",
    color: "#565656",
    marginBottom: "16px !important",
    border: "1px solid black",
    width: "97%",
    minHeight: "50px",
    borderRadius: 5,
    padding: 8,
  },
  answerBox: {
    border: "1px solid black",
    width: "97%",
    minHeight: "50px",
    borderRadius: 5,
    padding: 8,
  },
  checkBoxTitle: {
    "& > span": {
      fontWeight: "bolder !important",
      font: "normal normal bold 16px/19px Lato !important",
      marginBottom: "8px !important",
    },
  },
  reportBtn: {
    margin: "8px 18px 8px 0 !important",
  },
  arrowBtn: {
    color: "black !important",
    marginRight: "10px !important",
  },
}));
