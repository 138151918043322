export const emailCreationFormInitial = {
  emailDate: "",
  name: "",
  senderName: "",
  senderEmail: "",
  emails: "",
  emailSalutation: "",
  emailContent: "",
  emailClosing: "",
  isDataRequried: "true",
  financialYearId: "",
  dataCollectionFormId: "",
  scheduleDate: "",
  scheduleTime: "",
  followUpDays: "",
  followUpTimes: "",
};

export const emailCreationFields = [
  {
    id: 1,
    name: "emailDate",
    label: "Date of the email",
    type: "date",
    xs: 6,
  },
  {
    id: 2,
    name: "name",
    label: "Name of the email",
    type: "text",
    xs: 12,
  },
  {
    id: 3,
    name: "senderName",
    label: "Sender Name",
    type: "text",
    xs: 6,
  },
  {
    id: 4,
    name: "senderEmail",
    label: "Sender Email",
    type: "email",
    xs: 6,
  },
];

export const emailCreationSubFields = [
  {
    id: 1,
    name: "emails",
    label: "Recipient Email/Distribution List",
    type: "text",
    xs: 12,
  },
];

export const emailCreationUploadFields = [
  // {
  //   id: 1,
  //   name: "reference",
  //   label: "Reference",
  //   type: "radio",
  //   xs: 12,
  //   inputValues: [
  //     {
  //       id: 1,
  //       name: "Important Information Request",
  //       accessor: "id",
  //     },
  //     {
  //       id: 2,
  //       name: "Important Certification Request (email to include link to Certification)",
  //       accessor: "id",
  //     },
  //   ],
  // },
  {
    id: 2,
    name: "emailSalutation",
    label: "Email Salutation",
    type: "text",
    xs: 12,
  },
  {
    id: 3,
    name: "emailContent",
    label: "Email Content",
    type: "textarea",
    xs: 12,
  },
  // {
  //   id: 4,
  //   name: "link",
  //   label: "Link",
  //   type: "text",
  //   xs: 8,
  //   link: true,
  // },
  // {
  //   id: 5,
  //   name: "linkValidity",
  //   label: "Link Validity",
  //   type: "text",
  //   xs: 4,
  //   link: true,
  // },
  // {
  //   id: 6,
  //   name: "uploadInformation",
  //   label: "Upload Information",
  //   type: "radio",
  //   xs: 12,
  //   inputValues: [
  //     {
  //       id: 1,
  //       name: "Yes",
  //       value: "Yes",
  //       accessor: "value",
  //     },
  //     {
  //       id: 2,
  //       name: "No",
  //       value: "No",
  //       accessor: "value",
  //     },
  //   ],
  // },
  {
    id: 7,
    name: "emailClosing",
    label: "Email Closing",
    type: "textarea",
    xs: 12,
  },
];

export const emailUserDataFields = (financialYearInput, topicNameValues) => [
  {
    id: 1,
    name: "isDataRequried",
    label: "Data required from user",
    type: "radio",
    xs: 12,
    inputValues: [
      {
        id: 1,
        name: "Yes",
        value: true,
        accessor: "value",
      },
      {
        id: 2,
        name: "No",
        value: false,
        accessor: "value",
      },
    ],
  },
  {
    id: 2,
    name: "financialYearId",
    label: "Select Year",
    type: "select",
    xs: 6,
    inputValues: financialYearInput,
  },
  {
    id: 3,
    name: "dataCollectionFormId",
    label: "Form Topic Name",
    type: "select",
    xs: 6,
    inputValues: topicNameValues,
  },
];

export const emailCreationParameters = [
  {
    id: 1,
    name: "scheduleDate",
    label: "Schedule Date",
    type: "date",
    xs: 6,
  },
];

export const emailCreationFollow = [
  // {
  //   id: 1,
  //   name: "frequency",
  //   label: "Frequency",
  //   type: "radio",
  //   xs: 6,
  //   inputValues: [
  //     {
  //       id: 1,
  //       name: "Weekly",
  //       accessor: "name",
  //     },
  //     {
  //       id: 2,
  //       name: "Monthly",
  //       accessor: "name",
  //     },
  //   ],
  // },
  {
    id: 1,
    name: "followUpDays",
    label: "No of Days",
    type: "number",
    xs: 6,
  },
  {
    id: 2,
    name: "followUpTimes",
    label: "No of times",
    type: "number",
    xs: 6,
  },
  // {
  //   id: 3,
  //   name: "internalEscalation",
  //   label: "Internal Escalation",
  //   type: "radio",
  //   xs: 6,
  //   inputValues: [
  //     {
  //       id: 1,
  //       name: "Weekly",
  //       accessor: "name",
  //     },
  //     {
  //       id: 2,
  //       name: "Monthly",
  //       accessor: "name",
  //     },
  //   ],
  // },
  // {
  //   id: 4,
  //   name: "emailAddress",
  //   label: "Email address for escalation",
  //   type: "text",
  //   xs: 6,
  // },
];
