import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { EmailPopover } from "../../components/reusable";
import { ROUTE_PATHS } from "../../routes/routePath";

export const dataCollectionInitialValues = {
  financialYearId: "",
  topicName: "",
  expireDate: "",
  heading: "",
  subHeading: "",
  formSetupData: {
    certification: "Yes",
    certificationText: "",
    dataCollection: "Yes",
    dataCollectionText: "",
    multiRecords: "Yes",
    documentUpload: "Yes",
    documentUploadText: "",
    contentText: "Yes",
    remarks: "Yes",
    multiRecordsUpload: "Yes",
    question: "",
    questionOptions: [],
    questionnaireValues: [],
  },
};

export const dataColllectionHelperText = {
  form: "XXXXXX 1",
  certification: "XXXXXX 2",
  dataText: "XXXXXX 3",
  documentUpload: "XXXXXX 4",
  questionnaire: "XXXXXX 5",
};

export const dataCollectionFields = [
  {
    name: "financialYearId",
    label: "Select Year",
    type: "select",
    xs: 6,
  },
  {
    name: "topicName",
    label: "Form Topic Name",
    type: "text",
    xs: 6,
  },
  {
    name: "expireDate",
    label: "Compliance Expiry Date",
    type: "date",
    xs: 6,
  },
  {
    name: "heading",
    label: "Form Heading",
    type: "text",
    xs: 12,
  },
  {
    name: "subHeading",
    label: "Form Sub - Heading",
    type: "text",
    xs: 12,
  },
];

export const formSetupDataFields = [
  {
    name: "certification",
    label: `SECTION NAME : Acknowledgement/Certification `,
    type: "radio",
    inputValues: [
      { id: 1, name: "Yes", accessor: "name" },
      { id: 1, name: "No", accessor: "name" },
    ],
    xs: 12,
    helperPoper: dataColllectionHelperText.certification,
  },
  {
    name: "certificationText",
    label: "If Yes, Enter Acknowledgement/Certification Text",
    type: "textarea",
    xs: 12,
  },
  { name: "divider", type: "divider" },
  {
    name: "dataCollection",
    label: "SECTION NAME : Data Collection Text",
    type: "radio",
    inputValues: [
      { id: 1, name: "Yes", accessor: "name" },
      { id: 1, name: "No", accessor: "name" },
    ],
    helperPoper: dataColllectionHelperText.dataText,
    xs: 12,
  },
  {
    name: "dataCollectionText",
    label: "Description",
    type: "text",
    xs: 12,
    accessor: "dataCollection",
  },
  {
    name: "multiRecords",
    label: "Multi Records",
    type: "radio",
    inputValues: [
      { id: 1, name: "Yes", accessor: "name" },
      { id: 1, name: "No", accessor: "name" },
    ],
    toShowValue: "dataCollection",
    xs: 12,
  },
  { name: "divider", type: "divider" },
  {
    name: "documentUpload",
    label: "SECTION NAME : Data Collection Document Upload",
    type: "radio",
    inputValues: [
      { id: 1, name: "Yes", accessor: "name" },
      { id: 1, name: "No", accessor: "name" },
    ],
    helperPoper: dataColllectionHelperText.documentUpload,
    xs: 12,
  },
  {
    name: "documentUploadText",
    label: "Document upload text",
    type: "text",
    xs: 12,
    accessor: "documentUpload",
  },
  {
    name: "contentText",
    label: "Content Text",
    type: "radio",
    inputValues: [
      { id: 1, name: "Yes", accessor: "name" },
      { id: 1, name: "No", accessor: "name" },
    ],
    toShowValue: "documentUpload",
    xs: 4,
  },
  {
    name: "remarks",
    label: "Remarks, if any",
    type: "radio",
    inputValues: [
      { id: 1, name: "Yes", accessor: "name" },
      { id: 1, name: "No", accessor: "name" },
    ],
    toShowValue: "documentUpload",
    xs: 4,
  },
  {
    name: "multiRecordsUpload",
    label: "Multi Records",
    type: "radio",
    inputValues: [
      { id: 1, name: "Yes", accessor: "name" },
      { id: 1, name: "No", accessor: "name" },
    ],
    toShowValue: "documentUpload",
    xs: 4,
  },
  { name: "divider", type: "divider" },
];

export const dataCollectionFormList = (values, setValues, setEditIndex) => [
  {
    Header: "Question",
    accessor: "Question",
    id: 1,
    width: 200,
  },
  {
    Header: "Answers",
    accessor: "Answers",
    id: 2,
    width: 200,

    Cell: (props) => {
      return (
        <div
          style={{
            display: "flex ",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>
            {props.row.original.Answers?.map(({ name }) => `${name}, `)}
          </span>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <IconButton
              onClick={() => {
                setEditIndex(props.row.index);
                setValues({
                  ...values,
                  formSetupData: {
                    ...values.formSetupData,
                    question: props.row.original.Question,
                    questionOptions: props.row.original.Answers,
                  },
                });
              }}
            >
              <EditIcon color="action" />
            </IconButton>
            <IconButton
              onClick={() => {
                values.formSetupData?.questionnaireValues?.splice(
                  props.row.index,
                  1
                );
                setValues({
                  ...values,
                  formSetupData: {
                    ...values.formSetupData,
                    question: "",
                    questionOptions: "",
                    questionnaireValues: [
                      ...values.formSetupData.questionnaireValues,
                    ],
                  },
                });
              }}
            >
              <DeleteIcon color="action" />
            </IconButton>
          </div>
        </div>
      );
    },
  },
];

export const dataCollectionListColumn = (financialYearInput) => [
  {
    Header: " Year",
    accessor: "financialYearId",
    sticky: "left",
    id: 1,
    width: 300,
    Cell: (props) => (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {financialYearInput?.map(
          (item) => item.id === props.value && item.name
        )}
        <EmailPopover
          values={props}
          toShow={{
            edit: true,
          }}
          paths={{
            edit: ROUTE_PATHS.DATA_COLLECTION_FORM,
          }}
        />
      </div>
    ),
  },
  {
    Header: "Form Topic",
    accessor: "topicName",
    id: 2,
    width: 200,
  },
  {
    Header: "Compliance expiry date",
    accessor: "expireDate",
    id: 3,
    width: 200,
    Cell: (props) => moment(props.value).format("L"),
  },
  {
    Header: "Form Heading",
    accessor: "heading",
    id: 4,
    width: 200,
  },
  {
    Header: "Form Sub Heading",
    accessor: "subHeading",
    id: 5,
    width: 200,
    Cell: (props) => (props.value !== "" ? props.value : "--"),
  },
];
