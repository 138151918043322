import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  minWidth: "50%",
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function BulkEmailUpload({ setOpen, open, uploadStatus }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          div: {
            minWidth: "40%",
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{ fontWeight: "bold" }}
        >
          Email Upload Status
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Total Email : {uploadStatus?.totalCount}{" "}
          </Typography>
          <Typography gutterBottom>
            Email Uploaded : {uploadStatus?.createdCount}
          </Typography>
          <Typography gutterBottom>
            Email Duplicated : {uploadStatus?.duplicateCount}
          </Typography>

          {uploadStatus?.duplicateCustomer?.length > 0 ? (
            <>
              <Divider />

              <p style={{ fontWeight: "bold", textDecoration: "underline" }}>
                Email Duplicated List
              </p>
              <Typography gutterBottom>
                {uploadStatus?.duplicateCustomer?.map((item) => (
                  <p>{item?.email} </p>
                ))}
              </Typography>
            </>
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            OK
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
