import React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";
import { useNavigate, createSearchParams, useLocation } from "react-router-dom";
import { ROUTE_PATHS } from "../../routes/routePath";
import { getInvoiceDownload, sendReminder } from "../../api/api";
import UpdateStatus from "./UpdateStatus";
import CustomerUpdateStatus from "./CustomerUpdateStatus";
import UpdatePaymentStatus from "./UpdatePaymentStatus";

export const EditPopover = (props) => {
  const { setMailSent } = props;
  let navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //to handle close button
  const handleClose = () => {
    setAnchorEl(null);
  };

  const pathName = useLocation().pathname;

  const handleView = (id) => {
    pathName === "/customersList" &&
      navigate({
        pathname: `${ROUTE_PATHS.CUSTOMERS_FORM}`,
        search: `?${createSearchParams({
          editId: id,
          isViewMode: true,
        })}`,
      });
    pathName === "/myUsersList" &&
      navigate({
        pathname: `${ROUTE_PATHS.MY_USERS_FORM}`,
        search: `?${createSearchParams({
          userId: id,
          isViewMode: true,
        })}`,
      });
    pathName === "/companyUsers" &&
      navigate({
        pathname: `/companyUsersForm/userForm`,
        search: `?${createSearchParams({
          editId: id,
          isViewMode: true,
        })}`,
      });
    setAnchorEl(null);
  };

  //to handle edit button
  const handleEdit = (id) => {
    pathName === "/invoiceSetupList" &&
      navigate({
        pathname: `${ROUTE_PATHS.INVOICES_SETUP_FORM}`,
        search: `?${createSearchParams({
          editId: id,
        })}`,
      });

    pathName === "/customersList" &&
      navigate({
        pathname: `${ROUTE_PATHS.CUSTOMERS_FORM}`,
        search: `?${createSearchParams({
          editId: id,
        })}`,
      });
    pathName === "/myUsersList" &&
      navigate({
        pathname: `${ROUTE_PATHS.MY_USERS_FORM}`,
        search: `?${createSearchParams({
          userId: id,
        })}`,
      });
    pathName === "/companyUsers" &&
      navigate({
        pathname: `/companyUsersForm/userForm`,
        search: `?${createSearchParams({
          editId: id,
        })}`,
      });
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleDownload = (props) => {
    getInvoiceDownload(props.id).then((response) => {
      window.open(response.data, "_self");
    });
    setAnchorEl(null);
  };

  const sendRemainder = (props) => {
    sendReminder(props.id, props.customerId).then((response) => {
      setAnchorEl(null);
      setMailSent(true);
      navigate(`${ROUTE_PATHS.INVOICES_SETUP_LIST}`);
    });
  };

  return (
    <div>
      <Button
        aria-describedby={id}
        onClick={handleClick}
        className="moreHorizTwoToneIconStyle"
      >
        <MoreHorizTwoToneIcon />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {props.customer && (
          <div>
            <Typography
              className="editTableStyle"
              onClick={() => handleEdit(props.row.original.id)}
            >
              {"Edit User"}
            </Typography>
            <Typography
              className="editTableStyle"
              onClick={() => handleView(props.row.original.id)}
            >
              {"View User"}
            </Typography>
            <UpdateStatus
              originalId={props.row.original}
              title="Company users"
              setAnchorEl={setAnchorEl}
              pathName={pathName}
              setStatus={props.setStatus}
              status={props.status}
            />
          </div>
        )}

        {props.invoice && (
          <Button
            onClick={() => handleDownload(props.row.original)}
            style={{ padding: 20 }}
          >
            Download Invoice
          </Button>
        )}

        {props.superAdmin && (
          <div>
            <Typography
              className="editTableStyle"
              onClick={() => handleEdit(props.row.original.id)}
            >
              {"Edit User"}
            </Typography>
            {/* <Typography
              className="editTableStyle"
              onClick={() => handleView(props.row.original.id)}
            >
              {"View User"}
            </Typography> */}
            <CustomerUpdateStatus
              originalId={props.row.original}
              title="Company users"
              setAnchorEl={setAnchorEl}
              pathName={pathName}
              setStatus={props.setStatus}
              status={props.status}
            />
          </div>
        )}

        {props.invoiceSetupList && (
          <div>
            <Typography
              className="editTableStyle"
              onClick={() => handleEdit(props.row.original.id)}
            >
              {"Edit Invoice"}
            </Typography>
            <UpdatePaymentStatus
              originalId={props.row.original}
              title="Company users"
              setAnchorEl={setAnchorEl}
              pathName={pathName}
              setStatus={props.setStatus}
              status={props.status}
            />
            <Typography
              className={
                props.row.original.pymtOverDue &&
                !props.row.original.reminderEmailSent &&
                "editTableStyle"
              }
              onClick={() => sendRemainder(props.row.original)}
            >
              {props.row.original.pymtOverDue &&
                !props.row.original.reminderEmailSent &&
                "Send Reminder"}
            </Typography>
          </div>
        )}
      </Popover>
    </div>
  );
};
