export const emailMasterForm = [
  {
    id: 1,
    name: "organizationName",
    label: "Organisation Name",
    type: "text",
  },
  {
    id: 2,
    name: "name",
    label: "Contact Name",
    type: "text",
  },
  {
    id: 3,
    name: "email",
    label: "Email",
    type: "email",
  },
];

export const emailMasterFormInitial = {
  organizationName: "",
  name: "",
  email: "",
};
