import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import moment from "moment";
import {
  deleteInformationDetails,
  deleteSupportingData,
  downloadFileByLink,
} from "../../api/api";
import { saveAs } from "file-saver";

export const entryFormDataFields = {
  financialYear: {
    name: "financialYear",
    label: "Select Year",
  },
  informationText: {
    name: "informationText",
    label: "Description",
  },
  saveAs: {
    name: "saveAs",
    label: "Save As",
  },
};

export const entryFormDataInitial = {
  financialYear: "",
  informationText: "",
  saveAs: "af6f2eb0-2147-47e0-9da3-5128cd5e4b12",
  data: {
    informationDetails: [],
    supportingData: [],
  },
};

export const financialYearConstant = [
  {
    id: 1,
    name: "July 1, 2022 – May 31, 2023",
    accessor: "name",
  },
  {
    id: 2,
    name: "June 1, 2023 – May 31, 2024",
    accessor: "name",
  },
  {
    id: 3,
    name: "June 1, 2024 – May 31, 2025",
    accessor: "name",
  },
  {
    id: 4,
    name: "June 1, 2025 – May 31, 2026",
    accessor: "name",
  },
];

export const entryFormDataOptions = [
  {
    id: "af6f2eb0-2147-47e0-9da3-5128cd5e4b12",
    name: "Draft",
    value: "DRAFT",
    accessor: "id",
  },
  {
    id: "2b328687-4562-47ad-be73-40ebfd557ea1",
    name: "Final",
    value: "FINAL",
    accessor: "id",
  },
];

export const documentTypesDropdown = [
  {
    id: 1,
    name: "PDF",
    accessor: "name",
  },
  {
    id: 2,
    name: "PNG",
    accessor: "name",
  },
  {
    id: 3,
    name: "JPG",
    accessor: "name",
  },
  {
    id: 5,
    name: "WORD",
    accessor: "name",
  },
  {
    id: 4,
    name: "Others",
    accessor: "name",
  },
];

export const entryFormInformationFields = (sidebarIndex) => [
  {
    id: 1,
    name: "fileType",
    label: "Document Type",
    type: "select",
    inputValues: documentTypesDropdown,
  },
  {
    id: 2,
    name: "fileContent",
    label: `${sidebarIndex === 3 ? "Attachment Name" : "File Name"}`,

    type: "text",
  },
  {
    id: 3,
    name: "uploadFile",
    // label: "Upload Document",
    label: `${sidebarIndex === 3 ? "Upload Attachment" : "Upload Document"}`,
  },
];

export const entryFormInformationInitial = {
  fileType: "",
  fileContent: "",
  uploadFile: "",
};

export const entryFormSupportingFields = (sidebarIndex) => ({
  fileType: {
    name: "fileType",
    label: "Document Type",
    inputValues: documentTypesDropdown,
  },
  fileContent: {
    name: "fileContent",
    // label: "File Name",
    label: `${sidebarIndex === 3 ? "Attachment Name" : "File Name"}`,
  },
  uploadFile: {
    name: "uploadFile",
    // label: "Upload Document",
    label: `${sidebarIndex === 3 ? "Upload Attachment" : "Upload Document"}`,
  },
});

export const entryFormSupportingInitial = {
  fileType: "",
  fileContent: "",
  uploadFile: "",
};

export const entryFormInformationColumn = (
  setInformationTableData,
  setRenderTable,
  setDeletedInformation,
  informationTabledata,
  financialYearInput,
  sidebarIndex
) => [
  {
    Header: " Year",
    accessor: "data.financialYear",
    sticky: "left",
    id: 1,
    width: 250,
    Cell: (props) => {
      return financialYearInput.map(
        (item) => item.id === props.value && item.name
      );
    },
  },
  {
    Header: "DATE",
    accessor: "createdAt",
    id: 2,
    width: 150,
    Cell: (props) => {
      return moment(props.value).format("L");
    },
  },
  {
    Header: "Document Type",
    accessor: "fileType",
    id: 3,
    width: 150,

    Cell: (props) => {
      return props.value.split("/")[1].toUpperCase();
    },
  },
  {
    Header: `${sidebarIndex === 3 ? "Attachment Name" : "File Name"}`,
    accessor: "data.fileContent",
    id: 4,
    width: 150,
  },
  {
    Header: `${sidebarIndex === 3 ? "Attachment" : "Document"}`,
    accessor: "fileName",
    id: 5,
    width: 150,
    Cell: (props) => (
      <span style={{ textDecoration: "underline", color: "#2A5BA8" }}>
        {props.value}
      </span>
    ),
  },
  {
    Header: "Update By",
    accessor: "user.name",
    id: 6,
    width: 150,

    Cell: (props) => {
      return (
        <div
          style={{
            display: "flex ",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span> {props.value} </span>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <IconButton
              onClick={() => {
                let rowId = props.row.original.id;
                downloadFileByLink("informationDetails", rowId).then((res) =>
                  saveAs(res.data.url)
                );
              }}
            >
              <DownloadIcon color="primary" />
            </IconButton>
            <IconButton
              onClick={() => {
                // let deleteValue = informationTabledata.filter(
                //   (item) => item.id === props.row.original.id
                // );

                // setDeletedInformation((prevData) => [
                //   ...prevData,
                //   ...deleteValue,
                // ]);
                informationTabledata.splice(props.row.index, 1);

                setInformationTableData(informationTabledata);

                deleteInformationDetails(props.row.original.id)
                  .then((res) => res)
                  .catch((res) => res);
              }}
            >
              <DeleteIcon color="primary" />
            </IconButton>
          </div>
        </div>
      );
    },
  },
];

export const entryFormInformationRawdata = [
  {
    id: 1,
    financialYear: "May 22 - Apr 23",
    date: "11/2/2022",
    fileType: "PDF",
    fileContent: "Lorem.",
    fileName: "Filename.pdf",
    updateBy: "user",
  },
  {
    id: 2,
    financialYear: "May 22 - Apr 23",
    date: "11/2/2022",
    fileType: "JPG",
    fileContent: "Lorem.",
    fileName: "Filename.JPG",
    updateBy: "userOne",
  },
];

export const entryFormSupportingColumn = (
  setSupportingTabledata,
  setRenderTable,
  setDeletedSupporting,
  supportingTabledata,
  sidebarIndex
) => [
  {
    Header: "DATE",
    accessor: "createdAt",
    sticky: "left",
    id: 1,
    Cell: (props) => {
      return moment(props.value).format("L");
    },
  },
  {
    Header: "Document Type",
    accessor: "fileType",
    id: 2,
    Cell: (props) => {
      return props.value.split("/")[1].toUpperCase();
    },
  },
  {
    Header: `${sidebarIndex === 3 ? "Attachment Name" : "File Name"}`,
    accessor: "data.fileContent",
    id: 3,
  },
  {
    Header: `${sidebarIndex === 3 ? "Attachment" : "Document"}`,
    accessor: "fileName",
    id: 4,
    Cell: (props) => (
      <span style={{ textDecoration: "underline", color: "#2A5BA8" }}>
        {props.value}
      </span>
    ),
  },
  {
    Header: "Update By",
    accessor: "user.name",
    id: 5,
    Cell: (props) => {
      console.log();
      return (
        <div
          style={{
            display: "flex ",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span> {props.value} </span>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <IconButton
              onClick={() => {
                let rowId = props.row.original.id;
                downloadFileByLink("supportingData", rowId).then((res) =>
                  saveAs(res.data.url)
                );
              }}
            >
              <DownloadIcon color="primary" />
            </IconButton>
            <IconButton
              onClick={() => {
                // let deleteValue = supportingTabledata.filter(
                //   (item) => item.id === props.row.original.id
                // );

                // setDeletedSupporting((prevData) => [
                //   ...prevData,
                //   ...deleteValue,
                // ]);
                supportingTabledata.splice(props.row.index, 1);

                setSupportingTabledata(supportingTabledata);

                deleteSupportingData(props.row.original.id)
                  .then((res) => res)
                  .catch((res) => res);
              }}
            >
              <DeleteIcon color="primary" />
            </IconButton>
          </div>
        </div>
      );
    },
  },
];

export const entryFormSupportingRawdata = [
  {
    id: 1,
    date: "11/2/2022",
    fileType: "PDF",
    fileContent: "Lorem.",
    fileName: "Filename.pdf",
    updateBy: "user",
  },
  {
    id: 2,
    date: "11/2/2022",
    fileType: "JPG",
    fileContent: "Lorem.",
    fileName: "Filename.JPG",
    updateBy: "userOne",
  },
];

export const entryFormAuditlogColumn = [
  {
    Header: "Name",
    accessor: "user.name",
    sticky: "left",
    id: 1,
  },
  {
    Header: "Email ID",
    accessor: "user.emailId",
    id: 2,
  },
  {
    Header: "Date Modified",
    accessor: "updatedAt",
    id: 3,
    Cell: (props) => {
      return moment(props.value).format("L");
    },
  },
  {
    Header: " Saved as ",
    accessor: "formStatusId",
    id: 4,

    Cell: (props) => {
      return entryFormDataOptions.map(
        (item) => item.id === props.value && item.name
      );
    },
  },
];

export const entryFormAuditLogRawdata = [
  {
    id: 1,
    name: "example",
    emailId: "ex@gmail.cc",
    dateModified: "11/2/2022",
    savedAs: "Draft",
  },
  {
    id: 2,
    name: "example",
    emailId: "ex@gmail.cc",
    dateModified: "11/2/2022",
    savedAs: "Draft",
  },
];
